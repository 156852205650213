import React, { useEffect, useState } from "react";

import {
  AudioOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  EyeOutlined,
} from "@ant-design/icons";
import { Card, Col, Input, Modal, Row, Space, Spin, Table } from "antd";
import toast from "react-hot-toast";
import {
  Link,
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import useQueryParams from "../hooks/useQueryParams";
import {
  useDeleteCryptoJobsMutation,
  useGetCryptoJobDetailsQuery,
  useGetCryptoJobsQuery,
} from "../services/cryptoJob";
import { hasPermission } from "../utils/permissions";
const { Search } = Input;
const suffix = (
  <AudioOutlined
    style={{
      fontSize: 16,
      color: "#1890ff",
    }}
  />
);

function formatDate(dateString) {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
}

const makeFormData = (data) => {
  const formData = new FormData();
  formData.append("published", data.published);
};
const onSearch = (value) => console.log(value);
// Search End

const { TextArea } = Input;

const { confirm } = Modal;

function Listedcryptojobs() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedSlug, setSelectedSlug] = useState(null);
  const isCryptoEdit = hasPermission("crypto:edit");
  const isCryptoDelete = hasPermission("crypto:delete");

  const location = useLocation();
  const history = useHistory();

  // Parse the URL query parameters
  const searchParams = new URLSearchParams(location.search);
  const initialPage = parseInt(searchParams.get("page")) || 1;
  const initialSearch = searchParams.get("search") || "";

  const [page, setPage] = useState(initialPage);
  const [search, setSearch] = useState(initialSearch);
  const showModal = (slug) => {
    console.log("Slug", slug);
    setSelectedSlug(slug);

    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  useQueryParams({
    params: {
      page: [page > 1 ? page : null, setPage, 1],
      search: [searchQuery, setSearchQuery, "", true, setSearch], // Update setSearch as well
    },
    watchList: [page, search],
  });

  const {
    data: cryptoJobs,
    isLoading,
    isSuccess,
    isFetching,
  } = useGetCryptoJobsQuery({
    page,
    limit: 10,
    title: search,
  });
  const {
    data: cryptoJobDetails,
    isLoading: cryptoJobDetailsLoading,
    isError: cryptoJobDetailsError,
  } = useGetCryptoJobDetailsQuery(
    { slug: selectedSlug },
    { skip: !selectedSlug },
  );
  // console.log("Details=>", cryptoJobDetails);
  useEffect(() => {
    if (cryptoJobDetailsError) {
      toast.error("Failed to fetch crypto job details");
    }
  }, [cryptoJobDetailsError]);

  const [
    deleteCryptoJobs,
    {
      // isLoading: deleteBlogLoading,
      // isSuccess: isDeleteBlogSuccess,
      isError: isDeleteError,
      error: deleteError,
    },
  ] = useDeleteCryptoJobsMutation();
  useEffect(() => {
    if (isDeleteError) {
      toast.error(deleteError?.data?.message || "Something went wrong");
    }
  }, [isDeleteError]);
  console.log("cryptoJobDetails", cryptoJobDetails);
  const showDeleteConfirm = (slug) => {
    confirm({
      title: "Are you sure delete this Job?",
      icon: <ExclamationCircleFilled />,
      // content: 'Some descriptions',
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        console.log("OK");
        deleteCryptoJobs(slug);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  // User Table Data Stat
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Date Published",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => formatDate(createdAt), // Using the formatDate function here
    },

    {
      title: "Applicants",
      dataIndex: "totalApplicants",
      key: "totalApplicants",
      render: (totalApplicants, record) => {
        return (
          <Link
            to={`/applicants/${record?._id}`}
            style={{ fontWeight: "600", fontSize: "13px" }}
          >
            {totalApplicants}
          </Link>
        );
      },
    },
  ];

  // if (isCryptoEdit) {
  //   columns.push({
  //     title: "Published",
  //     key: "locked",
  //     render: (_, record) => (
  //       <Switch
  //         id={record.slug}
  //         checked={record.published}
  //         onChange={() => console.log("value changed")}
  //         onClick={async () => {
  //           try {
  //             setSelectedSlug(record.slug);
  //             const body = new FormData();
  //             body.append("published", !record.published);
  //             updateBlogs({ formData: body, slug: record.slug });
  //           } catch (error) {
  //             console.log("error => ", error);
  //           }
  //         }}
  //         loading={
  //           record.slug === selectedSlug ? updateLoading || isFetching : false
  //         }
  //       />
  //     ),
  //   });
  // }
  if (isCryptoDelete || isCryptoEdit) {
    columns.push({
      title: "Actions",
      key: "actions",
      render: (_, record) => {
        return (
          <Space size="middle">
            <a
              className="danger_color"
              onClick={() => showModal(record?.slug)}
              type="dashed"
            >
              <EyeOutlined />
            </a>
            {isCryptoEdit && (
              <a
                className="primary_color"
                onClick={() => {
                  history.push({
                    pathname: "/edit_crypto_jobs/" + record?._id,
                  });
                }}
              >
                <EditOutlined />
              </a>
            )}
            {isCryptoDelete && (
              <a
                className="danger_color"
                onClick={() => {
                  showDeleteConfirm(record?._id);
                }}
                type="dashed"
              >
                <DeleteOutlined />
              </a>
            )}
          </Space>
        );
      },
    });
  }

  const paginationOptions = {
    total: cryptoJobs?.totalJobs || 0,
    current: page,
    pageSize: 10,
    showSizeChanger: false,
    showQuickJumper: false,
    onChange: (page, pageSize) => {
      setPage(page);
      console.log("page => ", page);
    },
    onShowSizeChange: (current, size) => {},
  };

  const skillslist = [
    "JavaScript",
    "React",
    "Node.js",
    "HTML",
    "CSS",
    "Python",
    "Java",
  ];

  return (
    <>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <Col xs={24} md={24} sm={24} lg={24} xl={24} className="mb-24">
            <Card bordered={false} className="criclebox mb-5">
              <Row gutter={[24, 0]}>
                <Col
                  xs={24}
                  md={24}
                  sm={24}
                  lg={12}
                  xl={18}
                  className="mb-24"
                ></Col>
                <Modal
                  title="Crypto Job Detail Preview"
                  width={700}
                  centered
                  open={isModalOpen}
                  onOk={handleOk}
                  onCancel={handleCancel}
                >
                  {cryptoJobDetailsLoading ? (
                    <div className="text-center">
                      <Spin size="large" />
                    </div>
                  ) : (
                    <div className="container">
                      <div className="row">
                        <div className="col-12">
                          <div>
                            <h4>{cryptoJobDetails?.title}</h4>
                          </div>
                          <div className="row pt-3">
                            <div className="col-6">
                              <p className="mb-2">
                                <b>Company Name:</b>{" "}
                                {cryptoJobDetails?.company?.name ? (
                                  <> {cryptoJobDetails?.company?.name}</>
                                ) : (
                                  "N/A"
                                )}
                              </p>
                              {/* <p className="mb-2">
                              <b>Currency:</b>{" "}
                              {cryptoJobDetails?.salary?.currency}
                            </p> */}
                              <p className="mb-2">
                                <b>Tagline:</b>
                                {` `}
                                {cryptoJobDetails?.tagLine}
                              </p>
                              <p className="mb-2">
                                <b>Job Location:</b>
                                {` `} {cryptoJobDetails?.address?.country?.name}
                                - {cryptoJobDetails?.address?.city}
                              </p>
                            </div>
                          </div>
                          <div>
                            <p className="mb-2">
                              <b>Job Description:</b>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: cryptoJobDetails?.description || "",
                                }}
                              ></p>
                            </p>
                            <p className="mb-2">
                              <b>About your company:</b>

                              <p
                                dangerouslySetInnerHTML={{
                                  __html:
                                    cryptoJobDetails?.company?.about || "",
                                }}
                              ></p>
                            </p>
                            <p className="mb-2">
                              <b>Tags Added:</b>
                            </p>
                            <div>
                              {/* {skillslist?.map((skill, index) => ( */}
                              {cryptoJobDetails?.tags ? (
                                <>
                                  {cryptoJobDetails?.tags?.map(
                                    (skill, index) => (
                                      <span
                                        key={index}
                                        className={`span_skill_tag pt-1 me-2`}
                                      >
                                        <span className="">{skill.name}</span>
                                      </span>
                                    ),
                                  )}
                                </>
                              ) : (
                                "N/A"
                              )}
                            </div>

                            <p className="mb-2 mt-2">
                              <b>Currency Selected:</b>{" "}
                              {cryptoJobDetails?.salary?.currency}
                            </p>
                            <div className="pt-2">
                              <p className="">
                                <b>Salary Range:</b>{" "}
                                {cryptoJobDetails?.salary?.currency}
                                {` `}
                                {cryptoJobDetails?.salary?.value?.min} -{" "}
                                {cryptoJobDetails?.salary?.value?.max}
                                {/* ( {cryptoJobDetails?.salary?.value?.frequency}) */}
                              </p>
                            </div>
                            <p className="mb-2">
                              <b>Frequency:</b>{" "}
                              {cryptoJobDetails?.salary?.value?.frequency}
                            </p>
                            <p className="mb-2 mt-3">
                              <b>Email for resumes. Stays Private:</b>{" "}
                              {cryptoJobDetails?.company?.email}
                            </p>
                            <div className="container g-0">
                              <div className="row">
                                <div className="col-lg-7 col-md-8 col-12">
                                  <h4>Company Details</h4>
                                  <p className="mb-2">
                                    <b>Website Link:</b>{" "}
                                    {cryptoJobDetails?.company?.website ? (
                                      <>{cryptoJobDetails?.company?.website}</>
                                    ) : (
                                      "N/A"
                                    )}
                                  </p>
                                  <p className="mb-2">
                                    <b>Twitter Link:</b>
                                    {` `}
                                    {cryptoJobDetails?.company?.socialMedia
                                      ?.twitter ? (
                                      <>
                                        {
                                          cryptoJobDetails?.company?.socialMedia
                                            ?.twitter
                                        }
                                      </>
                                    ) : (
                                      "N/A"
                                    )}
                                  </p>
                                  {/* <p className="mb-2">
                                  <b>Discard:</b> https://discord.gg/company123
                                </p> */}
                                </div>
                                <div className="col-lg-5 col-md-4 col-12">
                                  <div className="d-flex justify-content-start pt-lg-4">
                                    <p className="mb-2">
                                      <b>Logo:</b>
                                    </p>
                                    <img
                                      src={cryptoJobDetails?.company?.picture}
                                      // src="https://work-as-pro.s3.us-east-2.amazonaws.com/staticPictures/profile-pic-1.png"
                                      width="100px"
                                      alt="N/A"
                                    ></img>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Modal>
                <Col xs={24} md={12} sm={24} lg={12} xl={6} className="mb-24">
                  <div className="">
                    <Search
                      placeholder="Input search text"
                      allowClear
                      onSearch={onSearch}
                      enterButton
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value);
                        setPage(1);
                      }} // Update the 'search' state while typing
                    />
                  </div>
                </Col>
                <Col xs={24} md={24} sm={24} lg={24} xl={24} className="mb-24">
                  <div className="">
                    <Table
                      loading={isLoading}
                      columns={columns}
                      dataSource={cryptoJobs?.jobs || []}
                      pagination={paginationOptions}
                    />
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Listedcryptojobs;
