import { Select } from "antd";
import React from "react";
import { useCryptoJobFormikContext } from "../../hooks/useFormikCryptoJobContext";
import { useGetAddressQuery } from "../../services/cryptoAddress";
import { useGetCountriesQuery } from "../../services/cryptoCountry";
const { Option } = Select;

const DisplayAddress = () => {
  const { isLoading: countryLoading, data: countryData } = useGetCountriesQuery(
    {
      page: 1,
      limit: 300,
    },
  );

  const {
    handleSubmit,
    values,
    errors,
    handleChange,
    handleBlur,
    isSubmitting,
    setFieldValue,
    displayError,
  } = useCryptoJobFormikContext();

  const { isLoading: addressLoading, data: addressData } = useGetAddressQuery({
    countryId: values?.country,
  });

  const handleCountryChange = (value) => {
    setFieldValue("country", value);
  };

  const handleAddressChange = (value) => {
    setFieldValue("address", value);
  };
  return (
    <>
      <div className="mb-3">
        <label className="create_crypto_jobs_labels">
          Country
          <span className="crypto_job_red_steric">*</span>
        </label>
        <Select
          showSearch
          className="job_title_input_field"
          // style={{ width: 200 }}
          style={{ width: "100%", padding: 0 }}
          notFoundContent={false}
          placeholder="Select Country"
          optionFilterProp="children"
          onChange={handleCountryChange}
          onBlur={handleBlur}
          value={values.country}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          <Option value={""}>Select Country</Option>
          {countryData?.countries?.map((country) => (
            <Option key={country?._id} value={country?._id}>
              {country?.name}
            </Option>
          ))}
        </Select>
        {displayError("country")}
      </div>

      <div className="mb-3">
        <label className="create_crypto_jobs_labels">
          City
          <span className="crypto_job_red_steric">*</span>
        </label>
        <Select
          showSearch
          className="job_title_input_field"
          // style={{ width: 200 }}
          style={{ width: "100%", padding: 0 }}
          disabled={!values?.country}
          notFoundContent={false}
          placeholder="Select City"
          optionFilterProp="children"
          onChange={handleAddressChange}
          onBlur={handleBlur}
          value={values.address}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          <Option value={""}>Select City</Option>
          {addressData?.addresses?.map((address) => (
            <Option key={address?._id} value={address?._id}>
              {address?.city}
            </Option>
          ))}
        </Select>
        {displayError("address")}
      </div>
    </>
  );
};

export default DisplayAddress;
