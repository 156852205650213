export const user = {
  getUsers: (query) => `/user?${query}`,
  getUserProfile: (username) => `/user/profile/${username}`,
  getTopSellers: (query) => `/user/get-top-sellers?${query}`,
  getTopBuyers: (query) => `/user/get-top-buyers?${query}`,
  getUserJobById: (jobId) => `/user/job/${jobId}`,
  getUserGigById: (gigId) => `/user/gig/${gigId}`,
  getUserOrderById: (orderId) => `/user/order/${orderId}`,
  changeUserStatus: `/user/updateStatus`,
  makeUserRealOrDummy: (userId) => `/user/makeRealOrDummy/${userId}`,
  changeGigStatus: `/user/gig/updateStatus`,
  changeJobStatus: `/user/job/updateStatus`,
  changeUserRoleSeller: `/user/userswtich/seller`,
  changeUserRoleBuyer: (status) => `/user/userswtich/${status}`,
};
