import ApiBase from "../app/ApiBase";
import config from "../config/development";
import { objectToQueryString } from "../utils/url";

const cacheTime = 10000;

export const userApi = ApiBase.enhanceEndpoints({
  addTagTypes: ["UserData"],
}).injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: (query) =>
        config.api.endpoints.getUsers(objectToQueryString(query)),
      transformResponse: (response) => {
        // handle successful response and return data to store
        console.log("response: ", response);
        return response;
      },
      providesTags: ["getUsers"],
      keepUnusedDataFor: cacheTime,
    }),
    getUsersCSV: builder.query({
      query: (query) =>
        config.api.endpoints.getUsers(objectToQueryString(query)),
      transformResponse: (response) => {
        // handle successful response and return data to store
        console.log("response: ", response);
        return response;
      },
      // providesTags: ["getUsers"],
      keepUnusedDataFor: cacheTime,
    }),
    getUserProfile: builder.query({
      query: ({ username }) => config.api.endpoints.getUserProfile(username),
      transformResponse: (response) => {
        // handle successful response and return data to store
        console.log("response: ", response?.user);
        return response?.user;
      },
      keepUnusedDataFor: cacheTime,
    }),
    getUserJobById: builder.query({
      query: ({ jobId }) => config.api.endpoints.getUserJobById(jobId),
      transformResponse: (response) => {
        // handle successful response and return data to store
        console.log("response: ", response?.job);
        return response?.job;
      },
      keepUnusedDataFor: cacheTime,
    }),
    getUserOrderById: builder.query({
      query: ({ orderId }) => config.api.endpoints.getUserOrderById(orderId),
      transformResponse: (response) => {
        // handle successful response and return data to store
        console.log("response: ", response);
        return response?.order;
      },
      keepUnusedDataFor: cacheTime,
    }),
    getUserGigById: builder.query({
      query: ({ gigId }) => config.api.endpoints.getUserGigById(gigId),
      transformResponse: (response) => {
        // handle successful response and return data to store
        console.log("response: ", response);
        return response?.gig;
      },
      keepUnusedDataFor: cacheTime,
    }),
    getTopSellers: builder.query({
      query: (query) =>
        config.api.endpoints.getTopSellers(objectToQueryString(query)),
      transformResponse: (response) => {
        // handle successful response and return data to store
        console.log("response: ", response);
        return response?.data;
      },
      keepUnusedDataFor: cacheTime,
    }),
    getTopBuyers: builder.query({
      query: (query) =>
        config.api.endpoints.getTopBuyers(objectToQueryString(query)),
      transformResponse: (response) => {
        // handle successful response and return data to store
        console.log("response: =>Buyer", response);
        return response?.data;
      },
      keepUnusedDataFor: cacheTime,
    }),
    changeUserStatus: builder.mutation({
      query: (updatedBuyerData) => ({
        url: config.api.endpoints.changeUserStatus,
        method: "PUT",
        body: updatedBuyerData, // The data you want to send in the request body
      }),
      transformResponse: (response) => {
        // handle successful response and return data to store
        console.log("response: ", response);
        return response;
      },
    }),
    makeUserRealOrDummy: builder.mutation({
      query: (data) => ({
        url: config.api.endpoints.makeUserRealOrDummy(data?.id),
        method: "PUT",
        body: { isDummy: data?.isDummy ?? false }, // The data you want to send in the request body
      }),
      transformResponse: (response) => {
        // handle successful response and return data to store
        console.log("response: ", response);
        return response;
      },
      invalidatesTags: ["getUsers"],
    }),
    changeGigStatus: builder.mutation({
      query: (updatedBuyerData) => ({
        url: config.api.endpoints.changeGigStatus,
        method: "PUT",
        body: updatedBuyerData, // The data you want to send in the request body
      }),
      transformResponse: (response) => {
        // handle successful response and return data to store
        console.log("response: ", response);
        return response;
      },
    }),
    changeJobStatus: builder.mutation({
      query: (updatedBuyerData) => ({
        url: config.api.endpoints.changeJobStatus,
        method: "PUT",
        body: updatedBuyerData, // The data you want to send in the request body
      }),
      transformResponse: (response) => {
        // handle successful response and return data to store
        console.log("response: ", response);
        return response;
      },
    }),
    changeUserRoleSeller: builder.mutation({
      query: (body) => ({
        url: config.api.endpoints.changeUserRoleSeller,
        method: "PATCH",
        body, // The data you want to send in the request body
      }),
      transformResponse: (response) => {
        // handle successful response and return data to store
        console.log("response Seller: ", response);
        return response;
      },
    }),
    changeUserRoleBuyer: builder.mutation({
      query: (body) => ({
        url: config.api.endpoints.changeUserRoleBuyer(body.role),
        method: "PATCH",
        body: { userid: body.userid }, // The data you want to send in the request body
      }),
      transformResponse: (response) => {
        // handle successful response and return data to store
        console.log("response Buyer: ", response);
        return response;
      },
    }),
  }),
});

// Generate hooks for each endpoint
export const {
  useGetUsersQuery,
  useGetUsersCSVQuery,
  useGetUserProfileQuery,
  useGetTopBuyersQuery,
  useGetTopSellersQuery,
  useGetUserGigByIdQuery,
  useGetUserJobByIdQuery,
  useGetUserOrderByIdQuery,
  useChangeUserStatusMutation,
  useChangeGigStatusMutation,
  useChangeJobStatusMutation,
  useChangeUserRoleSellerMutation,
  useChangeUserRoleBuyerMutation,
  useMakeUserRealOrDummyMutation,
} = userApi;
