import store from "../store";

export const hasPermission = (requiredPermissions) => {
  try {
    if (!Array.isArray(requiredPermissions)) {
      requiredPermissions = [requiredPermissions];
    }

    const user = store.getState().user?.user;
    if (
      !user ||
      !Array.isArray(user.permissions) ||
      user.permissions.length === 0
    ) {
      return false;
    }

    return requiredPermissions.every((requiredPermission) => {
      if (
        typeof requiredPermission === "string" &&
        requiredPermission.includes(":")
      ) {
        const [resource, action] = requiredPermission.split(":");
        if (!resource || !action) {
          throw new Error(
            "Invalid input: requiredPermission format must be 'resource:action'",
          );
        }
        return user.permissions.some(
          (permission) =>
            permission.resource === resource &&
            permission.actions.includes(action),
        );
      } else if (
        typeof requiredPermission === "object" &&
        requiredPermission.resource &&
        Array.isArray(requiredPermission.actions)
      ) {
        return requiredPermission.actions.every((action) =>
          user.permissions.some(
            (permission) =>
              permission.resource === requiredPermission.resource &&
              permission.actions.includes(action),
          ),
        );
      } else {
        throw new Error(
          "Invalid input: requiredPermissions must be in 'resource:action' format or { resource, actions } object",
        );
      }
    });
  } catch (error) {
    console.error("Error in hasPermission:", error.message);
    return false;
  }
};
