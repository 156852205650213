import React, { useState } from "react";

import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  EyeOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Input,
  Modal,
  Row,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import { useEffect } from "react";
import "react-dropdown-tree-select/dist/styles.css";
import toast from "react-hot-toast";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import AdminForm from "../components/admin/AdminForm";
import ViewAdmin from "../components/admin/ViewAdmin";
import useQueryParams from "../hooks/useQueryParams";
import { useDeleteAdminMutation, useGetAllAdminsQuery } from "../services/auth";
import { hasPermission } from "../utils/permissions";
// Search
const { Search } = Input;
const { confirm } = Modal;

function SubAdmin() {
  // const [loading, setLoading] = useState(false);
  // User Show Modal
  const [ShowUser, setShowUser] = useState(false);
  const [editUsername, setEditUsername] = useState(null);
  const isAdminCreate = hasPermission("subadmin:create");
  const isAdminEdit = hasPermission("subadmin:edit");
  const isAdminDelete = hasPermission("subadmin:delete");

  const OpenUser = (username) => {
    setEditUsername(username);
    setShowUser(true);
  };
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const history = useHistory();
  const location = useLocation();
  const [shouldReset, setShouldReset] = useState(false);
  const [
    deleteAdmin,
    {
      isLoading: isDeleteAdminLoading,
      isSuccess: isDeleteAdminSuccess,
      error: deleteAdminError,
      isError: isDeleteAdminError,
      data: deleteAdminData,
    },
  ] = useDeleteAdminMutation();
  const {
    isLoading: isAdminsLoading,
    data: adminsData,
    isError: isAdminsError,
    error: adminsError,
  } = useGetAllAdminsQuery(
    {
      page,
      perPage: 10,
      search: searchQuery,
    },
    {
      selectFromResult: ({ data, isLoading, error }) => ({
        // clear data if error exists
        data: error ? [] : data,
        isLoading,
        error,
      }),
    },
  );

  useQueryParams({
    params: {
      page: [page > 1 ? page : null, setPage, 1],
      search: [searchQuery, setSearchQuery, "", true, setSearch], // Update setSearch as well
    },
    watchList: [searchQuery, page],
  });

  useEffect(() => {
    if (isDeleteAdminSuccess) {
      toast.success("Admin is deleted successfully");
      setEditUsername(null);
    }
  }, [isDeleteAdminSuccess]);

  useEffect(() => {
    if (isDeleteAdminError) {
      toast.error(deleteAdminError?.data?.message || "Something went wrong");
    }
  }, [isDeleteAdminError]);

  const showDeleteConfirm = (username) => {
    setEditUsername(username);
    confirm({
      title: "Are you sure delete this subadmin?",
      icon: <ExclamationCircleFilled />,
      // content: 'Some descriptions',
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteAdmin({ username });
        console.log("OK");
      },
      onCancel() {
        setEditUsername(null);
        console.log("Cancel");
      },
    });
  };
  const UserOk = () => {
    setEditUsername(null);
    setShowUser(false);
  };
  const UserCancel = () => {
    setEditUsername(null);
    setShowUser(false);
  };
  // Create User Modal
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = (fromEdit) => {
    if (fromEdit) {
      setIsModalOpen(true);
      setShowUser(false);
    } else {
      setIsModalOpen(true);
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setEditUsername(null);
    setIsModalOpen(false);
  };
  const handleCancelreset = () => {
    setShouldReset(true);
    setEditUsername(null);
    setIsModalOpen(false);
  };

  const onSearch = (value) => {
    setPage(1);

    setSearchQuery(value); // Update the 'search' state with the entered value
    console.log(value);
    // Here you can use the 'value' to perform any search-related operations.
    // For example, you can use the 'value' to filter the table data based on the search query.
  };

  // Set up pagination options
  const paginationOptions = {
    total: adminsData?.adminsCount || 0, // Set the total number of items
    current: page, // Set the current page number
    pageSize: 10, // Set the number of items per page
    // pageSizeOptions: ['5', '10', '20'], // Optional: Available page size options
    showSizeChanger: false, // Optional: Show the page size changer
    showQuickJumper: false, // Optional: Show quick jump to page input
    onChange: (page, pageSize) => {
      setPage(page);
      console.log("page => ", page);
      // You can implement your custom logic here to fetch data for the selected page
      // For example, you can use the useGetUsersQuery hook with the new page number
    },
    onShowSizeChange: (current, size) => {
      // You can implement your custom logic here to fetch data with the new page size
      // For example, you can use the useGetUsersQuery hook with the new page size
    },
  };

  const [refetch, setRefetch] = useState(false);

  const onEdit = (username) => {
    setEditUsername(username);
    showModal();
    setRefetch((v) => !v);
  };

  // User Table Data Stat
  const columns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Status",
      key: "active",
      dataIndex: "active",
      render: (_, { active }) => {
        let color = active ? "geekblue" : "volcano";
        let tag = active ? "Active" : "InActive";
        return (
          <>
            <Tag color={color} key={tag}>
              {tag.toUpperCase()}
            </Tag>
          </>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return (
          <Space size="middle">
            <a
              className="primary_color"
              onClick={() => OpenUser(record?.username)}
            >
              <EyeOutlined />
            </a>
            {isAdminEdit && (
              <a onClick={() => onEdit(record?.username)}>
                <EditOutlined />
              </a>
            )}
            {isAdminDelete && (
              <a
                className="danger_color"
                onClick={() => showDeleteConfirm(record?.username)}
                type="dashed"
              >
                <DeleteOutlined />
              </a>
            )}
          </Space>
        );
      },
    },
  ];

  // User Table Data End

  const { Title, Text, Paragraph } = Typography;

  return (
    <>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <Col xs={24} md={24} sm={24} lg={24} xl={24} className="mb-24">
            <Card bordered={false} className="criclebox mb-5">
              <Row gutter={[24, 0]}>
                <Col xs={24} md={24} sm={12} lg={12} xl={24} className="mb-24">
                  <div className="d-flex justify-content-end">
                    <div className="">
                      <Search
                        style={{ maxWidth: "320px" }}
                        placeholder="Search By Username"
                        onSearch={onSearch}
                        enterButton
                        allowClear
                        value={search}
                        onChange={(e) => setSearch(e.target.value)} // Update the 'search' state while typing
                      />
                    </div>
                    {isAdminCreate && (
                      <div className="create_user_btn_div ms-3">
                        <Button
                          type="primary"
                          className="px-3 rounded-5"
                          onClick={showModal}
                        >
                          Create Sub Admin
                        </Button>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
              <Row gutter={[24, 0]}>
                <Col xs={24} md={24} sm={24} lg={24} xl={24} className="mb-24">
                  <div
                    className=""
                    style={{ minWidth: "auto", overflowX: "auto" }}
                  >
                    <Table
                      pagination={paginationOptions}
                      loading={isAdminsLoading}
                      columns={columns}
                      dataSource={adminsData?.admins}
                    />
                  </div>
                </Col>
              </Row>
            </Card>

            <div>
              <Modal
                title="Sub Admin"
                centered
                width={800}
                visible={isModalOpen}
                onOk={handleOk}
                onCancel={editUsername ? handleCancel : handleCancelreset}
                footer={
                  [
                    // <Button key="back" onClick={handleCancel}>
                    //   Return
                    // </Button>,
                    // <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
                    //   Edit
                    // </Button>,
                  ]
                }
              >
                <Row gutter={[24, 0]}>
                  <Col
                    xs={24}
                    md={24}
                    sm={24}
                    lg={24}
                    xl={24}
                    className="mobile-24"
                  >
                    <div className="project-ant">
                      <div>
                        <Title className="" level={3}>
                          {editUsername
                            ? "Update Sub Admin"
                            : "Create Sub Admin"}
                        </Title>
                      </div>
                    </div>

                    <AdminForm
                      shouldReset={shouldReset}
                      setShouldReset={setShouldReset}
                      refetch={refetch}
                      editUsername={editUsername}
                      handleCancel={handleCancel}
                      setEditUsername={setEditUsername}
                    />
                  </Col>
                </Row>
              </Modal>
            </div>

            {/* Create User Modal End */}

            {/* User View Modal Start */}
            <div>
              <Modal
                title="View Sub Admin"
                centered
                width={800}
                visible={ShowUser}
                onOk={UserOk}
                onCancel={UserCancel}
                footer={[
                  <Button key="back" onClick={UserCancel}>
                    Return
                  </Button>,
                  <Button
                    disabled={!isAdminEdit}
                    key="submit"
                    type="primary"
                    onClick={() => showModal(true)}
                  >
                    Edit
                  </Button>,
                ]}
              >
                <ViewAdmin editUsername={editUsername} />
              </Modal>
            </div>
            {/* User View Modal End */}
          </Col>
        </Row>
      </div>
    </>
  );
}

export default SubAdmin;
