import ApiBase from "../app/ApiBase";
import config from "../config/development";
import { objectToQueryString } from "../utils/url";
export const technologyApi = ApiBase.enhanceEndpoints({
  addTagTypes: ["Technology"],
}).injectEndpoints({
  endpoints: (builder) => ({
    getTechnologies: builder.query({
      query: (query) =>
        config.api.endpoints.getTechnologies(objectToQueryString(query)),
      transformResponse: (response) => {
        // handle successful response and return data to store
        console.log("response: ", response);
        return response;
      },
      providesTags: ["getTechnologies"],
    }),
    getTechnologyById: builder.query({
      query: ({ id }) => config.api.endpoints.getTechnologyById(id),
      transformResponse: (response) => {
        // handle successful response and return data to store
        console.log("response: ", response);
        return response;
      },
    }),
    createTechnology: builder.mutation({
      query: (formData) => ({
        url: config.api.endpoints.createTechnology,
        method: "POST",
        body: formData, // The data is already a FormData object containing the multipart data
        headers: {
          // 'Content-Type': 'multipart/form-data', // Set the Content-Type header directly here
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100,
          );
          console.log(`Upload progress: ${progress}%`);
        },
      }),

      transformResponse: (response) => {
        // handle successful response and return data to store
        console.log("response: ", response);
        return response;
      },
      invalidatesTags: ["getTechnologies"],
    }),
    updateTechnology: builder.mutation({
      query: (formData) => ({
        url: config.api.endpoints.updateTechnology(formData.id),
        method: "PUT",
        body: formData.body, // The data is already a FormData object containing the multipart data
        headers: {
          // 'Content-Type': 'multipart/form-data', // Set the Content-Type header directly here
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100,
          );
          console.log(`Upload progress: ${progress}%`);
        },
      }),

      transformResponse: (response) => {
        // handle successful response and return data to store
        console.log("response: ", response);
        return response;
      },
      invalidatesTags: ["getTechnologies"],
    }),
    deleteTechnology: builder.mutation({
      query: ({ id }) => ({
        url: config.api.endpoints.deleteTechnology(id),
        method: "DELETE",
      }),
      transformResponse: (response) => {
        // handle successful response and return data to store

        console.log("response: ", response);
        return response;
      },
      invalidatesTags: ["getTechnologies"],
    }),
  }),
});

// Generate hooks for each endpoint
export const {
  useGetTechnologiesQuery,
  useGetTechnologyByIdQuery,
  useCreateTechnologyMutation,
  useUpdateTechnologyMutation,
  useDeleteTechnologyMutation,
} = technologyApi;
