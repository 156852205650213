import { ethers } from "ethers";
import { toast } from "react-toastify";
import { connectWallet } from "../slices/wallet";
import ESCROW_ABI from "./abi/ESCROW_ABI.json";

const getNetworkDetails = (network) => {
  let chainId, contractAddress;

  switch (network) {
    case "ETH":
      contractAddress = process.env.REACT_APP_ESCROW_CONTRACT_ADDRESS_ETH;
      chainId = process.env.REACT_APP_ETHEREUM_CHAIN_ID;
      break;
    case "POLYGON":
      contractAddress = process.env.REACT_APP_ESCROW_CONTRACT_ADDRESS_POLYGON;
      chainId = process.env.REACT_APP_POLYGON_CHAIN_ID;
      break;
    default:
      break;
  }

  return { chainId, contractAddress };
};

const getEscrowContract = (network) => {
  let provider = new ethers.providers.Web3Provider(window.ethereum);
  let { contractAddress } = getNetworkDetails(network);

  const EscrowContract = new ethers.Contract(
    contractAddress,
    ESCROW_ABI,
    provider.getSigner(),
  );

  return EscrowContract;
};

export const getDisputeProof = (orderId, network) => {
  const EscrowContract = getEscrowContract(network);
  return EscrowContract.getOffer(orderId);
};

export const getContractOwner = (network) => {
  const EscrowContract = getEscrowContract(network);
  return EscrowContract.escAcc();
};

export const resolveDispute = (network, orderId, refund, setLoaderText) => {
  const EscrowContract = getEscrowContract(network);

  return new Promise((resolve, reject) => {
    EscrowContract.resolveDispute(orderId, refund)
      .then(async (tx) => {
        // Displaying Toasts
        toast.success("Transaction was approved");

        setLoaderText("Mining");

        await tx.wait();

        setLoaderText("Pending");

        resolve();
      })
      .catch(() => {
        toast.error("Transaction was rejected");
        reject();
      });
  });
};

export const handleConnect = async (network, dispatch) => {
  return new Promise(async (resolve, reject) => {
    toast.info("Please connect to Metamask");

    if (!window.ethereum) {
      toast.error("Please install MetaMask");
      reject();
    } else {
      try {
        const { chainId } = getNetworkDetails(network);

        const currentChainId = await window.ethereum.request({
          method: "eth_chainId",
        });

        if (chainId != currentChainId) {
          await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId }],
          });
        }

        await window.ethereum.request({
          method: "wallet_requestPermissions",
          params: [
            {
              eth_accounts: {},
            },
          ],
        });

        const resp = await window.ethereum.request({
          method: "eth_requestAccounts",
          params: [
            {
              eth_accounts: {},
            },
          ],
        });

        dispatch(
          connectWallet({
            address: resp[0]?.toLowerCase(),
            network,
          }),
        );

        toast.success("Successfully connected to Metamask");

        resolve(resp);
      } catch (err) {
        if (err?.message?.includes("Unrecognized chain ID")) {
          toast.error("Network not added in Metamask, visit ChainList.org");
        } else {
          toast.error("Error connecting to MetaMask");
        }
        reject();
      }
    }
  });
};
