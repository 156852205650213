import ApiBase from "../app/ApiBase";
import config from "../config/development";
import { objectToQueryString } from "../utils/url";

export const cryptoTags = ApiBase.injectEndpoints({
  endpoints: (builder) => ({
    getCryptoTags: builder.query({
      query: (query) =>
        config.api.endpoints.getCryptoTags(objectToQueryString(query)),
      transformResponse: (response) => {
        // handle successful response and return data to store

        console.log("response: ", response);
        return response;
      },
      providesTags: ["getCryptoTags"],
    }),
    getCryptoTagById: builder.query({
      query: (id) => (id ? config.api.endpoints.getCryptoTag(id) : null),
      transformResponse: (response) => {
        // handle successful response and return data to store

        console.log("response: ", response);
        return response;
      },
      providesTags: ["getCryptoTagById"],
    }),
    createCryptoTag: builder.mutation({
      query: (data) => ({
        url: config.api.endpoints.createCryptoTag,
        method: "POST",
        body: data,
      }),
      transformResponse: (response) => {
        // handle successful response and return data to store

        console.log("response: ", response);
        return response;
      },
      invalidatesTags: ["getCryptoTags", "getCryptoTagById"],
    }),
    updateCryptoTag: builder.mutation({
      query: (data) => ({
        url: config.api.endpoints.updateCryptoTag(data?.tagId),
        method: "PATCH",
        body: data?.tagData,
      }),
      transformResponse: (response) => {
        // handle successful response and return data to store

        console.log("response: ", response);
        return response;
      },
      invalidatesTags: ["getCryptoTags", "getCryptoTagById"],
    }),
    deleteCryptoTag: builder.mutation({
      query: (tagId) => ({
        url: config.api.endpoints.deleteCryptoTag(tagId),
        method: "DELETE",
      }),
      transformResponse: (response) => {
        // handle successful response and return data to store
        return response;
      },
      invalidatesTags: ["getCryptoTags", "getCryptoTagById"],
    }),
  }),
});

// Generate hooks for each endpoint
export const {
  useGetCryptoTagsQuery,
  useGetCryptoTagByIdQuery,
  useCreateCryptoTagMutation,
  useUpdateCryptoTagMutation,
  useDeleteCryptoTagMutation,
} = cryptoTags;
