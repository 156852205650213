export const blogs = {
  getAllBlogs: (query) => `/blog/get-blogs?${query}`,
  createBlogs: "/blog/create-blog",

  createDraftBlogs: "/blog/create-draftBlog",

  updateBlogs: (blogSlug) => `/blog/update-blog/${blogSlug}`,
  getSingleBlog: (blogSlug) => `/blog/get-singleblog/${blogSlug}`,
  deleteBlog: (blogSlug) => `/blog/delete-blog/${blogSlug}`,
};
