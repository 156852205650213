import {
  LoadingOutlined,
  PlusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Card, Col, Form, Input, Row, Tabs } from "antd";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import ImageUploading from "react-images-uploading";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  useFooterSettingGetQuery,
  useFooterUpdateMutation,
  useSiteLogoUpdateMutation,
  useSitelogoGetQuery,
  useSmtpSettingGetQuery,
  useSmtpUpdateMutation,
} from "../services/setting";
import { hasPermission } from "../utils/permissions";

function General_Setting() {
  // -----------------Site Setting---------------------------------
  const { isLoading, data: webSiteLogo, isError } = useSitelogoGetQuery();

  const [updatesiteLogo] = useSiteLogoUpdateMutation();

  const [images, setImages] = useState([]);
  const [dashboardImages, setDashboardImages] = useState([]);
  const [faviconImages, setFaviconImages] = useState([]);

  const isSettingCreate = hasPermission("settings:create");
  const isSettingEdit = hasPermission("settings:edit");
  const isSettingDelete = hasPermission("settings:delete");

  const maxNumber = 1;

  const onChangeWebsiteLogo = (imageList, addUpdateIndex) => {
    setImages(imageList);
    if (addUpdateIndex !== undefined) {
      onUpdateSiteLogo(imageList);
    }
  };
  const onChangeDashboardLogo = (imageList, addUpdateIndex) => {
    setDashboardImages(imageList);
    if (addUpdateIndex !== undefined) {
      onUpdateDashboardLogo(imageList);
    }
  };
  const onChangeFavicon = (imageList, addUpdateIndex) => {
    setFaviconImages(imageList);
    if (addUpdateIndex !== undefined) {
      onUpdateFavicon(imageList);
    }
  };

  const onUpdateSiteLogo = async (images) => {
    const formData = new FormData();

    if (images && images.length > 0) {
      formData.append("image", images[0].file);
      formData.append("type", "website_logo");

      try {
        // Assuming 'updatesiteLogo' is an asynchronous function that handles the upload
        await updatesiteLogo(formData);
        toast.success("Successfully logo update");
      } catch (error) {
        toast.error("Something went wrong");
      }
    } else {
      toast.error("No images found to upload");
    }
  };

  const onUpdateDashboardLogo = async (images) => {
    const formData = new FormData();

    if (images && images.length > 0) {
      formData.append("image", images[0].file);
      formData.append("type", "dashbaord_logo");

      try {
        // Assuming 'updatesiteLogo' is an asynchronous function that handles the upload
        await updatesiteLogo(formData);
        toast.success("Successfully logo update");
      } catch (error) {
        toast.error("Something went wrong");
      }
    } else {
      toast.error("No images found to upload");
    }
  };
  const onUpdateFavicon = async (images) => {
    const formData = new FormData();

    if (images && images.length > 0) {
      formData.append("image", images[0].file);
      formData.append("type", "favicon_logo");

      try {
        // Assuming 'updatesiteLogo' is an asynchronous function that handles the upload
        await updatesiteLogo(formData);
        toast.success("Successfully logo update");
      } catch (error) {
        toast.error("Something went wrong");
      }
    } else {
      toast.error("No images found to upload");
    }
  };

  // ----------------------end site settings--------------------
  // ----------------------Footer settings--------------------

  const { data: footerData } = useFooterSettingGetQuery();
  const [footerImages, setFooterImages] = useState([]);
  const [updateFooterLogo] = useFooterUpdateMutation();
  const [value, setValue] = useState("");

  useEffect(() => {
    if (footerData && footerData.copyright_text) {
      setValue(footerData.copyright_text);
    }
  }, [footerData]);

  const handleQuillChange = (content, delta, source, editor) => {
    // Update the state with the HTML content whenever it changes
    setValue(content);
  };

  // console.log('Footer data ==>',footerData.copyright_text)
  const onChangeFooterLogo = (imageList, addUpdateIndex) => {
    setFooterImages(imageList);
    if (addUpdateIndex !== undefined) {
      onUpdateFooterLogo(imageList);
    }
  };
  const onUpdateFooterLogo = async (images) => {
    const formData = new FormData();
    formData.append("copyright_text", value);

    if (images.length > 0 || value.trim() !== "") {
      if (images && images.length > 0) {
        formData.append("footer_log", images[0].file);
      }

      // formData.append("type", "dashbaord_logo");

      try {
        // Assuming 'updatesiteLogo' is an asynchronous function that handles the upload
        await updateFooterLogo(formData);
        toast.success("Successfully updated");
      } catch (error) {
        toast.error("Something went wrong");
      }
    } else {
      toast.error("No data found to upload");
    }
  };

  // ----------------------end footer settings--------------------
  // ----------------------SMTP--------------------

  // ----------------------end SMTP--------------------

  // Rich Text Editor

  // Footer Modal Handle

  // Upload Footer Logo
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const [webLogoLoading, setWebLogoLoading] = useState(false);
  const [webLogoImageUrl, setWebLogoImageUrl] = useState();
  const [webdashboardImageUrl, setDashboardImageUrl] = useState();
  const [webfaviconImageUrl, setFaviconImageUrl] = useState();
  const [webfooterlogoImageUrl, setFooterlogoImageUrl] = useState();

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleWebsiteLogo = (info) => {
    if (info.file.status === "uploading") {
      setWebLogoLoading(true);
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj)
        .then((url) => {
          setWebLogoLoading(false);
          setWebLogoImageUrl(url);
        })
        .catch((error) => {
          setWebLogoLoading(false);
          console.error("Error converting image to base64:", error);
        });
    }
  };
  const handledashboardLogo = (info) => {
    if (info.file.status === "uploading") {
      setWebLogoLoading(true);
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj)
        .then((url) => {
          setWebLogoLoading(false);
          setDashboardImageUrl(url);
        })
        .catch((error) => {
          setWebLogoLoading(false);
          console.error("Error converting image to base64:", error);
        });
    }
  };
  const handlefaviconLogo = (info) => {
    if (info.file.status === "uploading") {
      setWebLogoLoading(true);
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj)
        .then((url) => {
          setWebLogoLoading(false);
          setFaviconImageUrl(url);
        })
        .catch((error) => {
          setWebLogoLoading(false);
          console.error("Error converting image to base64:", error);
        });
    }
  };
  const handlefooterLogo = (info) => {
    if (info.file.status === "uploading") {
      setWebLogoLoading(true);
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj)
        .then((url) => {
          setWebLogoLoading(false);
          setFooterlogoImageUrl(url);
        })
        .catch((error) => {
          setWebLogoLoading(false);
          console.error("Error converting image to base64:", error);
        });
    }
  };
  const [fileList, setFileList] = useState([]);
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  // const onPreview = async (file) => {
  //   let src = file.url;
  //   if (!src) {
  //     src = await new Promise((resolve) => {
  //       const reader = new FileReader();
  //       reader.readAsDataURL(file.originFileObj);
  //       reader.onload = () => resolve(reader.result);
  //     });
  //   }
  //   const image = new Image();
  //   image.src = src;
  //   const imgWindow = window.open(src);
  //   imgWindow?.document.write(image.outerHTML);
  // };

  const onPreview = async (file) => {
    try {
      if (file.url) {
        window.open(file.url, "_blank");
      } else {
        const previewUrl = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(file.originFileObj);
          reader.onload = () => resolve(reader.result);
        });
        if (previewUrl) {
          window.open(previewUrl, "_blank");
        } else {
          console.error("Failed to generate preview URL");
        }
      }
    } catch (error) {
      console.error("Error while opening image preview:", error);
    }
  };
  const { data: smtpData } = useSmtpSettingGetQuery();

  // console.log("SMTP Data=>", smtpData);
  const [
    smtpUpdate,
    {
      isLoading: isSmtLoading,
      data: smtData,
      isSuccess: isSmtSuccess,
      // refetch
    },
  ] = useSmtpUpdateMutation();

  const initialValues = {
    password: smtpData?.password || "",
    username: smtpData?.username || "",
    port_number: smtpData?.port_number || "",
    hostname: smtpData?.hostname || "",
  };
  const [componentDisabled, setComponentDisabled] = useState(false);
  const onFormLayoutChange = ({ disabled }) => {
    setComponentDisabled(disabled);
  };
  const onFinish = (values) => {
    // console.log("Received values of form: ", values);

    let data = {
      ...values,
    };

    smtpUpdate(data)
      .then((response) => {
        // Handle success
        // console.log("SMTP Update Successful:", response);

        // Show success toast
        toast.success("SMTP Configuration Updated Successfully");
      })
      .catch((error) => {
        // Handle error
        // console.error("SMTP Update Error:", error);

        // Show error toast
        toast.error("Something went wrong while updating");
      });
  };

  useEffect(() => {
    if (!isLoading && !isError && webSiteLogo) {
      const initialImage = webSiteLogo?.website_logo;
      setImages((pre) => {
        return [{ data_url: initialImage }];
      });
      const initialImageDashboard = webSiteLogo?.dashbaord_logo;
      setDashboardImages((pre) => {
        return [{ data_url: initialImageDashboard }];
      });
      const initialImageFavicon = webSiteLogo?.favicon_logo;
      setFaviconImages((pre) => {
        return [{ data_url: initialImageFavicon }];
      });
    }
    if (footerData) {
      const initialImage = footerData?.footer_logo;
      setFooterImages((pre) => {
        return [{ data_url: initialImage }];
      });
    }
  }, [webSiteLogo, isLoading, isError, footerData]);

  return (
    <>
      <Card
        bordered={false}
        className="header-solid mb-24"
        id="general_setting"
        // title={
        //   <>
        //     <h5 className="font-semibold mb-0">General Setting</h5>
        //     <p className="fw-light">Setting up WorkAsPro</p>
        //   </>
        // }
      >
        <Tabs className="main_tab_div" defaultActiveKey="1">
          <Tabs.TabPane tab="Site Setting" key="1">
            <Row gutter={[24, 0]}>
              <Col xs={24} md={12} sm={24} lg={12} xl={12} className="mb-24">
                <h4 className="fw-semibold">Website logo</h4>

                <div>
                  <ImageUploading
                    multiple
                    value={images}
                    onChange={onChangeWebsiteLogo}
                    // onChange={onChangeSiteSetting}

                    maxNumber={maxNumber}
                    dataURLKey="data_url"
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                    }) => (
                      // write your building UI
                      <div className="upload__image-wrapper">
                        {!images.length && (
                          <div className="profile_image_select_box mb-3">
                            <button
                              className="image_selct_box text-decoration-none"
                              onClick={() => {
                                onImageUpload();
                              }}
                              {...dragProps}
                              disabled={!isSettingCreate}
                            >
                              <PlusCircleOutlined /> Upload
                            </button>
                          </div>
                        )}
                        <div>
                          {imageList?.map((image, index) => (
                            <div key={index} className="image-item">
                              <div className="image_upload_box">
                                <img
                                  src={image["data_url"]}
                                  alt=""
                                  width="100"
                                  onClick={() => {
                                    const isDisabled = !isSettingCreate;

                                    if (!isDisabled) {
                                      onImageUpdate(index);
                                    }
                                  }}
                                />
                              </div>
                              <div className="image-item__btn-wrapper px-3">
                                {isSettingCreate && (
                                  <button
                                    onClick={() => {
                                      onImageUpdate(index);
                                      onUpdateSiteLogo(images);
                                      // onUpdateImage('website_logo', images);

                                      // Call this after updating the image
                                    }}
                                    className="btn update_img_btn"
                                    disabled={!isSettingCreate}
                                  >
                                    Update
                                  </button>
                                )}
                                {/* <button
                                  onClick={() => {
                                    onImageRemove(index);
                                  }}
                                  className="btn remove_img_btn"
                                >
                                  Remove
                                </button> */}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </ImageUploading>
                </div>

                <h4 className="fw-semibold mt-3">Admin Dashboard Logo</h4>

                <div>
                  <ImageUploading
                    multiple
                    value={dashboardImages}
                    onChange={onChangeDashboardLogo}
                    maxNumber={maxNumber}
                    dataURLKey="data_url"
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                    }) => (
                      <div className="upload__image-wrapper">
                        {!dashboardImages.length && (
                          <div className="profile_image_select_box mb-3">
                            <button
                              className="image_selct_box text-decoration-none"
                              onClick={() => {
                                onImageUpload();
                              }}
                              {...dragProps}
                              disabled={!isSettingCreate}
                            >
                              <PlusCircleOutlined /> Upload
                            </button>
                          </div>
                        )}
                        <div>
                          {imageList?.map((image, index) => (
                            <div key={index} className="image-item">
                              <div className="image_upload_box">
                                <img
                                  src={image["data_url"]}
                                  alt=""
                                  width="100"
                                  onClick={() => {
                                    const isDisabled = !isSettingCreate;

                                    if (!isDisabled) {
                                      onImageUpdate(index);
                                    }
                                  }}
                                />
                              </div>
                              <div className="image-item__btn-wrapper px-3">
                                {isSettingCreate && (
                                  <button
                                    onClick={() => {
                                      onImageUpdate(index);
                                      onUpdateDashboardLogo(dashboardImages);
                                    }}
                                    className="btn update_img_btn"
                                    disabled={!isSettingCreate}
                                  >
                                    Update
                                  </button>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </ImageUploading>
                </div>

                <h4 className="fw-semibold mt-3">Favicon</h4>

                <div>
                  <ImageUploading
                    multiple
                    value={faviconImages}
                    onChange={onChangeFavicon}
                    maxNumber={maxNumber}
                    dataURLKey="data_url"
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                    }) => (
                      <div className="upload__image-wrapper">
                        {!images.length && (
                          <div className="profile_image_select_box mb-3">
                            <button
                              className="image_selct_box text-decoration-none"
                              onClick={() => {
                                onImageUpload();
                              }}
                              {...dragProps}
                              disabled={!isSettingCreate}
                            >
                              <PlusCircleOutlined /> Upload
                            </button>
                          </div>
                        )}
                        <div>
                          {imageList?.map((image, index) => (
                            <div key={index} className="image-item">
                              <div className="image_upload_box">
                                <img
                                  src={image["data_url"]}
                                  alt=""
                                  width="100"
                                  onClick={() => {
                                    const isDisabled = !isSettingCreate;

                                    if (!isDisabled) {
                                      onImageUpdate(index);
                                    }
                                  }}
                                />
                              </div>
                              <div className="image-item__btn-wrapper px-3">
                                {isSettingCreate && (
                                  <button
                                    onClick={() => {
                                      onImageUpdate(index);
                                      onUpdateFavicon(images);
                                    }}
                                    className="btn update_img_btn"
                                    disabled={!isSettingCreate}
                                  >
                                    Update
                                  </button>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </ImageUploading>
                </div>
              </Col>
            </Row>
          </Tabs.TabPane>

          <Tabs.TabPane tab="Footer Setting" key="3">
            <Row gutter={[24, 0]}>
              <Col xs={24} md={12} sm={24} lg={12} xl={12} className="mb-24">
                <h4 className="fw-semibold">Upload Footer logo</h4>
                <div>
                  <ImageUploading
                    multiple
                    value={footerImages}
                    onChange={onChangeFooterLogo}
                    maxNumber={maxNumber}
                    dataURLKey="data_url"
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                    }) => (
                      <div className="upload__image-wrapper">
                        {!footerImages.length && (
                          <div className="profile_image_select_box mb-3">
                            <button
                              className="image_selct_box text-decoration-none"
                              onClick={() => {
                                onImageUpload();
                              }}
                              {...dragProps}
                              disabled={!isSettingCreate}
                            >
                              <PlusCircleOutlined /> Upload
                            </button>
                          </div>
                        )}
                        <div>
                          {imageList?.map((image, index) => (
                            <div key={index} className="image-item">
                              <div className="image_upload_box">
                                <img
                                  src={image["data_url"]}
                                  alt=""
                                  width="100"
                                  onClick={() => {
                                    const isDisabled = !isSettingCreate;

                                    if (!isDisabled) {
                                      onImageUpdate(index);
                                    }
                                  }}
                                />
                              </div>
                              <div className="image-item__btn-wrapper px-3">
                                {isSettingCreate && (
                                  <button
                                    onClick={() => {
                                      onImageUpdate(index);
                                      // onUpdateFooterLogo(footerImages);
                                    }}
                                    className="btn update_img_btn"
                                    disabled={!isSettingCreate}
                                  >
                                    Update
                                  </button>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </ImageUploading>
                </div>
              </Col>
            </Row>
            <Row gutter={[24, 0]}>
              <Col xs={24} md={12} sm={24} lg={12} xl={12} className="mb-24">
                <h4 className="fw-semibold">Manage Copyright Text</h4>

                <ReactQuill
                  theme="snow"
                  value={value}
                  onChange={handleQuillChange}
                  style={{ height: "150px" }}
                />
                <div className="d-flex justify-content-end mt-3">
                  <button
                    disabled={!isSettingCreate}
                    className="add_text_btn mt-5"
                    onClick={() => onUpdateFooterLogo(footerImages, value)}
                  >
                    Add Text
                  </button>
                </div>
                {/* <Input
                  placeholder="Enter Copyright Phrase"
                /> */}
              </Col>
              {/* <Col xs={24} md={24} sm={24} lg={24} xl={24} className="mb-24">
                <h3>
                  Manage Footer Menus
                </h3>
                <div className="d-flex justify-content-start" >
                  <Button type="primary me-3" onClick={() => setModal1Open(true)}>
                    <PlusOutlined /> Add Categories
                  </Button>
                  <Modal
                    title="Add Categories"
                    centered
                    visible={modal1Open}
                    onOk={() => setModal1Open(false)}
                    onCancel={() => setModal1Open(false)}
                    footer={[
                      <Button key="back">
                        Cancle
                      </Button>,
                      <Button key="submit" type="primary">
                        Add
                      </Button>,
                    ]}
                  >
                    <p>Add New Catrgory</p>
                    <Input placeholder="Enter Category" />
                  </Modal>

                  <Button type="primary" onClick={() => setModal2Open(true)}>
                    <PlusOutlined /> Add Menus
                  </Button>
                  <Modal
                    title="Add Menus"
                    centered
                    visible={modal2Open}
                    onOk={() => setModal2Open(false)}
                    onCancel={() => setModal2Open(false)}
                    footer={[
                      <Button key="back">
                        Cancle
                      </Button>,
                      <Button key="submit" type="primary">
                        Add
                      </Button>,
                    ]}
                  >
                    <p>Select Category</p>
                    <Select
                      defaultValue="navigation"
                      className="w-100 mb-2"
                      // style={{
                      //   width: 100,
                      // }}
                      onChange={selectCategory}
                      options={[
                        {
                          value: 'navigation',
                          label: 'Navigation',
                        },
                        {
                          value: 'seller',
                          label: 'Seller',
                        },
                        {
                          value: 'buyer',
                          label: 'Buyer',
                        },
                        {
                          value: 'connectivity',
                          label: 'Connectivity',
                        },
                      ]}
                    />
                    <p>Link Name</p>
                    <Input className="mb-2" placeholder="Enter Link Name" />
                    <p>Link URL</p>
                    <Input placeholder="Enter Link URL" />
                  </Modal>
                </div>
              </Col> */}
              {/* <Col xs={24} md={12} sm={24} lg={12} xl={12} className="mb-24">
                <Table columns={category_columns} dataSource={category_data} pagination={false} />
              </Col>
              <Col xs={24} md={12} sm={24} lg={12} xl={12} className="mb-24">
                <Table columns={menu_columns} dataSource={menu_data} pagination={false} />
              </Col> */}
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane tab="SMTP Configuration" key="4">
            <Row gutter={[24, 0]}>
              <Col xs={24} md={24} sm={24} lg={24} xl={24} className="mb-24">
                <div className="">
                  <div>
                    <h4 className="fw-semibold">Configure SMTP (Mail)</h4>
                  </div>
                  <div>
                    <Form
                      name="complex-form"
                      initialValues={initialValues}
                      onValuesChange={onFormLayoutChange}
                      disabled={!isSettingCreate}
                      onFinish={onFinish}
                      labelCol={{
                        span: 24,
                      }}
                      wrapperCol={{
                        span: 24,
                      }}
                    >
                      <Row gutter={[24, 0]}>
                        <Col xs={24} md={12} sm={24} lg={12} xl={12}>
                          <Form.Item
                            label="Username"
                            name="username"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <Input placeholder="Enter Username" />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={12} sm={24} lg={12} xl={12}>
                          <Form.Item
                            label="Password"
                            name="password"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <Input placeholder="Enter Password" />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={12} sm={24} lg={12} xl={12}>
                          <Form.Item
                            label="Port"
                            name="port_number"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <Input placeholder="Enter Port" />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={12} sm={24} lg={12} xl={12}>
                          <Form.Item
                            label="Host"
                            name="hostname"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <Input placeholder="Enter Host" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Form.Item label=" " colon={false}>
                        <Button type="primary" htmlType="submit">
                          Configure
                        </Button>
                      </Form.Item>
                    </Form>
                  </div>
                </div>
              </Col>
            </Row>
          </Tabs.TabPane>
        </Tabs>
      </Card>
    </>
  );
}

export default General_Setting;
