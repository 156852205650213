import ApiBase from "../app/ApiBase";
import config from "../config/development";
import { objectToQueryString } from "../utils/url";

const settingApi = ApiBase.enhanceEndpoints({
  addTagTypes: ["Setting"],
}).injectEndpoints({
  endpoints: (builder) => ({
    siteLogoUpdate: builder.mutation({
      query: (body) => ({
        url: config.api.endpoints.siteLogo,
        method: "PATCH",
        body,
      }),
      transformResponse: (response) => {
        // console.log(response, "Website Logo Response Site Logo");
        // handle successful response and return data to store
        return response;
      },
    }),

    sitelogoGet: builder.query({
      query: (query) =>
        config.api.endpoints.websitelogo(objectToQueryString(query)),
      transformResponse: (response) => {
        // console.log( "Response Site Logo Get",response);
        return response;
      },
    }),

    footerSettingGet: builder.query({
      query: (query) =>
        config.api.endpoints.footerGet(objectToQueryString(query)),
      transformResponse: (response) => {
        // console.log( "Response Site Logo Get",response);
        return response;
      },
    }),

    footerUpdate: builder.mutation({
      query: (formData) => ({
        url: config.api.endpoints.footer,
        method: "PUT",
        body: formData,
      }),
      transformResponse: (response) => {
        // handle successful response and return data to store
        return response;
      },
    }),
    smtpUpdate: builder.mutation({
      query: (formData) => ({
        url: config.api.endpoints.smtp,
        method: "PUT",
        body: formData,
      }),
      transformResponse: (response) => {
        // handle successful response and return data to store
        return response;
      },
    }),
    smtpSettingGet: builder.query({
      query: (query) => config.api.endpoints.smtpGet,
      transformResponse: (response) => {
        // console.log("Response SMTP Get", response);
        return response;
      },
    }),
  }),
});
export const {
  useSiteLogoUpdateMutation,
  useSitelogoGetQuery,
  useFooterSettingGetQuery,
  useFooterUpdateMutation,
  useSmtpUpdateMutation,
  useSmtpSettingGetQuery,
} = settingApi;
