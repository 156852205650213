import ApiBase from "../app/ApiBase";
import config from "../config/development";
import { objectToQueryString } from "../utils/url";

export const cryptoJobApi = ApiBase.injectEndpoints({
  endpoints: (builder) => ({
    getCryptoJobs: builder.query({
      query: (query) =>
        config.api.endpoints.getCryptoJobs(objectToQueryString(query)),
      transformResponse: (response) => {
        // handle successful response and return data to store
        console.log("response: ", response);
        return response;
      },
      keepUnusedDataFor: 1000,
      providesTags: ["cryptoJobs"],
    }),
    getCryptoJobDetails: builder.query({
      query: (query) => config.api.endpoints.getCryptoJobDetail(query?.slug),
      transformResponse: (response) => {
        // handle successful response and return data to store
        // console.log("response: ", response);
        return response;
      },
      // keepUnusedDataFor: 1000,
      providesTags: ["cryptoJobs"],
    }),
    getCryptoJobById: builder.query({
      query: (jobId) => config.api.endpoints.getCryptoJobById(jobId),
      transformResponse: (response) => {
        // handle successful response and return data to store
        console.log("response: ", response);
        return response;
      },
      keepUnusedDataFor: 1000,
      providesTags: ["cryptoJobById"],
    }),
    createCryptoJob: builder.mutation({
      query: (data) => ({
        url: config.api.endpoints.createCryptoJob,
        method: "POST",
        body: data,
      }),
      transformResponse: (response) => {
        // handle successful response and return data to store

        console.log("response: ", response);
        return response;
      },
      invalidatesTags: ["cryptoJobById", "cryptoJobs"],
    }),
    updateCryptoJob: builder.mutation({
      query: (data) => ({
        url: config.api.endpoints.updateCryptoJobById(data?.jobId),
        method: "PATCH",
        body: data?.jobData,
      }),
      transformResponse: (response) => {
        // handle successful response and return data to store

        console.log("response: ", response);
        return response;
      },
      invalidatesTags: ["cryptoJobById", "cryptoJobs", "cryptoApplicants"],
    }),
    deleteCryptoJobs: builder.mutation({
      query: (jobId) => ({
        url: config.api.endpoints.deleteCryptoJob(jobId),
        method: "DELETE",
      }),
      transformResponse: (response) => {
        // handle successful response and return data to store
        return response;
      },
      invalidatesTags: ["cryptoJobById", "cryptoJobs"],
    }),
  }),
});

// Generate hooks for each endpoint
export const {
  useCreateCryptoJobMutation,
  useDeleteCryptoJobsMutation,
  useGetCryptoJobByIdQuery,
  useGetCryptoJobDetailsQuery,
  useUpdateCryptoJobMutation,
  useGetCryptoJobsQuery,
} = cryptoJobApi;
