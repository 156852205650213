export const OrderStatuses = {
  PENDING: "PENDING",
  DELIVERED: "DELIVERED",
  CANCELLED_BY_SELLER: "CANCELLED BY SELLER",
  CANCELLED_BY_BUYER_BEFORE_DELIVERY: "CANCELLED BY BUYER BEFORE DELIVERY",
  CANCELLED_BY_BUYER_AFTER_DELIVERY: "CANCELLED BY BUYER AFTER DELIVERY",
  ACCEPTED: "ACCEPTED",
  LATE: "LATE",
  DISPUTED_BY_SELLER: "DISPUTED BY SELLER",
  DISPUTED_BY_BUYER: "DISPUTED BY BUYER",
  DISPUTE_WON_BY_SELLER: "DISPUTE WON BY SELLER",
  DISPUTE_WON_BY_BUYER: "DISPUTE WON BY BUYER",
  OPEN_TO_DISPUTE: "OPEN TO DISPUTE",
};
