import React from "react";
import { RxCross2 } from "react-icons/rx";

const Permissionsdropdown = ({
  formData,
  setActionsN,
  setResource,
  handleRemovePermission,
}) => {
  return (
    <div>
      {formData.permissions?.length > 0 && (
        <h3 className="assigned_permissions_h3 ps-2">Permissions Assigned:</h3>
      )}
      <ul className="ps-0 permissions_scroll_ul mt-3">
        {formData.permissions.map((p, rIndex) => (
          <li style={{ listStyle: "none" }} key={p.resource} className="">
            <span style={{ cursor: "pointer" }} className="nav-link pe-3">
              <p
                className="ms-2 action_btn"
                onClick={() => {
                  setResource(p.resource);
                  setActionsN(p.actions);
                }}
              >
                {p.resource}
                <RxCross2
                  className="float-end mt-1"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setResource("");
                    setActionsN([]);
                    handleRemovePermission(rIndex);
                  }}
                />
              </p>
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Permissionsdropdown;
