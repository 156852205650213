import { cryptoApplicants } from "./applicants";
import { auth } from "./auth";
import { blogs } from "./blogs";
import { category } from "./category";
import { conversation } from "./conversation";
import { cryptoAddress } from "./cryptoAddress";
import { cryptoCompany } from "./cryptoCompany";
import { cryptoCountry } from "./cryptoCountry";
import { cryptoJob } from "./cryptoJob";
import { cryptoTag } from "./cryptoTag";
import { dashboard } from "./dashboard";
import { dispute } from "./dispute";
import { setting } from "./setting";
import { subscriber } from "./subscriber";
import { technology } from "./technology";
import { user } from "./user";

export const endpoints = {
  ...auth,
  ...dashboard,
  ...user,
  ...subscriber,
  ...dispute,
  ...category,
  ...technology,
  ...setting,
  ...blogs,
  ...conversation,
  ...cryptoJob,
  ...cryptoCompany,
  ...cryptoTag,
  ...cryptoCountry,
  ...cryptoAddress,
  ...cryptoApplicants,
};
