import { React } from "react";

import { Card, Col, Row, Tag, Typography } from "antd";

import { BiRevision } from "react-icons/bi";
import { IoTimerOutline } from "react-icons/io5";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useGetUserOrderByIdQuery } from "../services/user";
import { renderTruncatedDescription } from "./Profile";

// TypoGraphy
const { Title, Text, Link } = Typography;
// End Typography

export function convertDateTimeToCustomFormat(endDateTime) {
  // Convert endDateTime to a Date object
  const dateObj = new Date(endDateTime);

  // Extract date components
  const day = dateObj.getDate();
  const month = dateObj.getMonth() + 1;
  const year = dateObj.getFullYear();
  const hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();

  // Format date in "MM/DD/YYYY" format
  const formattedDate = `${month.toString().padStart(2, "0")}/${day
    .toString()
    .padStart(2, "0")}/${year}`;

  // Format time in "HH:mm" format
  const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;

  // Combine date and time
  const formattedDateTime = `(${formattedTime})`;

  return formattedDate + " " + formattedDateTime;
}

function Order_Detail() {
  const { id } = useParams();

  const {
    isLoading,
    isSuccess,
    data: order,
  } = useGetUserOrderByIdQuery({
    orderId: id,
  });

  console.log("order => ", order);
  const isSellerOrder = order?.gigId && Object.keys(order.gigId).length > 0;

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col xs={24} md={24} sm={24} lg={24} xl={24} className="mb-24">
          <Card>
            {/* <img className="main_gig_detail_img mb-24" src="https://images.pexels.com/photos/585752/pexels-photo-585752.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /> */}
            <div>
              <Row gutter={[24, 0]} className="mb-3">
                <Col xs={24} md={12} sm={24} lg={6} xl={6} className="">
                  <p className="font-bold mb-1 all_text_fontsize">
                    Order Status
                  </p>
                  <p className="mb-0 gig_detail_options all_text_fontsize">
                    {order?.status}
                  </p>
                </Col>
                <Col xs={24} md={12} sm={24} lg={6} xl={6} className="">
                  <p className="font-bold mb-1 all_text_fontsize">
                    Order Number
                  </p>
                  <p className="mb-0 gig_detail_options all_text_fontsize">
                    {order?.orderId}
                  </p>
                </Col>
                <Col xs={24} md={12} sm={24} lg={6} xl={6} className="">
                  <p className="font-bold mb-1 all_text_fontsize">
                    Start Date / Time
                  </p>
                  <p className="mb-0 gig_detail_options all_text_fontsize">
                    {convertDateTimeToCustomFormat(
                      order?.deliverySchedule?.startDateTime,
                    )}
                  </p>
                </Col>
                <Col xs={24} md={12} sm={24} lg={6} xl={6} className="">
                  <p className="font-bold mb-1 all_text_fontsize">
                    End Date / Time
                  </p>
                  <p className="mb-0 gig_detail_options all_text_fontsize">
                    {convertDateTimeToCustomFormat(
                      order?.deliverySchedule?.endDateTime,
                    )}
                  </p>
                </Col>
              </Row>
            </div>
            <div>
              <Row gutter={[24, 0]} className="mb-24">
                <Col xs={24} md={24} sm={24} lg={16} xl={16} className="mb-24">
                  <Title level={3} className="mb-0">
                    {isSellerOrder
                      ? order.gigId.gigtitle
                      : order?.jobId &&
                        order.jobId.job_Id &&
                        order.jobId.job_Id.jobtitle}
                  </Title>
                  <p className="mb-24 all_text_fontsize">
                    {renderTruncatedDescription(
                      isSellerOrder
                        ? order.gigId.gigdescription
                        : order?.jobId &&
                            order.jobId.job_Id &&
                            order.jobId.job_Id.jobdescription,
                      1000,
                    )}
                  </p>
                  <div className="mb-4">
                    {/* <Title level={5}>
                      Related Gig:{" "}
                      <Link to="/gig_detail">
                        I'll Create Lorem ipsum dolor
                      </Link>
                    </Title> */}
                  </div>
                  <div className="mb-24">
                    <Title className="all_text_fontsize fw-bold">
                      {order?.jobId?.job_Id?.skills ? "Skill" : ""}
                    </Title>
                    {isSellerOrder
                      ? ""
                      : order?.jobId?.job_Id?.skills.map((e) => (
                          <Tag color="#F47458">{e}</Tag>
                        ))}
                  </div>
                  <Row gutter={[24, 0]}>
                    <Col
                      xs={24}
                      md={12}
                      sm={24}
                      lg={6}
                      xl={6}
                      className="mb-24"
                      style={{ textAlign: "center" }}
                    >
                      <p className="font-bold mb-0 mt-3 d-flex align-items-center justify-content-center all_text_fontsize">
                        <BiRevision className="me-2" />
                        Revisions
                      </p>
                      <p className="mb-0 gig_detail_options all_text_fontsize">
                        {isSellerOrder
                          ? order?.gigId?.gigOffers[0]?.revisions
                          : order?.jobId?.purposal_Id?.revisions}
                      </p>
                    </Col>
                    <Col
                      xs={24}
                      md={12}
                      sm={24}
                      lg={6}
                      xl={6}
                      className="mb-24"
                      style={{ textAlign: "center" }}
                    >
                      <p className="font-bold mb-0 mt-3 d-flex align-items-center justify-content-center all_text_fontsize">
                        <IoTimerOutline className="me-2" />
                        Days
                      </p>
                      <p className="mb-0 gig_detail_options all_text_fontsize">
                        {order?.deliveryDays}
                      </p>
                    </Col>
                    <Col
                      xs={24}
                      md={12}
                      sm={24}
                      lg={6}
                      xl={6}
                      className="mb-24 text-center"
                    >
                      <p className="font-bold mb-0 mt-3 all_text_fontsize">
                        Assigned to
                      </p>
                      <Link
                        href={`/profile/${order?.sellerId?.username}`}
                        className="mb-0 gig_detail_options all_text_fontsize"
                      >
                        {order?.sellerId?.username}
                      </Link>
                    </Col>
                    <Col
                      xs={24}
                      md={12}
                      sm={24}
                      lg={6}
                      xl={6}
                      className="mb-24 text-center"
                    >
                      <p className="font-bold mb-0 mt-3 all_text_fontsize">
                        Assigned by
                      </p>
                      <Link
                        href={`/profile/${order?.user?.username}`}
                        className="mb-0 gig_detail_options all_text_fontsize"
                      >
                        {order?.user?.username}
                      </Link>
                    </Col>
                  </Row>
                  <Row gutter={[24, 0]}>
                    <Col
                      xs={24}
                      md={12}
                      sm={24}
                      lg={6}
                      xl={6}
                      className="mb-24 text-center"
                    >
                      <p className="font-bold mb-0 mt-3 all_text_fontsize">
                        Category
                      </p>
                      <p className="mb-0 gig_detail_options all_text_fontsize">
                        {isSellerOrder
                          ? order?.gigId?.gigcategory
                          : order?.jobId?.job_Id?.jobcategory}
                      </p>
                    </Col>
                    <Col
                      xs={24}
                      md={12}
                      sm={24}
                      lg={6}
                      xl={6}
                      className="mb-24 text-center"
                    >
                      <p className="font-bold mb-0 mt-3 all_text_fontsize">
                        Budget ($)
                      </p>
                      <p className="mb-0 gig_detail_options all_text_fontsize">
                        {order?.cryptoToken?.baseAmount || 0}
                      </p>
                    </Col>
                    <Col
                      xs={24}
                      md={12}
                      sm={24}
                      lg={6}
                      xl={6}
                      className="mb-24 text-center"
                    >
                      <p className="font-bold mb-0 mt-3 all_text_fontsize">
                        Payment Method
                      </p>
                      <p className="mb-0 gig_detail_options all_text_fontsize">
                        {order?.cryptoToken?.name}
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default Order_Detail;
