import axios from "axios";
import toast from "react-hot-toast";
import config from "../config/development";

export const handleDownloadClick = async ({ url, fileName }) => {
  try {
    toast.remove(1);
    toast.loading(`File is being downloaded...`, { id: 1 });
    const response = await axios.get(url, {
      params: {
        url: url,
        fileName: fileName,
      },
      responseType: "blob", // Set the response type to blob
      headers: {
        Origin: window.location.origin, // Add Origin header
      },
    });

    toast.remove(1);
    toast.success("File is downloaded successfully");
    // Create a blob object from the response data
    const blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });

    // Create a temporary URL for the blob
    const downloadLink = window.URL.createObjectURL(blob);

    // Create a link element
    const link = document.createElement("a");
    link.href = downloadLink;
    link.setAttribute("download", fileName);

    // Append the link to the document body and trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up
    link.parentNode.removeChild(link);
    window.URL.revokeObjectURL(downloadLink);
  } catch (error) {
    console.error("Error:", error);
    // Handle error
    toast.remove(1);
    toast.error("Error downloading the file");
  }
};

export const handleCustomDownload = async ({ url, fileName }) => {
  try {
    const urlBackend = config.api.baseUrl + "/user/file/download";

    toast.remove(1);
    toast.loading(`File is being downloaded...`, { id: 1 });

    const response = await axios.get(urlBackend, {
      params: {
        url,
        fileName,
      },
      responseType: "blob", // Set the response type to blob
    });

    toast.remove(1);
    toast.success("File is downloaded successfully");

    // Create a blob object from the response data
    const blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });

    // Create a temporary URL for the blob
    const downloadLink = window.URL.createObjectURL(blob);

    // Create a link element
    const link = document.createElement("a");
    link.href = downloadLink;
    link.setAttribute("download", fileName);

    // Append the link to the document body and trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up
    link.parentNode.removeChild(link);
    window.URL.revokeObjectURL(downloadLink);
  } catch (error) {
    console.error("Error:", error);
    toast.remove(1);
    toast.error("Error downloading the file");
  }
};

export function formatSize(sizeInBytes) {
  const GB = 1024 * 1024 * 1024;
  const MB = 1024 * 1024;
  const KB = 1024;

  if (sizeInBytes >= GB) {
    return (sizeInBytes / GB).toFixed(2) + " GB";
  } else if (sizeInBytes >= MB) {
    return (sizeInBytes / MB).toFixed(2) + " MB";
  } else if (sizeInBytes >= KB) {
    return (sizeInBytes / KB).toFixed(2) + " KB";
  } else {
    return sizeInBytes + " Bytes";
  }
}
