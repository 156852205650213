import { Card, Typography } from "antd";
import moment from "moment";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const { Text } = Typography;

const NotificationLayout = ({ notifications, tab }) => {
  useEffect(() => {
    const formatRelativeTime = (number, withoutSuffix, key, isFuture) => {
      // console.log({ number, withoutSuffix, key, isFuture });
      const weeks = Math.floor(number / 7);

      if (number < 7) return number + " d";

      if (number < 14 && number > 6) {
        return isFuture ? "in 1 w" : "1 w";
      } else if (number > 13) {
        return withoutSuffix ? `${weeks} w` : `${weeks} w ago`;
      }
    };
    moment.updateLocale("en", {
      relativeTime: {
        future: "in %sec",
        past: "%sec ago",
        s: "few sec ago",
        ss: "%d s",
        m: "1 min",
        mm: "%d min",
        h: "1 h",
        hh: "%d h",
        d: "1 d",
        dd: formatRelativeTime,
        w: "1 w",
        ww: "%d w",
        M: "1 mon",
        MM: "%d mon",
        y: "1 y",
        yy: "%d y",
      },
    });
  }, []);

  const getTitle = (notification) => {
    let title = "";
    let url = "";

    const modify = (title) => {
      return `${notification.title} (${title}) `;
    };
    if (notification?.type === "Job") {
      title = modify(notification?.job?.jobtitle);
      url = `/job_detail/${notification?.job?._id}`;
    } else if (notification?.type === "Gig") {
      title = modify(notification?.gig?.gigtitle);
      url = `/gig_detail/${notification?.gig?._id}`;
    } else if (notification?.type === "User") {
      title = modify(notification?.user?.username);
      url = `/profile/${notification?.user?.username}`;
    } else if (
      notification?.type === "new_order" ||
      notification?.type === "order_cancel"
    ) {
      title = "Order No: " + notification?.order?.uniqueOrderId;
      url = `/order_detail/${notification?.order?._id}`;
    }

    return { title, url };
  };
  return (
    <div className="">
      <div
        className="d-flex flex-column h-100 message_notification_height"
        style={{
          justifyContent: "start",
          alignItems: "center",
          overflowY: "auto",
          zIndex: "10000",
          maxHeight: "335px",
        }}
      >
        <>
          {notifications?.length === 0 ? (
            <>
              {" "}
              <Card
                className="mb-1"
                style={{ width: "100%" }}
                title={<Text>No Notifications</Text>}
              />
            </>
          ) : (
            notifications?.map((notification, index) => (
              <div
                key={index}
                className={` ${
                  index == 0
                    ? "notifications_layout_div"
                    : "notications_layout_color"
                }`}
                style={{ width: "100%" }}
              >
                <div className="d-flex ">
                  <div>
                    <p className="mb-0 pt-2 ps-3 grt_title_name">
                      <Link
                        to={getTitle(notification)?.url}
                        className="get_title_name"
                      >
                        {getTitle(notification)?.title}
                      </Link>
                    </p>
                  </div>
                </div>
                <div style={{ textAlign: "right" }} className="my-0 pe-3 pb-2">
                  <Text className="notification-time" style={{ color: "#888" }}>
                    {moment(notification?.createdAt).fromNow(true)}
                  </Text>
                </div>
              </div>
            ))
          )}
        </>
      </div>
    </div>
  );
};

export default NotificationLayout;
