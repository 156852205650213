import { Card, Col, Row } from "antd";
import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Blogpreview = (props) => {
  const { blogs, slug, query } = props.location.state || {};
  console.log(blogs, ">>>blogs in next");
  const history = useHistory();
  const clickedBlog = blogs?.blogs.find((blog) => blog.slug === slug);
  console.log("clickedBlog", clickedBlog);
  return (
    <div>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <Col xs={24} md={24} sm={24} lg={24} xl={24} className="mb-24">
            <Card bordered={false} className="criclebox mb-5">
              <div>
                <h5 className="blogs_view_title">{clickedBlog?.title}</h5>
                <p className="mb-2">
                  <b>Blog Description :</b>{" "}
                </p>
                <span
                  className="mb-0"
                  dangerouslySetInnerHTML={{
                    __html: clickedBlog?.body,
                  }}
                ></span>
                <div>
                  <p className="mb-2">
                    <b>Meta Title :</b>{" "}
                    {clickedBlog?.metaTitle ? (
                      <> {clickedBlog?.metaTitle}</>
                    ) : (
                      "N/A"
                    )}
                  </p>

                  <p className="mb-2 ">
                    <b>Meta Description :</b>{" "}
                    {clickedBlog?.metaDescription ? (
                      <span className="textControl">
                        {" "}
                        {clickedBlog?.metaDescription}
                      </span>
                    ) : (
                      "N/A"
                    )}
                  </p>

                  <p className="mb-2">
                    <b>Meta Tags :</b>{" "}
                  </p>
                  <div className="mb-2">
                    {clickedBlog?.metaTags ? (
                      <>
                        {clickedBlog?.metaTags?.map((tags, index) => (
                          <span
                            key={index}
                            className={`span_skill_tag pt-1 me-2`}
                          >
                            <span className="">{tags}</span>
                          </span>
                        ))}
                      </>
                    ) : (
                      "N/A"
                    )}
                  </div>

                  <p className="mb-2">
                    <b>Slug :</b>{" "}
                    {clickedBlog?.slug ? <> {clickedBlog?.slug}</> : "N/A"}
                  </p>

                  <p className="mb-2">
                    <b>Categories :</b>{" "}
                  </p>
                  <div className="mb-2">
                    {clickedBlog?.categories ? (
                      <>
                        {clickedBlog?.categories?.map((category, index) => (
                          <span
                            key={index}
                            className={`span_skill_tag pt-1 me-2`}
                          >
                            <span className="">{category.name}</span>
                          </span>
                        ))}
                      </>
                    ) : (
                      "N/A"
                    )}
                  </div>

                  <p className="mb-2">
                    <b>Media :</b>{" "}
                    {clickedBlog?.media ? (
                      <div>
                        <img
                          src={clickedBlog?.media}
                          className="w-25 h-25"
                          alt=""
                        />
                      </div>
                    ) : (
                      "N/A"
                    )}
                  </p>
                </div>

                <div className="text-end">
                  {/* {isblogEdit && ( */}
                  <a
                    onClick={() => {
                      history.push({
                        pathname: "/add_new_blog",
                        state: { slug: clickedBlog?.slug, query },
                      });
                    }}
                    className="edit_blog_button"
                  >
                    Edit Blog
                  </a>
                  {/* )} */}
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Blogpreview;
