import React, { createContext, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useSocketInstance from "./useSocketInstance";

const SocketContext = createContext();

export const useSocket = () => {
  return useContext(SocketContext);
};

export const SocketProvider = ({ children }) => {
  const socket = useSocketInstance();
  const user = useSelector((state) => state.user?.user);
  const [isSocketConnected, setIsSocketConnected] = useState(false);

  useEffect(() => {
    socket?.connect();
    setIsSocketConnected(true);

    const handleUserConnected = (user) => {
      console.log("user connected", user);
    };

    socket?.on("user connected", handleUserConnected);

    return () => {
      socket?.off("user connected", handleUserConnected);
    };
  }, [socket]);

  const reInstantiateSocketConnection = () => {
    if (!isSocketConnected) {
      if (user.isLoggedIn) {
        socket?.connect();
        setIsSocketConnected(true);
      }
    }
  };

  const disconnectSocket = () => {
    if (isSocketConnected) {
      socket?.disconnect();
      setIsSocketConnected(false);
    }
  };

  const contextValue = {
    reInstantiateSocketConnection,
    isSocketConnected,
    disconnectSocket,
    socket,
  };

  return (
    <SocketContext.Provider value={contextValue}>
      {children}
    </SocketContext.Provider>
  );
};
