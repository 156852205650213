import { Card, Col, Row, Spin, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
  useGetConversationsQuery,
  useGetMessagesQuery,
} from "../services/conversation";
import { ChatMessages } from "./ChatMessages";
import Conversations from "./conversations";
const { Title, Text, Paragraph } = Typography;
const PersonalChat = () => {
  const { id } = useParams();
  console.log("id => ", id);
  let allMessages = [];
  const [selectedConvo, setSelectedConvo] = useState({});
  const [selectedConvoUser, setSelectedConvoUser] = useState({});
  const [currentConvoUser, setCurrentConvoUser] = useState({});
  let setActivePurposal = () => {};
  const { data, isLoading } = useGetMessagesQuery(selectedConvo?._id);
  const { data: conversations, isLoading: conversationsLoading } =
    useGetConversationsQuery({
      id,
      query: { isAdmin: false },
    });

  useEffect(() => {
    if (selectedConvo?._id) {
      setSelectedConvoUser(
        selectedConvo?.members?.find((item) => {
          if (item._id !== id) {
            return item;
          }
        }),
      );
    }
  }, [selectedConvo?._id]);
  useEffect(() => {
    if (selectedConvo?._id) {
      setCurrentConvoUser(
        selectedConvo?.members?.find((item) => {
          if (item._id === id) {
            return item;
          }
        }),
      );
    }
  }, [selectedConvo?._id]);
  console.log({ data, conversations });
  return (
    <div>
      <div>
        <div className="layout-content">
          <Row gutter={[24, 0]}>
            <Col xs={24} md={24} sm={24} lg={24} xl={24} className="mb-24">
              <Card bordered={false} className="criclebox mb-5">
                <Row gutter={[24, 0]}>
                  <Col xs={24} md={24} sm={24} lg={12} xl={18} className="mb-3">
                    <div className="project-ant ps-2">
                      <div>
                        <Title className="mb-0" level={3}>
                          Inbox
                        </Title>
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-xxl-3 col-xl-4 col-lg-4">
                      <div className="profile_description_box heightAdjustment py-3 px-3">
                        {conversationsLoading ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "90vh",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <Spin
                                size="large"
                                tip="Loading Conversations data..."
                              />
                              <div
                                style={{
                                  marginTop: "10px",
                                  fontSize: "0.95em",
                                }}
                              >
                                Please wait, we're preparing your Conversations!
                              </div>
                            </div>
                          </div>
                        ) : (
                          <>
                            {conversations?.map((conversation) => {
                              return (
                                <span>
                                  <Conversations
                                    setSelectedConvo={setSelectedConvo}
                                    // SelectConvo={conversations[0]}
                                    selectedConvoStatus={
                                      conversations[0]?.status
                                    }
                                    updateMessage={() => {}}
                                    conversation={conversation}
                                    // currentUser={user?.userDetail?.user._id}
                                    userDetail={conversation?.members?.find(
                                      (item) => {
                                        if (item._id !== id) {
                                          return item;
                                        }
                                      },
                                    )}
                                    active={selectedConvo}
                                    updateConversationStatus={() => {}}
                                  />
                                </span>
                              );
                            })}
                          </>
                        )}
                      </div>
                    </div>
                    {selectedConvoUser?.username ? (
                      <div className="col-xxl-9 col-xl-8 col-lg-8">
                        <div className="chat_top_header">
                          <p className="mb-0 chat_top_header_heading">
                            {selectedConvoUser?.username}
                          </p>
                        </div>
                        <div className="messages_bg_individual">
                          <div className="messgaes_height_individual">
                            {isLoading ? (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "60vh",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                  }}
                                >
                                  <Spin
                                    size="large"
                                    tip="Loading Messages data..."
                                  />
                                  <div
                                    style={{
                                      marginTop: "10px",
                                      fontSize: "0.95em",
                                    }}
                                  >
                                    Please wait, we're preparing your Messages!
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <ChatMessages
                                messages={data}
                                setActivePurposal={setActivePurposal}
                                selectedConvo={selectedConvo}
                                selectedConvoUser={selectedConvoUser}
                                currentConvoUser={currentConvoUser}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="col-xxl-9 col-xl-8 col-lg-8">
                        <div className="inbox_chat_outer_div">
                          <div className="chat_wrapper">
                            <div className="inbox_background" id="section-1">
                              <div className="d-flex justify-content-center align-items-center h-100">
                                <div>
                                  <div className="text-center pb-3">
                                    <img
                                      src="https://work-as-pro.s3.us-east-2.amazonaws.com/staticPictures/wapLogo.png"
                                      className="img-fluid"
                                      style={{ width: "120px" }}
                                    />
                                  </div>

                                  <div>
                                    <p className="chatEmptyHeading mb-0">
                                      Pick up where you left off
                                    </p>
                                    <div className="text-center text-secondary">
                                      <p className="profile_location">
                                        Select a conversation and chat away.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default PersonalChat;
