import { MemoizedMovie } from "./renderItem copy";

export function ChatDate({
  date,
  messages,
  setActivePurposal,
  selectedConvoUser,
  selectedConvo,
  currentConvoUser,
  buyerSelected,
}) {
  return (
    <>
      <h6 className="text-center py-2">
        <span className="date_bg p-2 px-3 mt-1 rounded">{date}</span>
      </h6>
      {messages?.map((item, index) => {
        return (
          <MemoizedMovie
            msg={item}
            setActivePurposal={setActivePurposal}
            selectedConvoUser={selectedConvoUser}
            selectedConvo={selectedConvo}
            currentConvoUser={currentConvoUser}
            buyerSelected={buyerSelected}
          />
        );
      })}
    </>
  );
}
