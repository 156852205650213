import React from "react";
import { useCryptoJobFormikContext } from "../../hooks/useFormikCryptoJobContext";
import { useGetCryptoTagsQuery } from "../../services/cryptoTag";

const DisplayFormTags = () => {
  const { values, setFieldValue, displayError } = useCryptoJobFormikContext();

  const { isLoading, data } = useGetCryptoTagsQuery();

  const handleSkillClick = (skill) => {
    // Function to handle when a skill tag is clicked
    const tags = [...values.tags];
    if (!tags.includes(skill)) {
      setFieldValue("tags", [...tags, skill]);
    } else {
      const newTags = tags.filter((t) => t !== skill);
      setFieldValue("tags", newTags);
    }
  };

  const getClassNameForTag = (id) => {
    if (values?.tags?.includes(id)) {
      return `span_skill_tag_border me-2`;
    } else return `span_skill_tag me-2`;
  };
  return (
    <div className="mb-3">
      <label className="create_crypto_jobs_labels mb-1">Tags</label>
      <div className="skills_tag_overflow">
        {data?.tags?.map((skill, index) => (
          <span
            key={index?._id}
            className={getClassNameForTag(skill?._id)}
            onClick={() => handleSkillClick(skill?._id)}
          >
            {skill?.name}
          </span>
        ))}
      </div>
      {displayError("tags")}
    </div>
  );
};

export default DisplayFormTags;
