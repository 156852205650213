import React, { useEffect, useState } from "react";

import {
  AudioOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Table,
  Typography,
  message,
} from "antd";

import toast from "react-hot-toast";
import useQueryParams from "../hooks/useQueryParams";
import {
  useCreateTechnologyMutation,
  useDeleteTechnologyMutation,
  useGetTechnologiesQuery,
  useGetTechnologyByIdQuery,
  useUpdateTechnologyMutation,
} from "../services/technology";
import { hasPermission } from "../utils/permissions";
// import UserModal from '../components/modal/modal';

// import card from "../assets/images/info-card-1.jpg";

// Search
const { Search } = Input;
const suffix = (
  <AudioOutlined
    style={{
      fontSize: 16,
      color: "#1890ff",
    }}
  />
);
const onSearch = (value) => console.log(value);
// Search End

const { Text, Link } = Typography;

const { TextArea } = Input;

const { confirm } = Modal;

function Technology() {
  // const [loading, setLoading] = useState(false);
  // User Show Modal
  const [ShowUser, setShowUser] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedId, setSelectedId] = useState(null);
  const isTechnologyCreate = hasPermission("technologies:create");
  const isTechnologyEdit = hasPermission("technologies:edit");
  const isTechnologyDelete = hasPermission("technologies:delete");

  const [
    deleteTechnology,
    {
      isLoading: isDeleteLoading,
      isError: isDeleteError,
      error: deleteError,
      isSuccess: deleteSuccess,
    },
  ] = useDeleteTechnologyMutation();
  const [
    updateTechnology,
    {
      isLoading: isUpdateLoading,
      data: updateData,
      isError: isUpdateError,
      error: updateError,
      isSuccess: updateSuccess,
    },
  ] = useUpdateTechnologyMutation();
  const [
    createTechnology,
    {
      isLoading: isCreateLoading,
      data: createData,
      isError: isCreateError,
      error: createError,
      isSuccess: createSuccess,
    },
  ] = useCreateTechnologyMutation();
  const {
    isLoading: isGetLoading,
    data: getData,
    isSuccess: isGetSuccess,
  } = useGetTechnologyByIdQuery({ id: selectedId }, { skip: !selectedId });

  const {
    isLoading,
    data: technologies,
    isError,
    error,
  } = useGetTechnologiesQuery(
    {
      page,
      perPage: 10,
      search: searchQuery,
    },
    {
      selectFromResult: ({ data, isLoading, error }) => ({
        // clear data if error exists
        data: error ? [] : data,
        isLoading,
        error,
      }),
    },
  );
  useQueryParams({
    params: {
      page: [page > 1 ? page : null, setPage, 1],
      search: [searchQuery, setSearchQuery, "", true, setSearch], // Update setSearch as well
    },
    watchList: [searchQuery, page],
  });

  useEffect(() => {
    if (deleteSuccess) {
      toast.success("Technology is deleted successfully");
    }
  }, [deleteSuccess]);
  useEffect(() => {
    if (updateSuccess) {
      toast.success("Technology is updated successfully");
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (createSuccess) {
      toast.success("Technology is created successfully");
    }
  }, [createSuccess]);

  useEffect(() => {
    if (isUpdateError) {
      if (
        updateError?.data?.message &&
        updateError?.data?.message.includes(
          "E11000 duplicate key error collection",
        )
      ) {
        toast.error("Technology already exists");
        setIsModalOpen(false);
      } else {
        toast.error(updateError?.data?.message || "Something went wrong");
        setIsModalOpen(false);
      }
    }
  }, [isUpdateError]);

  useEffect(() => {
    if (isCreateError) {
      toast.error(createError?.data?.message || "Something went wrong");
      setIsModalOpen(false);
    }
  }, [isCreateError]);

  useEffect(() => {
    if (isDeleteError) {
      toast.error(deleteError?.data?.message || "Something went wrong");
      setIsModalOpen(false);
    }
  }, [isDeleteError]);

  const showDeleteConfirm = (id) => {
    confirm({
      title: "Are you sure delete this Technology?",
      icon: <ExclamationCircleFilled />,
      // content: 'Some descriptions',
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      okButtonProps: { loading: isDeleteLoading },
      onOk() {
        deleteTechnology({ id });
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const OpenUser = (id) => {
    setSelectedId(id);
    setShowUser(true);
  };
  const UserOk = () => {
    setSelectedId(null);
    setShowUser(false);
  };
  const UserCancel = () => {
    setSelectedId(null);
    setShowUser(false);
  };
  // Create User Modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setSelectedId(null);
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setSelectedId(null);
    setIsModalOpen(false);
  };
  const onSearch = (value) => {
    setPage(1);

    setSearchQuery(value); // Update the 'search' state with the entered value
    console.log(value);
    // Here you can use the 'value' to perform any search-related operations.
    // For example, you can use the 'value' to filter the table data based on the search query.
  };

  // Set up pagination options
  const paginationOptions = {
    total: technologies?.totalCount || 0, // Set the total number of items
    current: page, // Set the current page number
    pageSize: 10, // Set the number of items per page
    // pageSizeOptions: ['5', '10', '20'], // Optional: Available page size options
    showSizeChanger: false, // Optional: Show the page size changer
    showQuickJumper: false, // Optional: Show quick jump to page input
    onChange: (page, pageSize) => {
      setPage(page);
      console.log("page => ", page);
      // You can implement your custom logic here to fetch data for the selected page
      // For example, you can use the useGetUsersQuery hook with the new page number
    },
    onShowSizeChange: (current, size) => {
      // You can implement your custom logic here to fetch data with the new page size
      // For example, you can use the useGetUsersQuery hook with the new page size
    },
  };

  // User Table Data Stat
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
  ];

  if (isTechnologyEdit || isTechnologyDelete) {
    columns.push({
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          {isTechnologyEdit && (
            <a className="primary_color" onClick={() => OpenUser(record?._id)}>
              <EditOutlined />
            </a>
          )}
          {isTechnologyDelete && (
            <a
              className="danger_color"
              onClick={() => showDeleteConfirm(record?._id)}
              type="dashed"
            >
              <DeleteOutlined />
            </a>
          )}
        </Space>
      ),
    });
  }
  // Reset Form
  const [form] = Form.useForm();
  const onReset = () => {
    form.resetFields();
  };
  console.log({ selectedId });

  const [componentDisabled, setComponentDisabled] = useState(false);
  const onFormLayoutChange = ({ disabled }) => {
    setComponentDisabled(disabled);
  };
  const uTechnology = getData?.technology;
  useEffect(() => {
    if (uTechnology) {
      // To update the value, you can use the `setFieldsValue` method
      if (selectedId)
        form.setFieldsValue({
          name: uTechnology?.name,
        });
      else
        form.setFieldsValue({
          name: "",
        });
    }
  }, [uTechnology, isGetSuccess]);
  const { Title, Text, Paragraph } = Typography;

  const onChange = (e) => console.log(`radio checked:${e.target.value}`);

  // Upload Avatar
  const uploadProps = {
    name: "file",
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  useEffect(() => {
    if (isCreateLoading || isUpdateLoading) {
      onReset();
      UserCancel();
    }
  }, [isCreateLoading, isUpdateLoading]);

  const onFinish = (values) => {
    console.log("values => ", values);
    if (selectedId) {
      updateTechnology({ body: values, id: selectedId });
    } else {
      createTechnology(values);
    }
  };
  return (
    <>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <Col xs={24} md={24} sm={24} lg={24} xl={24} className="mb-24">
            <Card bordered={false} className="criclebox mb-5">
              <Row gutter={[24, 0]}>
                <Col xs={24} md={24} sm={24} lg={24} xl={24} className="mb-24">
                  <div className="d-flex justify-content-end">
                    <div>
                      <Search
                        placeholder="Input search text"
                        allowClear
                        onSearch={onSearch}
                        enterButton
                        value={search}
                        onChange={(e) => setSearch(e.target.value)} // Update the 'search' state while typing
                      />
                    </div>
                    {isTechnologyCreate && (
                      <div className="create_user_btn_div">
                        <Button
                          type="primary"
                          className="ms-3 px-3 rounded-5"
                          onClick={() => setShowUser(true)}
                        >
                          {/* <PlusOutlined /> */}
                          Add New Technology
                        </Button>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
              <Row gutter={[24, 0]}>
                <Col xs={24} md={24} sm={24} lg={24} xl={24} className="mb-24">
                  <div className="">
                    <Table
                      loading={isLoading}
                      pagination={paginationOptions}
                      columns={columns}
                      dataSource={technologies?.technologies}
                    />
                  </div>
                </Col>
              </Row>
            </Card>
            {/* <UserModal /> */}

            {/* Create User Modal Start */}

            {/* Create User Modal End */}

            {/* User View Modal Start */}
            <div>
              <Modal
                title="Edit Technology"
                centered
                width={800}
                visible={ShowUser}
                onOk={UserOk}
                onCancel={UserCancel}
                footer={[
                  <Button key="back" onClick={UserCancel}>
                    Cancel
                  </Button>,
                  <Button
                    key="submit"
                    type="primary"
                    htmlType="submit"
                    form="techForm"
                  >
                    {selectedId ? "Update" : "Create"}
                  </Button>,
                ]}
              >
                <Form
                  id="techForm" // Add an id to the form
                  initialValues={{ name: "" }}
                  layout="vertical"
                  onValuesChange={onFormLayoutChange}
                  disabled={componentDisabled}
                  onFinish={onFinish} // Now the onFinish function is attached to the form
                  form={form}
                >
                  <Row gutter={[24, 0]}>
                    <Col
                      xs={24}
                      md={24}
                      sm={24}
                      lg={12}
                      xl={12}
                      className="mobile-24"
                    >
                      <Form.Item
                        label="Technology Name"
                        name="name"
                        rules={[
                          {
                            required: true,
                            message: "Enter Technology Name",
                          },
                        ]}
                      >
                        <Input placeholder="Technology Name" />
                      </Form.Item>
                    </Col>

                    <Col
                      xs={24}
                      md={24}
                      sm={24}
                      lg={24}
                      xl={24}
                      className="mobile-24"
                    ></Col>
                  </Row>
                </Form>
              </Modal>
            </div>
            {/* User View Modal End */}
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Technology;
