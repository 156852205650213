import { Button, Col, Form, Input, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import RoleForm from "../../pages/Permissions";
import {
  useCreateAdminMutation,
  useGetAdminByUsernameQuery,
  useUpdateAdminMutation,
} from "../../services/auth";

const AdminForm = ({
  refetch,
  editUsername,
  handleCancel,
  setEditUsername,
  shouldReset,
  setShouldReset,
}) => {
  const [isResourceError, setIsResourceError] = useState(false);
  const [isActionError, setIsActionError] = useState(false);
  const [isRoleError, setIsRoleError] = useState(false);
  const [resource, setResource] = useState("");
  const [role, setRole] = useState("");
  const [actionsN, setActionsN] = useState([]);

  const [formData, setFormData] = useState({
    role: "",
    permissions: [],
  });

  const {
    isLoading: isAdminLoading,
    data: adminData,
    isSuccess: isAdminSuccess,
    // refetch
  } = useGetAdminByUsernameQuery(
    { username: editUsername },
    {
      skip: !editUsername, // Set skip to true when editUsername is falsy (null, undefined, etc.)
      // Add this option to refetch the data every 3 seconds
      // pollingInterval: 3000
      refetchOnFocus: true,
    },
  );

  const [
    createAdmin,
    {
      isLoading: isCreateAdminLoading,
      isSuccess: isCreateAdminSuccess,
      isError: isCreateAdminError,
      error: createAdminError,
      data: createAdminData,
    },
  ] = useCreateAdminMutation();
  const [
    updateAdmin,
    {
      isLoading: isUpdateAdminLoading,
      isError: isUpdateAdminError,
      error: updateAdminError,
      isSuccess: isUpdateAdminSuccess,
      data: updateAdminData,
    },
  ] = useUpdateAdminMutation();
  const [permissionError, setPermissionError] = useState(false);

  useEffect(() => {
    if (isCreateAdminError) {
      toast.error(createAdminError?.data?.message || "Something went wrong");
    }
  }, [isCreateAdminError]);

  useEffect(() => {
    if (updateAdminError) {
      toast.error(createAdminError?.data?.message || "Something went wrong");
    }
  }, [updateAdminError]);

  const initialValues = {
    userStatus: "", // Set the default value here
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    username: "",
  };

  const [componentDisabled, setComponentDisabled] = useState(false);
  const onFormLayoutChange = ({ disabled }) => {
    setComponentDisabled(disabled);
  };

  // Reset Form
  const [form] = Form.useForm();
  const onReset = () => {
    form.resetFields();
    setFormData({
      role: "",
      permissions: [],
    });
    setResource("");
    setRole("");
    setActionsN([]);
  };

  useEffect(() => {
    setShouldReset(false);
    return () => {
      form.resetFields();
      setFormData({
        role: "",
        permissions: [],
      });
      setResource("");
      setRole("");
      setActionsN([]);
    };
  }, [shouldReset]);

  const handleValidation = () => {
    const roleCond = role === "";
    const resourceCond = resource === "";
    const actionCond = actionsN.length === 0;

    if (roleCond || resourceCond || actionCond) {
      setIsRoleError(roleCond);
      setIsResourceError(resourceCond);
      setIsActionError(actionCond);
      return true;
    }
    return false;
  };

  const handlePermissionAdded = () => {
    let roleE, resourceE, actionE;

    if (formData.role === "") {
      roleE = true;
    }

    if (formData.permissions.length > 0) {
      resourceE = formData.permissions[0]?.resource === "";
      if (formData.permissions[0]?.actions.length === 0) {
        actionE = true;
      } else {
        actionE = false;
      }
    } else {
      actionE = true;
      resourceE = true;
    }

    if (roleE || resourceE || actionE) {
      setPermissionError(true);
      return true;
    }
    return false;
  };

  const onFinish = (values) => {
    const isPermissionAddedError = handlePermissionAdded();
    if (isPermissionAddedError) {
      handleValidation();
      return;
    }

    let data = {
      ...values,
      ...formData,
    };
    if (editUsername) {
      const keys = ["firstName", "lastName"];

      const updatedObject = { ...data };
      keys.map((key) => delete updatedObject[key]);
      updateAdmin(updatedObject);
    } else {
      createAdmin(data);
    }

    // Perform your form submission logic here (e.g., API calls, etc.)
  };

  const onFinishFailed = (errorInfo) => {
    // Handle validation errors or form submission failure
    const isPermissionAddedError = handlePermissionAdded();
    if (isPermissionAddedError) {
      handleValidation();
      return;
    }
  };

  useEffect(() => {
    if (editUsername) {
      // refetch();
    } else {
      form.setFieldsValue(initialValues);
      setRole("");
      setFormData({
        role: "",
        permissions: [],
      });
    }
  }, [editUsername]);

  const admin = adminData?.admin;
  useEffect(() => {
    if (admin) {
      setFormData((pre) => {
        return { ...pre, role: admin?.role, permissions: admin?.permissions };
      });
      setRole(admin?.role);

      const mockData = {
        active: admin?.active,
        email: admin?.email,
        firstName: admin?.firstName,
        lastName: admin?.lastName,
        username: admin?.username,
      };

      form.setFieldsValue(mockData);
    } else {
      // refetch()
    }
  }, [admin, refetch]);

  useEffect(() => {
    if (isCreateAdminSuccess || isUpdateAdminSuccess) {
      isUpdateAdminSuccess && toast.success("Admin is updated successfully");
      isCreateAdminSuccess && toast.success("Admin is created successfully");
      setEditUsername(null);
      handleCancel();
      onReset();
    }
  }, [isCreateAdminSuccess, isUpdateAdminSuccess]);
  const handleCopy = (event) => {
    event.preventDefault(); // Prevent copying
    toast.error("Copying is not allowed!");
  };

  return (
    <Form
      initialValues={initialValues}
      layout="vertical"
      onValuesChange={onFormLayoutChange}
      disabled={componentDisabled}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed} // Handle form submission failure
      form={form}
    >
      <Row gutter={[24, 0]}>
        {!Boolean(editUsername) && (
          <Col xs={24} md={24} sm={24} lg={12} xl={12} className="mobile-24">
            <Form.Item
              label="First Name"
              name="firstName"
              rules={[
                {
                  required: true,
                  message: "Please input your First Name!",
                },
              ]}
            >
              <Input placeholder="Allen" />
            </Form.Item>
          </Col>
        )}
        {!Boolean(editUsername) && (
          <Col xs={24} md={24} sm={24} lg={12} xl={12} className="mobile-24">
            <Form.Item
              label="Last Name"
              name="lastName"
              rules={[
                {
                  required: true,
                  message: "Please input your Last Name!",
                },
              ]}
            >
              <Input placeholder="Walker" />
            </Form.Item>
          </Col>
        )}

        <Col xs={24} md={24} sm={24} lg={12} xl={12} className="mobile-24">
          <Form.Item
            label="Username"
            name="username"
            rules={[
              {
                required: true,
                message: "Please input your Username!",
              },
            ]}
          >
            <Input
              placeholder="allen45walker"
              disabled={Boolean(editUsername)}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={24} sm={24} lg={12} xl={12} className="mobile-24">
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your Email!",
              },
              {
                pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
                message: "Please enter a valid email address!",
              },
            ]}
          >
            <Input
              placeholder="dummy@email.com"
              autoComplete="off"
              disabled={Boolean(editUsername)}
            />
          </Form.Item>
        </Col>

        {!editUsername && (
          <Col xs={24} md={24} sm={24} lg={12} xl={12} className="mobile-24">
            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your Password!",
                },
                // {
                //   min: 8,
                //   message: "",
                // },
                {
                  pattern:
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                  message:
                    "Password must contain at least one uppercase letter, one lowercase letter, one number and at least 8 characters.",
                },
              ]}
            >
              <Input.Password
                style={{ userSelect: "none" }}
                onCopy={handleCopy}
                onContextMenu={(e) => e.preventDefault()} // Disable right-click context menu
              />
            </Form.Item>
          </Col>
        )}

        <Col xs={24} md={24} sm={24} lg={12} xl={12} className="mobile-24">
          <Form.Item
            label="User Status"
            name="active"
            className="mb-0"
            rules={[
              {
                required: true,
                message: "Please select User Status!",
              },
            ]}
          >
            <Select>
              <Select.Option value="">Select User Status</Select.Option>
              <Select.Option value={true}>Active</Select.Option>
              <Select.Option value={false}>Inactive</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={24} sm={24} lg={24} xl={24} className="mobile-24">
          <RoleForm
            formData={formData}
            setFormData={setFormData}
            isResourceError={isResourceError}
            setIsResourceError={setIsResourceError}
            isActionError={isActionError}
            setIsActionError={setIsActionError}
            isRoleError={isRoleError}
            setIsRoleError={setIsRoleError}
            resource={resource}
            role={role}
            actionsN={actionsN}
            setActionsN={setActionsN}
            setRole={setRole}
            setResource={setResource}
            permissionError={permissionError}
            setPermissionError={setPermissionError}
          />
        </Col>

        <Col xs={24} md={24} sm={24} lg={24} xl={24} className="mobile-24">
          <Form.Item>
            <Button
              loading={isCreateAdminLoading || isUpdateAdminLoading}
              disabled={isAdminLoading || isUpdateAdminLoading}
              type="primary"
              className="me-3"
              htmlType="submit"
            >
              Submit
            </Button>
            <Button type="secondary" htmlType="button" onClick={onReset}>
              Reset
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default AdminForm;
