import { Card, Col, Row, Spin, Typography } from "antd";

import { dashboardCards } from "../config/constants/dashboard";
import { count } from "../data/dashboardDats";
import { useGetDashboardQuery } from "../services/dashboard";

const { Title } = Typography;

function Home() {
  const { isLoading, data } = useGetDashboardQuery();

  if (isLoading) {
    // If data is still loading, show the custom loading spinner.
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Spin size="large" tip="Loading dashboard data..." />
          <div style={{ marginTop: "20px", fontSize: "0.95em" }}>
            Please wait, we're preparing your dashboard!
          </div>
        </div>
      </div>
    );
  }

  const getPriceValue = (title) => {
    if (Object.keys(data || {})?.length) {
      if (title === dashboardCards.totalSellers) return data?.totalSellers;
      if (title === dashboardCards.totalBuyers) return data?.totalBuyers;
      if (title === dashboardCards.totalGigs) return data?.gigCount;
      if (title === dashboardCards.totalJobs) return data?.jobCount;
      if (title === dashboardCards.totalInvestedInIdeaToken)
        return `$${data?.totalInvestedInIdeaToken}`;
      if (title === dashboardCards.totalBuyersInvested)
        return `$${data?.totalInvestedByAllBuyers}`;
      if (title === dashboardCards.totalInvestedInOtherTokens)
        return `$${data?.totalInvestedInOtherTokens}`;
      if (title === dashboardCards.totalCancelOrders)
        return data?.totalCanceledOrders;
      if (title === dashboardCards.totalActiveOrders)
        return data?.totalActiveOrders;
      if (title === dashboardCards.totalOrders) return data?.totalOrders;
    } else return false;
  };

  return (
    <>
      <div className="layout-content">
        <Row className="rowgap-vbox" gutter={[24, 0]}>
          {count.map((c, index) => (
            <Col
              key={index}
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              xxl={6}
              className="mb-24"
            >
              <Card bordered={false} className="criclebox ">
                <div className="number">
                  <Row align="middle" gutter={[24, 0]}>
                    <Col xs={18} className="pe-0">
                      <span>{c.cardTitle}</span>
                      <Title level={3}>
                        {getPriceValue(c.cardTitle) || 0}{" "}
                        <small className={c.bnb}>{c.persent}</small>
                      </Title>
                    </Col>
                    <Col xs={6} className="ps-0">
                      <div className="icon-box d-flex justify-content-center align-items-center">
                        {c.icon}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
}

export default Home;
