import React, { useState } from "react";
import { FiClock } from "react-icons/fi";
import { TbReload } from "react-icons/tb";
const CustomInboxOfferProposal = ({
  user,
  proposal,
  setActivePurposal,
  selectedConvo,
}) => {
  // const { socket } = useSocket();
  const [rejected, setRejected] = useState(false);
  const handleProposalDecline = (status, declined) => {
    // updateCustomInboxOfferProposalApi(proposal._id, {
    //   status,
    // }).then(() => {
    //   toast.success("Successfully cancelled the proposal");
    //   setRejected(true);
    //   const messageContent = {
    //     offerUpdate: true,
    //     senderId: user,
    //     receiverId: declined
    //       ? selectedConvo?.members.find((m) => m._id !== user)._id
    //       : proposal?.buyerId,
    //     conversationId: selectedConvo?._id,
    //     message: "",
    //   };
    //   socket?.emit("private message", { msg: messageContent });
    // });
  };

  return (
    <>
      <div className="my-3">
        <div className="accepted_top_navtab mb-0 packages_tabs_bg package_manner_top py-1">
          <div className="d-flex justify-content-between px-4 align-items-center py-2 ">
            <div>
              <p className="mb-0 custom_offer_title_new">
                {" "}
                {proposal?.gigId?.gigtitle}{" "}
                {/* {console.log(proposal, "jsjbhbvs")} */}
              </p>
            </div>
            <div>
              <p className="price_in_custom_offer_new mb-0">
                ${proposal?.pricep}
              </p>
            </div>
          </div>
        </div>
        <div className="package_tab_bg">
          <div className="package_manner_bottom px-4">
            <div className="" style={{ wordWrap: "break-word" }}>
              <p className="mb-0 pt-4 pb-4 lets_get_text_custom_offer">
                {proposal?.offerDescription
                  ?.replace(/<[^>]*>/g, "")
                  .replace(/\s+/g, " ")}
              </p>
            </div>
            <div className="margin_bottom_seller_profile"></div>
            <div className="row justify-content-around align-items-center pt-3 pb-4 px-2 ">
              <div className="col">
                <div className="d-flex align-items-center h-100">
                  <p className="mb-0 color_custom_offer_new_offer px-1">
                    Your Offer includes
                  </p>
                </div>
              </div>
              <div className="px-1 col">
                <p className="mb-0 color_custom_offer_new_offer ">
                  {proposal?.revisions}
                </p>
                <p className="color_custom_offer_new_offer mb-0">
                  <TbReload className="me-1" />
                  Revisions
                </p>
              </div>
              <div className="px-1 col">
                <p className="mb-0 color_custom_offer_new_offer ">
                  {proposal?.deliveryDays}
                </p>
                <p className="color_custom_offer_new_offer mb-0">
                  <FiClock className="me-1" />
                  Delivery Days
                </p>
              </div>
            </div>
            <div className="margin_bottom_seller_profile"></div>
            {!rejected && proposal?.status == "sent" && (
              <div className=" py-3 text-center ">
                <button
                  className="offer_sent_button btn px-3 py-3 mx-1 "
                  data-bs-toggle="modal"
                  data-bs-target="#connect_metamask"
                  // onClick={() => setActivePurposal(proposal)}
                  disabled={true}
                >
                  {user == proposal?.userid ? "Offer Sent" : "Accept"}
                </button>

                <button
                  className="offer_sent_button_withdraw px-3 py-3 mx-1"
                  disabled={true}
                  // onClick={() =>
                  //   user == proposal?.userid
                  //     ? handleProposalDecline("withdrawn")
                  //     : handleProposalDecline("rejected", true)
                  // }
                >
                  {!rejected
                    ? user == proposal?.userid
                      ? "Withdraw"
                      : "Decline"
                    : `Proposal was ${
                        proposal?.status == "sent"
                          ? "withdrawn"
                          : proposal?.status
                      }`}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomInboxOfferProposal;
