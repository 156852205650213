import { Col, Row, Tag, Typography } from "antd";
import React from "react";
import { useGetAdminByUsernameQuery } from "../../services/auth";
// User Table Data End

const { Title, Text, Paragraph } = Typography;
const ViewAdmin = ({ editUsername }) => {
  const {
    isLoading: isAdminLoading,
    data: adminData,
    isSuccess: isAdminSuccess,
    refetch,
  } = useGetAdminByUsernameQuery(
    { username: editUsername },
    {
      skip: !editUsername, // Set skip to true when editUsername is falsy (null, undefined, etc.)
    },
  );

  const admin = adminData?.admin;
  const fullName = `${admin?.firstName} ${admin?.lastName}` || "John Doe";
  const username = admin?.username || "dummyuser.name";
  const status = admin?.active ? "Active" : "InActive";
  const email = admin?.email || "Dummy123@mail.com";
  return (
    <div>
      <Row gutter={[24, 0]}>
        <Col xs={24} md={24} sm={24} lg={24} xl={24} className="mb-24">
          <div className="d-flex justify-content-start">
            <div className="mr-30">
              <img src={""} alt="" className="modal_user_img" />
            </div>
            <div>
              <Title className="mb-0">{fullName}</Title>
              <Paragraph
                className="user_name_text"
                copyable={{ text: username }}
              >
                @<span>{username}</span>
              </Paragraph>
            </div>
          </div>
        </Col>
      </Row>
      <Row gutter={[24, 0]} className="pl-5 ml-5">
        <Col xs={24} md={12} sm={24} lg={12} xl={12}>
          <div>
            <p className="user_view_modal_lable_heading">Email</p>
            <p className="user_view_modal_data">{email}</p>
          </div>
        </Col>
        <Col>
          <div>
            <p className="user_view_modal_lable_heading">Status</p>
            <Tag color="green-inverse">{status}</Tag>
            {/* <p className='user_view_modal_data'>
                            Active
                        </p> */}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ViewAdmin;
