import DisplayOffer from "./DisplayOffer";

const DisplayCustomOffer = ({ msg, setActivePurposal, selectedConvo }) => {
  if (msg.type !== "offer") {
    return null;
  } else {
    return (
      <>
        <DisplayOffer
          msg={msg}
          setActivePurposal={setActivePurposal}
          selectedConvo={selectedConvo}
        />
      </>
    );
  }
};

export default DisplayCustomOffer;
