import React, { useState } from "react";
import { FaFile } from "react-icons/fa";

const FileIcon = () => {
  const [isHovered, setIsHovered] = useState(false);

  const iconContainerStyle = {
    position: "relative",
    display: "inline-block",
  };

  const iconStyle = {
    fontSize: "50px",
    color: "rgb(177 176 176)", // a slightly lighter shade of grey
    cursor: "pointer", // to indicate that it's clickable
  };

  const textStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    display: isHovered ? "block" : "none",
  };

  // const handleMouseEnter = () => {
  //     setIsHovered(true);
  // };

  // const handleMouseLeave = () => {
  //     setIsHovered(false);
  // };

  return (
    <div
      // onMouseEnter={handleMouseEnter}
      // onMouseLeave={handleMouseLeave}
      style={iconContainerStyle}
      className="text-center"
    >
      <FaFile style={iconStyle} />
      {isHovered && <div style={textStyle}>File Text</div>}
    </div>
  );
};

export default FileIcon;
