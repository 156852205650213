export const auth = {
  signup: "/auth/signup",
  login: "/auth/login",
  getUser: "/auth/me",
  createAdmin: `/auth/create`,
  updateAdmin: `/auth/update`,
  updateCredentials: `/auth/credentials`,
  deleteAdmin: (username) => `/auth/delete/${username}`,
  getAdminByUsername: (username) => `/auth/${username}`,
  getAllAdmins: (query) => `/auth?${query}`,
};
