import React, { createContext, useContext } from "react";
import useFormikCryptoJob from "./useFormikCryptoJob";

const FormikContext = createContext();

export const useCryptoJobFormikContext = () => {
  const context = useContext(FormikContext);
  if (!context) {
    throw new Error(
      "useCryptoJobFormik must be used within a CryptoJobFormikProvider",
    );
  }
  return context;
};

export const CryptoJobFormikProvider = ({ children }) => {
  const formik = useFormikCryptoJob();

  return (
    <FormikContext.Provider value={formik}>{children}</FormikContext.Provider>
  );
};
