import ApiBase from "../app/ApiBase";
import config from "../config/development";
import { objectToQueryString } from "../utils/url";

export const conversationApi = ApiBase.enhanceEndpoints({
  addTagTypes: ["Conversation"],
}).injectEndpoints({
  endpoints: (builder) => ({
    getConversations: builder.query({
      query: (data) =>
        config.api.endpoints.getConversations(data.id, data.query?.isAdmin),
      transformResponse: (response) => {
        // handle successful response and return data to store
        console.log("response: ", response);
        return response?.data.sort((a, b) => {
          const aDate = a.lastMessage ? a.lastMessage.updatedAt : a.updatedAt;
          const bDate = b.lastMessage ? b.lastMessage.updatedAt : b.updatedAt;

          // Sorting in descending order, adjust the comparison logic if needed
          return new Date(bDate) - new Date(aDate);
        });
      },
    }),
    getMessages: builder.query({
      query: (id) => config.api.endpoints.getMessages(id),
      transformResponse: (response) => {
        // handle successful response and return data to store
        console.log("response: ", response);
        return response?.data?.filter((msg) => msg.type != "offerUpdate");
      },
    }),
    getConversationsByMembers: builder.query({
      query: (query) =>
        config.api.endpoints.getConversationsByMembers(
          objectToQueryString(query),
        ),
      transformResponse: (response) => {
        // handle successful response and return data to store
        console.log("response: ", response);
        return response?.data;
      },
    }),
    createConversation: builder.mutation({
      query: (body) => ({
        url: config.api.endpoints.createConversation,
        method: "POST",
        body,
      }),
      transformResponse: (response) => {
        // handle successful response and return data to store

        console.log("response: ", response);
        return response;
      },
    }),
  }),
});

// Generate hooks for each endpoint
export const {
  useGetConversationsQuery,
  useGetConversationsByMembersQuery,
  useGetMessagesQuery,
  useCreateConversationMutation,
} = conversationApi;
