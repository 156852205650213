import { StrikethroughOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi"; // Import FiChevronUp
import { Link } from "react-router-dom";

const CryptoDropdown = ({
  name,
  isCollapsed,
  toggleCollapse,
  closeSidebar,
}) => {
  const [isDisputeDropdownOpen, setIsDisputeDropdownOpen] = useState(false); // Renamed state variable

  const handleDropdownClick = () => {
    setIsDisputeDropdownOpen(!isDisputeDropdownOpen);
    toggleCollapse(name);
  };
  const handleLinkClick = () => {
    if (closeSidebar) {
      closeSidebar();
    }
  };
  return (
    <div>
      <ul className="">
        <li>
          <a
            className="nav-link px-3 sidebar_link"
            href="#"
            data-bs-toggle="collapse"
            data-bs-target={`#collapseExample_${name}`}
            aria-expanded={isCollapsed ? "false" : "true"}
            aria-controls={`collapseExample_${name}`}
            onClick={handleDropdownClick}
          >
            <span className="icon">
              <StrikethroughOutlined className="icon_bg" />
            </span>
            <span className="ms-2" style={{ fontSize: "0.85em" }}>
              Crypto Jobs
            </span>
            <span className="ps-1">
              {isCollapsed ? (
                <FiChevronDown className="ms-auto" />
              ) : (
                <FiChevronUp className="ms-auto" />
              )}
            </span>
            <span id="right-icon" className="ms-auto">
              <i className="bi bi-chevron-down"></i>
            </span>
          </a>
          <div
            className={` ${isCollapsed ? "collapse" : "show"}`}
            id={`collapseExample_${name}`}
          >
            <ul className="navbar-nav ps-4 ms-1">
              <li className="p-2" onClick={handleLinkClick}>
                <Link
                  to="/createcryptojobs"
                  className="nav-link px-3"
                  style={{ fontSize: "0.85em" }}
                >
                  Create Job
                </Link>
              </li>
              <li className="p-2" onClick={handleLinkClick}>
                <Link
                  to="/listedcryptojobs"
                  className="nav-link px-3"
                  style={{ fontSize: "0.85em" }}
                >
                  Listed Jobs
                </Link>
              </li>
              <li className="p-2" onClick={handleLinkClick}>
                <Link
                  to="/cryptotags"
                  className="nav-link px-3"
                  style={{ fontSize: "0.85em" }}
                >
                  Crypto Tags
                </Link>
              </li>
              <li className="p-2" onClick={handleLinkClick}>
                <Link
                  to="/addcrytocountry"
                  className="nav-link px-3"
                  style={{ fontSize: "0.85em" }}
                >
                  Add Country
                </Link>
              </li>
              <li className="p-2" onClick={handleLinkClick}>
                <Link
                  to="/crypto-add-country"
                  className="nav-link px-3"
                  style={{ fontSize: "0.85em" }}
                >
                  Add City
                </Link>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default CryptoDropdown;
