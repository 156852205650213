export const category = {
  getCategories: (query) => `/category?${query}`,
  getSubcategories: (query) => `/category/subcategories?${query}`,
  getCategoriesWithSubcategories: (query) =>
    `/category/categoriesWithSubcategories?${query}`,
  createCategory: `/category/create`,
  updateCategory: (category) => `/category/update/${category}`,
  deleteCategory: (category) => `/category/delete/${category}`,
  getCategoryById: (category) => `/category/${category}`,
};
