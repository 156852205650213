import * as Yup from "yup";

export const signinValidation = Yup.object({
    email: Yup.string()
        .email("Enter valid email e.g abc@xyz.com")
        .strict(true)
        .required("Please enter your email"),

    password: Yup.string()
        .required("Please enter your password"),


});