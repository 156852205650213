import React, { useEffect, useState } from "react";

import { VerticalAlignTopOutlined } from "@ant-design/icons";
import {
  Avatar,
  Card,
  Col,
  Descriptions,
  List,
  Modal,
  Row,
  Select,
  Tag,
  message,
} from "antd";

import BgProfile from "../assets/images/bg-signup.jpg";
import convesionImg from "../assets/images/gig_card_img.png";
// import convesionImg2 from "../assets/images/face-4.jpg";
// import convesionImg3 from "../assets/images/face-5.jpeg";
// import convesionImg4 from "../assets/images/face-6.jpeg";
// import convesionImg5 from "../assets/images/face-2.jpg";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import dummyvideoimage from "../assets/images/Minimalist Tutorial Event YouTube Thumbnail .jpg";
import project1 from "../assets/images/home-decor-1.jpeg";
import project2 from "../assets/images/home-decor-2.jpeg";
import project3 from "../assets/images/home-decor-3.jpeg";
import {
  useChangeUserRoleBuyerMutation,
  useChangeUserStatusMutation,
  useGetUserProfileQuery,
} from "../services/user";
import { hasPermission } from "../utils/permissions";

import PropTypes from "prop-types";
// Select
const UserStatusDpdn = (value) => {
  console.log(`selected ${value}`);
};
const TopRatedDpdn = (value) => {
  console.log(`selected ${value}`);
};
// End Select

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const options = [
  {
    value: "completed",
    label: "Active",
  },
  {
    value: "suspended",
    label: "Suspend",
  },
  {
    value: "under-reviewed",
    label: "Under Review",
  },
  {
    value: "not-verified",
    label: "Not Verify",
  },
  {
    value: "verified",
    label: "Verify",
  },
  {
    value: "not-completed",
    label: "Inactive",
  },
];

// Tab Pane
// const items = new Array(3).fill(null).map((_, i) => {
//   const id = String(i + 1);
//   return {
//     label: `Tab Title ${id}`,
//     key: id,
//     children: (
//       <>
//         <p>Content of Tab Pane {id}</p>
//         <p>Content of Tab Pane {id}</p>
//         <p>Content of Tab Pane {id}</p>
//       </>
//     ),
//   };
// });
// End Tab Pane

// Buyer Seller Tabs

const truncateText = (html, maxLength) => {
  const div = document.createElement("div");
  div.innerHTML = html;
  let text = div.textContent || div.innerText || "";
  if (text.length > maxLength) {
    // Truncate the text and add ellipsis
    text = text.substr(0, maxLength).trim() + "...";
  }
  return text;
};

export const renderTruncatedDescription = (html, maxLength) => {
  const truncatedText = truncateText(html, maxLength);

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: truncatedText,
      }}
    />
  );
};

function Profile() {
  const [value, setValue] = React.useState(0);
  const [selectedValue, setSelectedValue] = useState("active");
  const [selectedOptionKey, setSelectedOptionKey] = useState("Active");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { username } = useParams();
  const isUserEdit = hasPermission("users:edit");
  const {
    isLoading,
    data: profile,
    isError,
    error,
    refetch,
  } = useGetUserProfileQuery({
    username,
  });
  console.log("profile data=>", profile);

  const [
    changeUserStatus,
    {
      isSuccess: isStatusSuccess,
      data,
      isError: isStatusError,
      error: statusError,
      isLoading: isStatusLoading,
    },
  ] = useChangeUserStatusMutation();
  const [userRoleBuyer, { isSuccess: switchSuccess }] =
    useChangeUserRoleBuyerMutation();

  const handleOk = () => {
    changeUserStatus({
      status: selectedValue,
      username,
    });
    // setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (isStatusSuccess) {
      setIsModalOpen(false);
      refetch();
    }
  }, [isStatusSuccess]);

  useEffect(() => {
    if (switchSuccess) {
      refetch();
    }
  }, [switchSuccess]);

  useEffect(() => {
    if (isStatusError) {
      setIsModalOpen(false);
    }
  }, [isStatusError]);
  const handleSelectChange = (selectedOption) => {
    console.log("selectedOptions => ", selectedOption);
    setSelectedOptionKey(
      options.find((o) => o.value === selectedOption)?.label,
    );
    setSelectedValue(selectedOption);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const [imageURL, setImageURL] = useState(false);
  const [, setLoading] = useState(false);

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(false);
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (imageUrl) => {
        setLoading(false);
        setImageURL(false);
      });
    }
  };

  const pencil = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z"
        className="fill-gray-7"
      ></path>
      <path
        d="M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z"
        className="fill-gray-7"
      ></path>
    </svg>,
  ];

  const uploadButton = (
    <div className="ant-upload-text font-semibold text-dark">
      {<VerticalAlignTopOutlined style={{ width: 20, color: "#000" }} />}
      <div>Upload New Project</div>
    </div>
  );

  const latest_jobs = [
    {
      title: "Convert my PSD to HTML",
      avatar: convesionImg,
      description: "Lorem ipsum dolor sit amet,",
    },
    {
      title: "Build Website In JS",
      avatar: convesionImg,
      description: "Lorem ipsum dolor sit amet,",
    },
    {
      title: "Remove My Images Background",
      avatar: convesionImg,
      description: "About files I can…",
    },
    {
      title: "Animated Add",
      avatar: convesionImg,
      description: "Have a great afternoon…",
    },
    {
      title: "Create Crypto",
      avatar: convesionImg,
      description: "Hi! I need more information…",
    },
  ];

  const latest_orders = [
    {
      title: "Convert my PSD to HTML",
      avatar: convesionImg,
      description: "Lorem ipsum dolor sit amet,",
    },
    {
      title: "Build Website In JS",
      avatar: convesionImg,
      description: "Lorem ipsum dolor sit amet,",
    },
    {
      title: "Remove My Images Background",
      avatar: convesionImg,
      description: "About files I can…",
    },
    {
      title: "Animated Add",
      avatar: convesionImg,
      description: "Have a great afternoon…",
    },
    {
      title: "Create Crypto",
      avatar: convesionImg,
      description: "Hi! I need more information…",
    },
  ];

  const project = [
    {
      img: project1,
      titlesub: "Project #1",
      title: "Modern",
      disciption:
        "As Uber works through a huge amount of internal management turmoil.",
    },
    {
      img: project2,
      titlesub: "Project #2",
      title: "Scandinavian",
      disciption:
        "Music is something that every person has his or her own specific opinion about.",
    },
    {
      img: project3,
      titlesub: "Project #3",
      title: "Minimalist",
      disciption:
        "Different people have different taste, and various types of music, Zimbali Resort",
    },
  ];

  useEffect(() => {
    if (profile?.customer_type === "buyer") {
      setValue(0);
    } else if (profile?.customer_type === "seller") {
      setValue(1);
    }
  }, [profile]);
  const isSeller = profile?.customer_type;
  console.log("user role=>", isSeller);

  // Buyer/Seller Tabs Toggle
  const handleChangeSwitch = (event, newValue) => {
    const id = profile?.user;
    userRoleBuyer({ userid: id, role: newValue === 0 ? "buyer" : "seller" });
    console.log("Buyer=>", profile.customer_type);
    setValue(newValue);
  };

  const [showFullDescription, setShowFullDescription] = useState(false);

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const description = profile?.description || "<p>No description added</p>";
  const truncatedDescription = showFullDescription
    ? description
    : `${description.slice(0, 320)}`;

  // Portfolio Modal

  const [isPortfolioModalOpen, setPortfolioIsModalOpen] = useState(false);
  const [selectedPortfolio, setSelectedPortfolio] = useState(null);
  const showPortfolioModal = (portfolio) => {
    setSelectedPortfolio(portfolio);
    setPortfolioIsModalOpen(true);
  };
  const handleOkPortfolioModal = () => {
    setPortfolioIsModalOpen(false);
  };
  const handleCancelPortfolioModal = () => {
    setPortfolioIsModalOpen(false);
  };

  // Profile Data Modal

  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const showProfileModal = () => {
    setIsProfileModalOpen(true);
  };
  const handleProfileOk = () => {
    setIsProfileModalOpen(false);
  };
  const handleProfileCancel = () => {
    setIsProfileModalOpen(false);
  };

  return (
    <>
      <div
        className="profile-nav-bg"
        style={{ backgroundImage: "url(" + BgProfile + ")" }}
      ></div>

      <Card
        className="card-profile-head"
        bodyStyle={{ display: "none" }}
        title={
          <Row justify="space-between" align="middle" gutter={[24, 0]}>
            <Col span={24} md={12} className="col-info">
              <Avatar.Group>
                <Avatar
                  size={65}
                  shape="square"
                  src={
                    profile?.personalpicture ||
                    "https://work-as-pro.s3.us-east-2.amazonaws.com/staticPictures/image_2024_02_05T06_10_50_024Z.png"
                  }
                />

                <div className="avatar-info">
                  <h4 className="font-semibold m-0">
                    {username || "@sarah_jacob95"}
                  </h4>
                  <p className="mb-0" style={{ fontSize: "0.8em" }}>
                    {" "}
                    {profile?.slug}
                  </p>
                  {/* <p>@sarah_jacob95</p> */}
                </div>
              </Avatar.Group>
            </Col>
            <Col
              span={24}
              md={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <div
                className="d-flex justify-content-end align-items-center"
                id="navabr_buyer_seller_btn"
              >
                <div className="me-3">
                  <Tabs
                    value={value}
                    onChange={(event, newValue) => {
                      handleChangeSwitch(event, newValue);
                    }}
                    aria-label="basic tabs example"
                    className="tooggle_Seller set_customer_dashboard_toggle_border"
                    indicatorColor="primary"
                    selectionFollowsFocus
                  >
                    <Tab
                      label="Buyer"
                      id="pills-home-tab"
                      className="dashboard_toggle_button_Seller"
                      {...a11yProps(0)}
                    ></Tab>
                    <Tab
                      label="Seller"
                      className="dashboard_toggle_button_Seller"
                      {...a11yProps(1)}
                    />
                  </Tabs>
                </div>
                {isUserEdit && (
                  <div>
                    <Select
                      defaultValue="active"
                      value={profile?.status}
                      style={{
                        width: 250,
                      }}
                      onChange={handleSelectChange}
                      options={options}
                    />
                    <Modal
                      confirmLoading={isStatusLoading}
                      open={isModalOpen}
                      onOk={handleOk}
                      onCancel={handleCancel}
                    >
                      <p>
                        Are you sure you want to {selectedOptionKey} this
                        profile?
                      </p>
                    </Modal>{" "}
                  </div>
                )}
              </div>
            </Col>
          </Row>
        }
      ></Card>

      <Row gutter={[24, 0]}>
        <Col xs={24} md={24} sm={24} lg={12} xl={12} className="mb-24">
          <Card
            bordered={false}
            title={<h6 className="font-semibold m-0">Profile Information</h6>}
            className="header-solid h-full card-profile-information"
            bodyStyle={{
              paddingTop: 0,
              paddingBottom: 16,
              wordBreak: "break-word",
            }}
          >
            {isSeller === "seller" && (
              <>
                <p
                  className="mb-0"
                  dangerouslySetInnerHTML={{
                    __html: truncatedDescription,
                  }}
                />
                {description.length > 220 && (
                  <span
                    className="see_more_less_btn"
                    onClick={toggleDescription}
                  >
                    {showFullDescription ? "See less" : "... See more"}
                  </span>
                )}
              </>
            )}
            {isSeller === "seller" && <hr className="my-25" />}
            <Descriptions title="Personal Info">
              <Descriptions.Item label="Username" span={3}>
                {profile?.username || "Not found"}
              </Descriptions.Item>
              <Descriptions.Item label="Email" span={3}>
                {profile?.email || "Not found"}
              </Descriptions.Item>
              {profile?.country ? (
                <Descriptions.Item label="Location" span={3}>
                  {profile?.country ? profile?.country : "No Country Found"}
                </Descriptions.Item>
              ) : (
                ""
              )}
              {isSeller === "buyer" && (
                <>
                  {profile?.totalSpendings ? (
                    <Descriptions.Item label="Total Spent" span={3}>
                      {profile?.totalSpendings
                        ? profile?.totalSpendings
                        : "No Country Found"}
                    </Descriptions.Item>
                  ) : (
                    ""
                  )}
                </>
              )}
            </Descriptions>
            {isSeller === "seller" && (
              <div className="d-flex justify-content-end">
                <button
                  className="view_profile_details_btn"
                  onClick={showProfileModal}
                >
                  View Details
                </button>
              </div>
            )}
            <Modal
              title="Personal Information"
              open={isProfileModalOpen}
              onOk={handleProfileOk}
              onCancel={handleProfileCancel}
            >
              <div>
                <Avatar
                  size={75}
                  shape="square"
                  className="rounded-2"
                  src={
                    profile?.personalpicture ||
                    "https://work-as-pro.s3.us-east-2.amazonaws.com/staticPictures/image_2024_02_05T06_10_50_024Z.png"
                  }
                />
              </div>
              <div className="row pt-3">
                <div className="col-6">
                  <div>
                    <p className="profile_info_username_p mb-0">Username:</p>
                    <p className="">{profile?.username}</p>
                  </div>
                  <div>
                    <p className="profile_info_username_p mb-0">Location:</p>
                    <p className="">{profile?.country}</p>
                  </div>
                  <div>
                    <p className="profile_info_username_p mb-2">Education:</p>

                    {profile?.education?.map((edu) => {
                      return (
                        <>
                          <p className="mb-0">
                            <span className="highlightText">
                              {edu?.title}- {edu?.majorSubject}
                            </span>
                          </p>
                          <p className="mb-1">
                            {edu?.institue}, {edu?.country}, {edu?.passingYear}
                          </p>
                        </>
                      );
                    })}
                  </div>
                  <div>
                    <p className="profile_info_username_p mb-2 mt-3">
                      Language:
                    </p>
                    {profile?.languages?.map((lang) => {
                      return (
                        <>
                          <p className="mb-1">
                            <span className="highlightText">
                              {lang?.language}
                            </span>{" "}
                            - {lang?.proficiency}
                          </p>
                        </>
                      );
                    })}
                  </div>
                  <div>
                    <p className="profile_info_username_p mb-2 mt-3">
                      Linked Accounts:
                    </p>
                    {profile?.linkedAccounts?.isGoogleConnected === true && (
                      <p className="mb-1">Google Connected</p>
                    )}

                    {profile?.linkedAccounts?.isFacebookConnected === true && (
                      <p className="mb-1">Facebook Connected</p>
                    )}

                    {profile?.linkedAccounts?.isMetaMaskConnected === true && (
                      <p className="mb-1">Meta Mask Connected</p>
                    )}
                    {profile?.linkedAccounts?.isTwitterConnected === true && (
                      <p className="mb-1">Twitter Connected</p>
                    )}
                  </div>
                </div>
                <div className="col-6">
                  <div>
                    <p className="profile_info_username_p mb-0">Email:</p>
                    <p className="">{profile?.email}</p>
                  </div>
                  <div>
                    <p className="profile_info_username_p mb-0">
                      Total Earning:
                    </p>
                    <p className="">{profile?.totalEarnings}</p>
                  </div>
                  <div>
                    <p className="profile_info_username_p mb-2">Experience:</p>

                    {profile?.experience?.map((exp) => {
                      const endDateFormatted = new Date(
                        exp?.endDate,
                      ).toLocaleDateString("en-US", {
                        month: "short",
                        year: "numeric",
                      });
                      const startDateFormatted = new Date(
                        exp?.startDate,
                      ).toLocaleDateString("en-US", {
                        month: "short",
                        year: "numeric",
                      });

                      return (
                        <>
                          <p className="mb-0">
                            <span className="highlightText">
                              {exp?.occuption}
                            </span>{" "}
                          </p>
                          <p className="mb-0">
                            {exp?.companyName}, {exp?.city}, {exp?.location}
                          </p>
                          <p className="mb-1">
                            {startDateFormatted} to{" "}
                            {exp?.endDate === "Present"
                              ? "Present"
                              : endDateFormatted}
                          </p>
                        </>
                      );
                    })}
                  </div>
                  <div>
                    <p className="profile_info_username_p mb-2 mt-3">
                      Certifications:
                    </p>

                    {profile?.certification?.map((exp) => {
                      return (
                        <>
                          <p className="mb-0">
                            <span className="highlightText">
                              {exp?.certificateAward}
                            </span>{" "}
                          </p>
                          <p className="mb-1">
                            {exp?.certificatefrom}, {exp?.passingYear}{" "}
                          </p>
                        </>
                      );
                    })}
                  </div>
                </div>
                <div className="col-12">
                  <p className="profile_info_username_p mb-2 mt-3">Skills:</p>

                  {profile?.skillset?.map((skill) => {
                    return (
                      <>
                        <Tag color="#F47458" className="my-1">
                          {skill}
                        </Tag>
                      </>
                    );
                  })}
                </div>
              </div>
            </Modal>
          </Card>
        </Col>
        <Col xs={24} md={24} sm={24} lg={12} xl={12} className="mb-24">
          <Card
            bordered={false}
            title={<h6 className="font-semibold m-0">Latest Orders</h6>}
            className="header-solid h-full"
            bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
          >
            <List
              itemLayout="horizontal"
              dataSource={profile?.latestOrders}
              split={false}
              className="conversations-list"
              renderItem={(item) => (
                <List.Item
                  actions={[
                    <Link to={`/order_detail/${item?._id}`} type="link">
                      View
                    </Link>,
                  ]}
                >
                  <List.Item.Meta
                    // avatar={
                    //   <Avatar shape="square" size={48} src={item.avatar} />
                    // }
                    title={
                      item?.gigId && Object.keys(item.gigId).length > 0
                        ? item.gigId.gigtitle
                        : item?.jobId &&
                          item.jobId.job_Id &&
                          item.jobId.job_Id.jobtitle
                    }
                    description={renderTruncatedDescription(
                      item?.gigId && Object.keys(item.gigId).length > 0
                        ? item.gigId.gigdescription
                        : item?.jobId &&
                            item.jobId.job_Id &&
                            item.jobId.job_Id.jobdescription,
                      100,
                    )}
                  />
                </List.Item>
              )}
            />
          </Card>
        </Col>
      </Row>
      <Card
        bordered={false}
        className="header-solid mb-24"
        // title={
        //   <>
        //     <h6 className="font-semibold">Projects</h6>
        //     <p>Architects design houses</p>
        //   </>
        // }
      >
        <Row gutter={[24, 0]}>
          <Col xs={24} md={24} sm={24} lg={12} xl={12} className="mb-24">
            <Card
              bordered={false}
              title={
                <h6 className="font-semibold m-0">
                  {isSeller === "seller" ? "All Gigs" : "All Jobs"}
                </h6>
              }
              className="header-solid h-full"
              bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
            >
              <div
                style={{
                  maxHeight: "300px",
                  overflowY: "scroll",
                  overflowX: "hidden",
                  wordBreak: "break-all",
                }}
                className="custom_scroll_bar"
              >
                <List
                  itemLayout="horizontal"
                  dataSource={
                    isSeller === "seller"
                      ? profile?.allLatestGigs
                      : profile?.allLatestJobs
                  }
                  split={false}
                  className="conversations-list"
                  renderItem={(item) => (
                    <List.Item
                      actions={[
                        <Link
                          to={
                            isSeller === "seller"
                              ? `/gig_detail/${item?._id}`
                              : `/job_detail/${item?._id}`
                          }
                          type="link"
                        >
                          View
                        </Link>,
                      ]}
                    >
                      <List.Item.Meta
                        avatar={
                          isSeller === "seller" ? (
                            <Avatar
                              shape="square"
                              size={48}
                              src={item.gigImages[0] || dummyvideoimage}
                            />
                          ) : (
                            <></>
                          )
                        }
                        title={
                          isSeller === "seller" ? item.gigtitle : item?.jobtitle
                        }
                        description={renderTruncatedDescription(
                          isSeller === "seller"
                            ? item?.gigdescription
                            : item?.jobdescription,
                          100,
                        )}
                      />
                    </List.Item>
                  )}
                />
              </div>
            </Card>
          </Col>
          <Col xs={24} md={24} sm={24} lg={12} xl={12} className="mb-24">
            <Card
              bordered={false}
              title={<h6 className="font-semibold m-0">All Orders</h6>}
              className="header-solid h-full"
              bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
            >
              <div
                style={{
                  maxHeight: "300px",
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
                className="custom_scroll_bar"
              >
                <List
                  itemLayout="horizontal"
                  dataSource={profile?.allOrders}
                  split={false}
                  className="conversations-list"
                  renderItem={(item) => (
                    <List.Item
                      style={{ marginRight: "15px" }}
                      actions={[
                        <Link to={`/order_detail/${item?._id}`} type="link">
                          View
                        </Link>,
                      ]}
                    >
                      <List.Item.Meta
                        title={
                          item?.gigId && Object.keys(item.gigId).length > 0
                            ? item.gigId.gigtitle
                            : item?.jobId &&
                              item.jobId.job_Id &&
                              item.jobId.job_Id.jobtitle
                        }
                        description={renderTruncatedDescription(
                          item?.gigId && Object.keys(item.gigId).length > 0
                            ? item.gigId.gigdescription
                            : item?.jobId &&
                                item.jobId.job_Id &&
                                item.jobId.job_Id.jobdescription,
                          100,
                        )}
                      />
                    </List.Item>
                  )}
                />
              </div>
            </Card>
          </Col>
        </Row>
        <Row>
          {isSeller === "seller" ? (
            <Col xs={24} md={24} sm={24} lg={12} xl={12}>
              <Card
                bordered={false}
                title={<h6 className="font-semibold m-0">Portfolio</h6>}
                className="header-solid h-full"
                bodyStyle={{
                  paddingTop: 0,
                  paddingBottom: 16,
                  wordBreak: "break-all",
                }}
              >
                {console.log(profile, ">>>Checking Profile Data")}
                <List
                  itemLayout="horizontal"
                  dataSource={profile?.userPortfollio}
                  split={false}
                  className="conversations-list"
                  renderItem={(item) => (
                    <List.Item
                      actions={[
                        <Link
                          to="#"
                          onClick={() => showPortfolioModal(item)}
                          type="button"
                        >
                          View
                        </Link>,
                      ]}
                    >
                      <List.Item.Meta
                        avatar={
                          <Avatar
                            shape="square"
                            size={48}
                            src={item?.projectMedia[0]}
                          />
                        }
                        title={item?.title}
                        description={renderTruncatedDescription(
                          item?.description,
                          30,
                        )}
                      />
                    </List.Item>
                  )}
                />
                <Modal
                  title={
                    <div className="d-flex align-items-center">
                      <div>
                        <img
                          src={profile?.personalpicture}
                          className="portfolio_personal_picture"
                        />
                      </div>
                      <div>
                        <h6 className="portfolio_username ms-3">
                          {profile?.username}
                        </h6>
                      </div>
                    </div>
                  }
                  open={isPortfolioModalOpen}
                  onOk={handleOkPortfolioModal}
                  onCancel={handleCancelPortfolioModal}
                >
                  <div className="profile_portfolio_height">
                    <div className="">
                      <h4 className="profile_portfolio_username">
                        {selectedPortfolio?.title}
                      </h4>
                      <p className="profile_portfolio_p">
                        {selectedPortfolio?.description}
                      </p>
                    </div>
                    <div>
                      <img
                        src={selectedPortfolio?.projectMedia[0]}
                        className="profile_portfolio_img"
                      />
                    </div>
                  </div>
                </Modal>
              </Card>
            </Col>
          ) : (
            ""
          )}
        </Row>
      </Card>
    </>
  );
}

export default Profile;

// Usage inside your component:
// <ProfileContent profile={profile} />
