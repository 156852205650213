import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    loggedIn: false,
    user: null,
  },
  reducers: {
    loginSuccess: (state, action) => {
      state.loggedIn = true;
      state.user = action.payload;
    },
    updateUser: (state, action) => {
      state.loggedIn = true;
      let token = state.user?.token;
      let user = { ...action.payload, token };
      state.user = user;
      // state.user.token = token;
    },
    logout: (state) => {
      state.loggedIn = false;
      state.user = null;
    },
  },
});

export const { loginSuccess, logout, updateUser } = userSlice.actions;

// Selectors
export const selectUser = (state) => state.user.user;
export const selectLoggedIn = (state) => state.user.loggedIn;

export default userSlice.reducer;
