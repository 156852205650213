import { Card, Col, Input, Modal, Row, Table, Typography } from "antd";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useQueryParams from "../../hooks/useQueryParams";
import { useCreateConversationMutation } from "../../services/conversation";
import { disputeApi } from "../../services/dispute";
import { selectUser } from "../../slices/user";
import { disconnectWallet, selectWallet } from "../../slices/wallet";
import { hasPermission } from "../../utils/permissions";
import {
  getContractOwner,
  handleConnect,
  resolveDispute,
} from "../../utils/web3";
import Overlay from "../Overlay/Overlay";

const BuyerChat = ({
  offersData,
  refetch,
  tx = false,
  pageLength,
  setPageLength,
  search,
  setSearch,
  page,
  setPage,
  setSearchQuery,
  searchQuery,
}) => {
  const isManageDisputeCreate = hasPermission("manageDisputes:create");
  const isUserView = hasPermission("users:view");
  const user = useSelector(selectUser);
  const [createConversation, { isLoading }] = useCreateConversationMutation();
  const [selectedDisputeId, setSelectedDisputeId] = useState(null);
  const [loader, setLoader] = useState(false);
  const [loaderText, setLoaderText] = useState("Pending");
  const [currentDisputeDescription, setCurrentDisputeDescription] =
    useState("");

  // console.log("offersData", offersData);
  const wallet = useSelector(selectWallet);
  const dispatch = useDispatch();

  const history = useHistory();
  const [loading, setLoading] = useState(false);

  useQueryParams({
    params: {
      page: [page > 1 ? page : null, setPage, 1],
      search: [searchQuery, setSearchQuery, "", true, setSearch], // Update setSearch as well
    },
    watchList: [searchQuery, page],
  });

  const paginationOptions = {
    total: offersData?.total || 0, // Set the total number of items
    current: page, // Set the current page number
    pageSize: pageLength, // Set the number of items per page
    pageSizeOptions: ["5", "10", "20"], // Optional: Available page size options
    // showSizeChanger: true, // Optional: Show the page size changer
    showQuickJumper: false, // Optional: Show quick jump to page input
    onChange: (page, pageSize) => {
      setPage(page);
    },
    onShowSizeChange: (current, size) => {
      setPageLength(size);
    },
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = (disputeDescription) => {
    setCurrentDisputeDescription(disputeDescription);
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const columns = [
    {
      title: "Order Id",
      dataIndex: "uniqueOrderId",
    },
    {
      title: "Buyer",
      dataIndex: ["user", "username"],
      render: (username, record) =>
        isUserView ? (
          <Link
            to={`/profile/${username}`}
            style={{ fontWeight: "600", color: "black" }}
          >
            {username}
          </Link>
        ) : (
          username
        ),
    },
    {
      title: "Seller",
      dataIndex: ["sellerId", "username"],
      render: (username, record) =>
        isUserView ? (
          <Link
            to={`/profile/${username}`}
            style={{ fontWeight: "600", color: "black" }}
          >
            {username}
          </Link>
        ) : (
          username
        ),
    },
    {
      title: "Amount",
      dataIndex: ["cryptoToken", "baseAmount"],
      render: (amount) => `$${amount}`,
    },
    {
      title: "Token",
      dataIndex: ["cryptoToken", "name"],
      render: (_, record) =>
        `${record.cryptoToken.name} (${record.cryptoToken.network?.slice(
          0,
          3,
        )})`,
    },
    {
      title: "Gig",
      dataIndex: "gigId",
      render: (gig, record) =>
        isUserView ? (
          <Link
            style={{ fontWeight: "600", color: "black" }}
            to={`/gig_detail/${
              gig ? gig?._id : record?.jobId?.purposal_Id?.gigId?._id
            }`}
          >
            {gig ? gig?.gigtitle : record?.jobId?.purposal_Id?.gigId?.gigtitle}
          </Link>
        ) : gig ? (
          gig.gigtitle
        ) : (
          record.jobId.purposal_Id.gigId.gigtitle
        ),
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Dispute Proof",
      dataIndex: "disputeProof",
      render: (proof, record) => (
        <div className="d-flex justify-content-between">
          <a
            href={proof}
            target="_blank"
            rel="noreferrer"
            style={{ color: "black", fontWeight: "500" }}
          >
            {proof?.split("/")?.at(-1)}
          </a>
          {record?.disputeDescription === "" ? (
            ""
          ) : (
            <button
              className="select_status ms-4"
              onClick={() => showModal(record?.disputeDescription)}
            >
              Description
            </button>
          )}
        </div>
      ),
      hidden: tx,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => {
        return (
          <div>
            {isManageDisputeCreate && (
              <>
                {" "}
                <button
                  id="resolveButton"
                  className="select_status"
                  onClick={() => initiateResolveDispute(record, false)}
                >
                  Accept
                </button>
                <button
                  id="resolveButton"
                  className="select_status"
                  onClick={() => initiateResolveDispute(record, true)}
                  style={{ marginLeft: "10px" }}
                >
                  Refund
                </button>
              </>
            )}
            <button
              disabled={isLoading}
              onClick={async () => {
                setSelectedDisputeId(record?._id);
                setLoading(true);
                try {
                  await createConversation({
                    senderId: user?._id,
                    receiverId: record?.sellerId?._id,
                  });
                  await createConversation({
                    senderId: user?._id,
                    receiverId: record?.user?._id,
                  });
                  setLoading(false);
                } catch (error) {
                  setLoading(false);
                  console.log(
                    "error creating conversation with user => ",
                    error,
                  );
                  toast.error(
                    "something went wrong while preparing conversation!",
                  );
                  return;
                }

                history.push(
                  `/disputes/chat?member1=${record?.sellerId?._id}&member2=${record?.user?._id}`,
                );
              }}
              className="select_status"
              style={{ marginLeft: "10px" }}
            >
              {loading && selectedDisputeId === record?._id
                ? "Preparing..."
                : "View Chat"}
            </button>
          </div>
        );
      },
      hidden: !refetch,
    },
    {
      title: "Tx Hash",
      dataIndex: "txHash",
      render: (tx, record) => {
        const url =
          (record.cryptoToken.network == "ETH"
            ? process.env.REACT_APP_ETH_EXPLORER_URL
            : process.env.REACT_APP_POLYGON_EXPLORER_URL) + tx.at(-1);

        const hash = tx.at(-1).slice(0, 5) + "..." + tx.at(-1).slice(-4);

        return (
          <a href={url} target="_blank" rel="noreferrer">
            {hash}
          </a>
        );
      },
      hidden: !tx,
    },
  ].filter((item) => !item.hidden);

  const { Title } = Typography;
  const { Search } = Input;

  const onSearch = (value) => {
    setPage(1);

    setSearchQuery(value);
  };

  const initiateResolveDispute = async (record, refund) => {
    const network = record.cryptoToken.network;
    setLoader(true);

    if (!wallet.isConnected) {
      await handleConnect(network, dispatch).finally(() => {
        setLoader(false);
        return;
      });
    } else {
      if (network != wallet.selectedNetwork) {
        toast.error("Invalid network selected in Metamask");
        dispatch(disconnectWallet());

        setTimeout(() => {
          setLoader(false);
          history.go(0);
        }, 1000);
      }

      // Check if it is owner account
      const ownerAddress = String(await getContractOwner(network));
      if (ownerAddress?.toLowerCase() != wallet.walletAddress) {
        toast.error("Invalid account connected, connect Owner account");
        dispatch(disconnectWallet());
        setLoader(false);
        return;
        setTimeout(() => {
          // history.go(0);
        }, 1000);
      }

      resolveDispute(network, record.orderId, refund, setLoaderText)
        .then(() => {
          toast.success("Successfully resolved the dispute");

          // Refetching the Pending Disputes
          refetch();

          // Invalidating ResolvedDisputes tag to trigger refetch of Completed Disputes
          dispatch(disputeApi.util.invalidateTags(["ResolvedDisputes"]));
        })
        .catch(() => {
          toast.error("Error resolving the dispute");
        })
        .finally(() => {
          setLoader(false);
          setTimeout(() => {
            // history.go(0);
          }, 1000);
        });
    }
  };
  {
  }

  return (
    <>
      {loader && (
        <Overlay>
          Transaction
          <br />
          {loaderText}...
        </Overlay>
      )}
      <Modal
        title="Dispute Description"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>
          {currentDisputeDescription === ""
            ? "Description in Nothing"
            : currentDisputeDescription}
        </p>
      </Modal>
      <div>
        <Card bordered={false} className="criclebox mb-5">
          <Row gutter={[24, 0]}>
            <Col xs={24} md={24} sm={24} lg={12} xl={18} className="mb-24">
              <div className="project-ant">
                <div>
                  <Title className="mb-5" level={8}></Title>
                </div>
              </div>
            </Col>
            <Col xs={24} md={12} sm={12} lg={12} xl={6} className="mb-24">
              <div className="mt-2">
                <Search
                  placeholder="Input search text"
                  allowClear
                  onSearch={onSearch}
                  enterButton
                  value={search}
                  onChange={(e) => setSearch(e.target.value)} // Update the 'search' state while typing
                />
              </div>
            </Col>
          </Row>
          <Row gutter={[24, 0]}>
            <Col xs={24} md={24} sm={24} lg={24} xl={24} className="mb-24">
              <div className="">
                <Table
                  className="table"
                  columns={columns}
                  dataSource={offersData?.data}
                  pagination={paginationOptions} // Add the pagination options to the Table component
                />
              </div>
            </Col>
          </Row>
        </Card>
      </div>
    </>
  );
};

export default BuyerChat;
