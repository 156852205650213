import { MailFilled, MailOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useReadNotification } from "../../hooks/useUnreadNotification";

export const ReadUnreadNotification = ({ item }) => {
  const [isUnread, setIsUnread] = useState(true);
  const { makeReadUnreadNotification, isReadUnreadNotification } =
    useReadNotification();

  useEffect(() => {
    if (item) {
      setIsUnread(item?.isUnread);
    }
  }, [JSON.stringify(item)]);

  const makeReadUnreadNotificationFunc = (e) => {
    e.stopPropagation();
    makeReadUnreadNotification({ item, isUnread: !isUnread });
    setIsUnread(!isUnread);
  };

  return (
    <div className="col-1">
      {isUnread ? (
        <MailFilled
          onClick={makeReadUnreadNotificationFunc}
          disabled={isReadUnreadNotification}
        />
      ) : (
        <MailOutlined
          onClick={makeReadUnreadNotificationFunc}
          disabled={isReadUnreadNotification}
        />
      )}
    </div>
  );
};
