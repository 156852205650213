import React from "react";
import "./Overlay.css";
function Overlay(props) {
  return (
    <>
      <div className="over_lay"></div>
      <div className="overlay">
        <div className="spinner-border spinner" role="status">
          {/* <span className="sr-only"><img src={spinner_inner_img}/></span> */}
        </div>
        <div className="spinner-text">{props.children}</div>
      </div>
    </>
  );
}

export default Overlay;
