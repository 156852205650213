import { useFormik } from "formik";
import toast from "react-hot-toast";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useCreateCryptoCompanyMutation } from "../services/cryptoCompany";
import {
  useCreateCryptoJobMutation,
  useUpdateCryptoJobMutation,
} from "../services/cryptoJob";
import {
  cryptoJobInitialValues,
  cryptoJobValidationSchema,
  transformCreateCompanyReqBody,
  transformCreateJobReqBody,
} from "../utils/cryptoJobFormik";

const useFormikCryptoJob = () => {
  const [createJob] = useCreateCryptoJobMutation();
  const { id } = useParams();
  const history = useHistory();

  const [createCompany] = useCreateCryptoCompanyMutation();
  const [updateJob] = useUpdateCryptoJobMutation();

  const formik = useFormik({
    initialValues: cryptoJobInitialValues,
    validationSchema: cryptoJobValidationSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      //first we need to create company
      try {
        setSubmitting(true);
        const companyData = transformCreateCompanyReqBody(values);

        let createCompanyData;

        try {
          createCompanyData = await createCompany(companyData);

          if (createCompanyData?.error) {
            let isDuplicateEmailError =
              createCompanyData?.error?.data?.message.includes("dup key") ??
              null;
            if (isDuplicateEmailError) {
              return toast.error(
                "This company email is already in use. Please use a different email address.",
              );
            } else {
              return toast.error(
                createCompanyData?.error?.data?.message ??
                  `something went wrong with ${id ? "updating" : "creating"} company`,
              );
            }
          }

          console.log("@company data => ", createCompanyData);
        } catch (error) {
          console.log("error creating or updating company => ", error);
          return toast.error(
            `something went wrong with ${id ? "updating" : "creating"} company`,
          );
        }

        //second we need to create job
        const jobData = transformCreateJobReqBody(values);
        jobData["company"] = createCompanyData?.data?._id;

        if (id) {
          await updateJob({ jobData, jobId: id });
          toast.success("Job Edited Successfully!");
          setSubmitting(false);
          resetForm();
          history.push("/listedcryptojobs");
        } else {
          await createJob(jobData);
          toast.success("Job Posted Successfully!");
          setSubmitting(false);
          resetForm();
        }
      } catch (error) {
        console.error(error);
        toast.error("Something wrong posting job");
        setSubmitting(false);
      }
    },
  });

  const displayError = (name) => {
    if (formik.errors[name] && formik.touched[name]) {
      return <div className="text-danger">{formik.errors[name]}</div>;
    } else {
      return null;
    }
  };

  return { ...formik, displayError };
};

export default useFormikCryptoJob;
