import * as Yup from "yup";

export const JOB_TYPE_ENUM = [
  "full-time",
  "part-time",
  "contract",
  "freelance",
  "temporary",
  "internship",
  "volunteer",
  "remote",
];

export const cryptoJobValidationSchema = Yup.object().shape({
  jobTitle: Yup.string().required("Job Title is required"),
  jobType: Yup.string()
    .oneOf(JOB_TYPE_ENUM, "Invalid job type")
    .required("Job Type is required"),
  tagline: Yup.string().required("Tagline is required"),
  companyName: Yup.string().required("Company Name is required"),
  country: Yup.string().required("Country is required"),
  address: Yup.string().required("City is required"),
  companyAbout: Yup.string().required("Company Description is required"),
  jobDescription: Yup.string().required("Job Description is required"),
  companyEmail: Yup.string()
    .email("Invalid email")
    .required("Company Email is required"),
  tags: Yup.array().of(Yup.string()).min(1, "At least one tag is required"),
  salaryMin: Yup.number().required("Minimum salary is required"),
  salaryMax: Yup.number().required("Maximum salary is required"),
  salaryCurrency: Yup.string().required("Salary currency is required"),
  salaryFrequency: Yup.string().required("Salary frequency is required"),
  salaryType: Yup.string().required("Salary type is required"),
  // companyWebsite: Yup.string().required("Company Website is required"),
  // companyTwitter: Yup.string().required("Company Twitter is required"),
  // companyDiscord: Yup.string().required("Company Discord is required"),
  companyLogo: Yup.array().min(1, "Company Logo is required"),
});

export const companyKeysObject = {
  companyName: "name",
  companyAbout: "about",
  companyEmail: "email",
  companyDiscord: "discord",
  companyTwitter: "twitter",
  companyWebsite: "website",
  companyLogo: "picture",
};

export const companyKeys = Object.keys(companyKeysObject);

export const jobKeysObject = {
  jobTitle: "title",
  jobType: "jobType",
  country: "country",
  address: "address",
  tagline: "tagLine",
  jobDescription: "description",
  tags: "tags",
  salaryMin: "min",
  salaryMax: "max",
  salaryCurrency: "currency",
  salaryFrequency: "frequency",
  salaryType: "type",
};

const setJobFormikValuesForEdit = (values, setFieldValue) => {
  if (!values || (values && Object.keys(values)?.length === 0)) return {};

  const salary = values?.salary;

  setFieldValue(
    getKeyByValue(jobKeysObject, jobKeysObject.jobTitle),
    values?.[jobKeysObject.jobTitle] ?? "",
  );
  setFieldValue(
    getKeyByValue(jobKeysObject, jobKeysObject.jobDescription),
    values?.[jobKeysObject.jobDescription] ?? "",
  );
  setFieldValue(
    getKeyByValue(jobKeysObject, jobKeysObject.jobType),
    values?.[jobKeysObject.jobType] ?? "",
  );
  setFieldValue(
    getKeyByValue(jobKeysObject, jobKeysObject.address),
    values?.[jobKeysObject.address]?._id ?? "",
  );
  setFieldValue(
    getKeyByValue(jobKeysObject, jobKeysObject.country),
    values?.[jobKeysObject.address]?.country ?? "",
  );
  setFieldValue(
    getKeyByValue(jobKeysObject, jobKeysObject.tagline),
    values?.[jobKeysObject.tagline] ?? "",
  );
  setFieldValue(
    getKeyByValue(jobKeysObject, jobKeysObject.tags),
    values?.[jobKeysObject.tags]?.map((t) => t?._id) ?? [],
  );
  setFieldValue(
    getKeyByValue(jobKeysObject, jobKeysObject.salaryType),
    salary?.type ?? "",
  );
  setFieldValue(
    getKeyByValue(jobKeysObject, jobKeysObject.salaryCurrency),
    salary?.currency ?? "",
  );
  setFieldValue(
    getKeyByValue(jobKeysObject, jobKeysObject.salaryFrequency),
    salary?.value?.frequency ?? "",
  );
  setFieldValue(
    getKeyByValue(jobKeysObject, jobKeysObject.salaryMin),
    salary?.value?.min ?? "",
  );
  setFieldValue(
    getKeyByValue(jobKeysObject, jobKeysObject.salaryMax),
    salary?.value?.max ?? "",
  );
};

const setCompanyFormikValuesForEdit = (values, setFieldValue) => {
  if (!values || (values && Object.keys(values)?.length === 0)) return {};
  const company = values?.company ?? {};
  const socialMedia = company?.socialMedia ?? {};

  setFieldValue(
    getKeyByValue(companyKeysObject, companyKeysObject.companyName),
    company?.[companyKeysObject.companyName] ?? "",
  );
  setFieldValue(
    getKeyByValue(companyKeysObject, companyKeysObject.companyAbout),
    company?.[companyKeysObject.companyAbout] ?? "",
  );
  setFieldValue(
    getKeyByValue(companyKeysObject, companyKeysObject.companyEmail),
    company?.[companyKeysObject.companyEmail] ?? "",
  );
  setFieldValue(
    getKeyByValue(companyKeysObject, companyKeysObject.companyWebsite),
    company?.[companyKeysObject.companyWebsite] ?? "",
  );
  setFieldValue(
    getKeyByValue(companyKeysObject, companyKeysObject.companyDiscord),
    socialMedia?.[companyKeysObject.companyDiscord] ?? "",
  );
  setFieldValue(
    getKeyByValue(companyKeysObject, companyKeysObject.companyTwitter),
    socialMedia?.[companyKeysObject.companyTwitter] ?? "",
  );

  const pictureURL = company?.[companyKeysObject.companyLogo] ?? "";

  const pictureArray = [];

  if (pictureURL) {
    pictureArray.push({ data_url: pictureURL });
  }
  setFieldValue(
    getKeyByValue(companyKeysObject, companyKeysObject.companyLogo),
    pictureArray ?? [],
  );
};

export const updateFormikValuesForEdit = (values, setFieldValue) => {
  setJobFormikValuesForEdit(values, setFieldValue);
  setCompanyFormikValuesForEdit(values, setFieldValue);
};

export const jobKeys = Object.keys(jobKeysObject);

function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

export const transformCreateJobReqBody = (values) => {
  // const jobData = transformCreateJobData(values);
  if (!values || (values && Object.keys(values)?.length === 0)) return {};

  const address =
    values?.[getKeyByValue(jobKeysObject, jobKeysObject.address)] ?? "";

  try {
    const structure = {
      [jobKeysObject.jobTitle]:
        values?.[getKeyByValue(jobKeysObject, jobKeysObject.jobTitle)] ?? "",
      [jobKeysObject.jobDescription]:
        values?.[getKeyByValue(jobKeysObject, jobKeysObject.jobDescription)] ??
        "",
      [jobKeysObject.jobType]:
        values?.[getKeyByValue(jobKeysObject, jobKeysObject.jobType)] ?? "",

      [jobKeysObject.address]:
        values?.[getKeyByValue(jobKeysObject, jobKeysObject.address)] ?? "",
      [jobKeysObject.tagline]:
        values?.[getKeyByValue(jobKeysObject, jobKeysObject.tagline)] ?? "",
      [jobKeysObject.tags]:
        values?.[getKeyByValue(jobKeysObject, jobKeysObject.tags)] ?? "",
      salary: {
        [jobKeysObject.salaryType]:
          values?.[
            getKeyByValue(jobKeysObject, jobKeysObject.salaryFrequency)
          ] ?? "",
        [jobKeysObject.salaryCurrency]:
          values?.[
            getKeyByValue(jobKeysObject, jobKeysObject.salaryCurrency)
          ] ?? "",
        value: {
          [jobKeysObject.salaryMin]:
            values?.[getKeyByValue(jobKeysObject, jobKeysObject.salaryMin)] ??
            "",
          [jobKeysObject.salaryMax]:
            values?.[getKeyByValue(jobKeysObject, jobKeysObject.salaryMax)] ??
            "",
          [jobKeysObject.salaryFrequency]:
            values?.[
              getKeyByValue(jobKeysObject, jobKeysObject.salaryFrequency)
            ] ?? "",
        },
      },
    };

    if (address) {
      structure[jobKeysObject.address] = address;
    }

    return structure;
  } catch (error) {
    console.error(error);
  }
};

export const transformCreateCompanyReqBody = (values) => {
  // const jobData = transformCreateJobData(values);
  if (!values || (values && Object.keys(values)?.length === 0)) return {};

  const formData = new FormData();
  try {
    // Append key-value pairs representing form fields
    formData.append(
      companyKeysObject.companyName,
      values?.[
        getKeyByValue(companyKeysObject, companyKeysObject.companyName)
      ] ?? "",
    );
    formData.append(
      companyKeysObject.companyAbout,
      values?.[
        getKeyByValue(companyKeysObject, companyKeysObject.companyAbout)
      ] ?? "",
    );
    formData.append(
      companyKeysObject.companyEmail,
      values?.[
        getKeyByValue(companyKeysObject, companyKeysObject.companyEmail)
      ] ?? "",
    );
    formData.append(
      companyKeysObject.companyWebsite,
      values?.[
        getKeyByValue(companyKeysObject, companyKeysObject.companyWebsite)
      ] ?? "",
    );
    formData.append(
      `socialMedia[${companyKeysObject.companyDiscord}]`,
      values?.[
        getKeyByValue(companyKeysObject, companyKeysObject.companyDiscord)
      ] ?? "",
    );
    formData.append(
      `socialMedia[${companyKeysObject.companyTwitter}]`,
      values?.[
        getKeyByValue(companyKeysObject, companyKeysObject.companyTwitter)
      ] ?? "",
    );

    const pictureArray =
      values[getKeyByValue(companyKeysObject, companyKeysObject.companyLogo)];

    if (pictureArray?.length > 0) {
      const picture = pictureArray[0]?.file
        ? pictureArray[0]?.file
        : pictureArray[0]?.data_url
          ? pictureArray[0]?.data_url
          : "";
      formData.append(companyKeysObject.companyLogo, picture);
    }

    return formData;
  } catch (error) {
    console.error(error);
    return formData;
  }
};

export const cryptoJobInitialValues = {
  jobTitle: "",
  jobType: "",
  country: "",
  address: "",
  tagline: "",
  companyName: "",
  companyAbout: "",
  jobDescription: "",
  companyEmail: "",
  tags: [],
  salaryMin: "",
  salaryMax: "",
  salaryCurrency: "",
  salaryFrequency: "",
  salaryType: "monthly",
  // question1: "",
  // question2: "",
  // question3: "",
  // HREmail: "",
  // HRFirstName: "",
  // HRLastName: "",
  // HRImage: [],
  companyWebsite: "",
  companyTwitter: "",
  companyDiscord: "",
  companyLogo: [],
};
