import { createSlice } from "@reduxjs/toolkit";

export const walletSlice = createSlice({
  name: "wallet",
  initialState: {
    isConnected: false,
    walletAddress: null,
    selectedNetwork: null,
  },
  reducers: {
    connectWallet: (state, action) => {
      state.isConnected = true;
      state.walletAddress = action.payload.address;
      state.selectedNetwork = action.payload.network;
    },
    disconnectWallet: (state) => {
      state.isConnected = false;
      state.walletAddress = null;
      state.selectedNetwork = null;
    },
  },
});

export const { connectWallet, disconnectWallet } = walletSlice.actions;

// Selectors
export const selectWallet = (state) => state.wallet;

export default walletSlice.reducer;
