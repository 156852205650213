import ApiBase from "../app/ApiBase";
import { OrderStatuses } from "../config/constants/OrderStatuses";
import config from "../config/development";

export const disputeApi = ApiBase.enhanceEndpoints({
  addTagTypes: ["Disputes", "ResolvedDisputes"],
}).injectEndpoints({
  endpoints: (builder) => ({
    getPendingDisputes: builder.query({
      query: (query) => ({
        url: `${config.api.endpoints.getDisputes}?${query}&status=${OrderStatuses.DISPUTED_BY_BUYER}&status=${OrderStatuses.DISPUTED_BY_SELLER}`,
        method: "GET",
      }),
      providesTags: ["Disputes"],
      transformResponse: (response) => {
        response.data = response.data.map((o) =>
          o.gigId ? o : { ...o, gigId: null },
        );
        return response;
      },
    }),

    getResolvedDisputes: builder.query({
      query: (query) => ({
        url: `${config.api.endpoints.getDisputes}?${query}&status=${OrderStatuses.DISPUTE_WON_BY_BUYER}&status=${OrderStatuses.DISPUTE_WON_BY_SELLER}`,
        method: "GET",
      }),
      providesTags: ["ResolvedDisputes"],
      transformResponse: (response) => {
        response.data = response.data.map((o) =>
          o.gigId ? o : { ...o, gigId: null },
        );
        return response;
      },
    }),
  }),
});

// Generate hooks for each endpoint
export const { useGetPendingDisputesQuery, useGetResolvedDisputesQuery } =
  disputeApi;
