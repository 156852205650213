import { useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";

const useQueryParams = ({ params, watchList = [] }) => {
  const location = useLocation();
  const history = useHistory();
  const isFirstRun = useRef(false);

  const updateUrl = () => {
    const newSearchParams = new URLSearchParams();
    Object.entries(params).forEach(([key, [value, _, defaultValue]]) => {
      if (value !== undefined && value !== null && value) {
        if (typeof defaultValue === "object") {
          newSearchParams.set(key, JSON.stringify(value));
        } else {
          newSearchParams.set(key, value);
        }
      }
    });

    const queryParams = new URLSearchParams(location.search);

    if (queryParams.toString() === newSearchParams.toString()) return;

    if (isFirstRun.current) {
      history.push(`?${newSearchParams.toString()}`);
    }
  };

  const updateStateFromQueryParams = () => {
    const queryParams = new URLSearchParams(location.search);

    Object.entries(params).forEach(
      ([
        key,
        [getValue, setValue, defaultValue, shouldUpdateSearch, setSearch],
      ]) => {
        const paramValue = queryParams.get(key);
        if (typeof defaultValue === "object") {
          setValue(paramValue ? JSON.parse(paramValue) : defaultValue);
        } else if (key === "page") {
          setValue(parseInt(paramValue) || defaultValue);
        } else {
          setValue(paramValue || defaultValue);
        }

        if (shouldUpdateSearch && setSearch) {
          setSearch(paramValue || defaultValue);
        }
      },
    );

    setTimeout(() => {
      isFirstRun.current = true;
    }, 200);
  };

  useEffect(() => {
    updateStateFromQueryParams();
  }, []);

  useEffect(() => {
    updateUrl();
  }, watchList);

  return null;
};

export default useQueryParams;
