import React, { useState } from "react";
import Permissionsdropdown from "./Permissionsdropdown";
const RoleForm = ({
  formData,
  setFormData,
  isResourceError,
  setIsResourceError,
  isActionError,
  setIsActionError,
  setIsRoleError,
  isRoleError,
  role,
  setRole,
  resource,
  setResource,
  actionsN,
  setActionsN,
  permissionError,
  setPermissionError,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const resources = [
    "dashboard",
    "users",
    "subadmin",
    "crypto",
    "subscribers",
    "manageCategories",
    "technologies",
    "settings",
    "blogs",
    "manageDisputes",
  ];
  const actions = ["view", "create", "edit", "delete"];

  const handleChange = (field, value) => {
    setRole(value);
    if (value !== "") {
      setIsRoleError(false);
    }
  };

  const handlePermissionChange = (index, field, value) => {
    if (field === "resource") {
      let actions = formData?.permissions?.find(
        (p) => p.resource === value,
      )?.actions;
      let shouldActions =
        actions && Object.keys(actions)?.length ? actions : [];
      setResource(value);
      setActionsN(shouldActions);
      setIsResourceError(false);
    } else if (field === "actions") {
      setActionsN((pre) => [...new Set([...value])]);
      setIsActionError(false);
    }
  };

  const handleAddPermission = () => {
    const roleCond = role === "";
    const resourceCond = resource === "";
    const actionCond = actionsN.length === 0;

    if (roleCond || resourceCond || actionCond) {
      setIsRoleError(roleCond);
      setIsResourceError(resourceCond);
      setIsActionError(actionCond);
      return;
    } else {
      setPermissionError(false);
      setIsResourceError(false);
      setIsActionError(false);
      setIsRoleError(false);

      const existingResourceIndex = formData?.permissions?.findIndex(
        (permission) => permission.resource === resource,
      );

      if (existingResourceIndex !== -1) {
        // Resource already exists, update its actions
        setFormData((prevFormData) => {
          const updatedPermissions = [...prevFormData.permissions];
          updatedPermissions[existingResourceIndex] = {
            ...updatedPermissions[existingResourceIndex], // Spread existing object properties
            actions: actionsN, // Update the 'actions' property
          };
          return {
            ...prevFormData,
            role,
            permissions: updatedPermissions,
          };
        });
      } else {
        // Resource doesn't exist, add it to the formData
        setFormData((prevFormData) => ({
          ...prevFormData,
          role,
          permissions: [
            ...prevFormData.permissions,
            {
              resource,
              actions: actionsN,
            },
          ],
        }));
      }

      setActionsN([]);
      setResource("");
    }
  };

  const handleRemovePermission = (index) => {
    setFormData((prevFormData) => {
      const newPermissions = [...prevFormData.permissions];
      newPermissions.splice(index, 1);
      return {
        ...prevFormData,
        permissions: newPermissions,
      };
    });
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-6 ps-0">
          <div className="form-group">
            <label htmlFor="role">Role:</label>
            <select
              className="form-control py-2 rounded-5"
              id="role"
              value={role}
              onChange={(e) => handleChange("role", e.target.value)}
            >
              <option value="">Select Role</option>
              <option value="admin">Admin</option>
              <option value="manager">Manager</option>
              <option value="support">Support</option>
            </select>
            {isRoleError && <p style={{ color: "red" }}>Role is required</p>}
          </div>

          <div className="mt-2 rounded">
            <div className="form-group">
              <label htmlFor={`resource`}>Resource:</label>
              <select
                className="form-control py-2 rounded-5"
                id={`resource`}
                value={resource}
                onChange={(e) =>
                  handlePermissionChange(0, "resource", e.target.value)
                }
              >
                <option value="">Select Resource</option>
                {resources.map((resource) => (
                  <option key={resource} value={resource}>
                    {resource}
                  </option>
                ))}
              </select>
              {isResourceError && (
                <p style={{ color: "red" }}>Resource is required</p>
              )}
            </div>

            {
              <div className="form-group" hidden={!resource}>
                {actions.map((action) => (
                  <div
                    key={action}
                    className="ps-2 pt-2 d-flex align-items-center"
                  >
                    <input
                      type="checkbox"
                      className="create_accent_color"
                      disabled={!resource}
                      id={action}
                      value={action}
                      checked={actionsN.includes(action)}
                      onChange={(e) => {
                        const checkedAction = e.target.value;
                        const updatedActions = actionsN.includes(checkedAction)
                          ? actionsN.filter(
                              (action) => action !== checkedAction,
                            )
                          : [...actionsN, checkedAction];

                        handlePermissionChange(0, "actions", updatedActions);
                      }}
                    />
                    <label htmlFor={action} className="ps-2">
                      {action}
                    </label>
                  </div>
                ))}
                {isActionError && (
                  <p style={{ color: "red" }}>
                    Please assign at least one action.
                  </p>
                )}
                {permissionError && (
                  <p style={{ color: "red" }}>
                    Please add at least one permission.
                  </p>
                )}
              </div>
            }
          </div>
        </div>
        <div className="col-6 ps-0">
          <div style={{ minHeight: "300px" }}>
            <div className="conatiner">
              <div className="row">
                <div className="col-12">
                  <Permissionsdropdown
                    handleRemovePermission={handleRemovePermission}
                    formData={formData}
                    setActionsN={setActionsN}
                    setResource={setResource}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-between">
        <button
          type="button"
          className="add_permission_view_btn_success mb-4"
          onClick={handleAddPermission}
        >
          Add Permission
        </button>
      </div>
    </div>
  );
};

export default RoleForm;
