import { Card, Col, Row, Spin, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useSocket } from "../hooks/socketContext";
import {
  useGetConversationsByMembersQuery,
  useGetConversationsQuery,
  useGetMessagesQuery,
} from "../services/conversation";
import { selectUser } from "../slices/user";
import { hasPermission } from "../utils/permissions";
import { ChatMessages } from "./ChatMessages";
import Disputeconversations from "./Disputeconversations";
import "./Inbox.css";
const { Title, Text, Paragraph } = Typography;

const BuyerChat = () => {
  let allMessages = [];

  const { socket } = useSocket();
  const [selectedConvo, setSelectedConvo] = useState({});
  const [selectedConvoUser, setSelectedConvoUser] = useState({});
  const [currentConvoUser, setCurrentConvoUser] = useState({});
  const messagesEndRef = React.createRef();
  const isManageDisputeCreate = hasPermission("manageDisputes:create");

  const [buyerSelected, setBuyerSelected] = useState(false);
  const user = useSelector(selectUser);
  const [message, setMessage] = useState("");
  const location = useLocation();
  // Parse the query string to get the value of the "slug" parameter
  const queryParams = new URLSearchParams(location.search);
  const member1 = queryParams.get("member1");
  const member2 = queryParams.get("member2");
  let setActivePurposal = () => {};
  const {
    data,
    refetch: refetchMessages,
    isLoading: messageLoading,
  } = useGetMessagesQuery(selectedConvo?._id);
  const {
    data: conversations1,
    refetch: refetchConversations,
    isLoading: conversationLoading,
  } = useGetConversationsQuery({
    id: user?._id,
    query: { isAdmin: true },
  });

  let conversations = conversations1?.filter((conversation) => {
    // Check if both member1Id and member2Id are in the conversation members
    const memberIds = conversation.members.map((member) => member._id);
    return memberIds.includes(member1) || memberIds.includes(member2);
  });

  useEffect(() => {
    if (selectedConvo?._id && !buyerSelected) {
      setSelectedConvoUser(
        selectedConvo?.members?.find((item) => {
          if (item._id !== user?._id) {
            return item;
          }
        }),
      );
    }
  }, [selectedConvo?._id]);
  useEffect(() => {
    if (selectedConvo?._id && !buyerSelected) {
      setCurrentConvoUser(
        selectedConvo?.members?.find((item) => {
          if (item._id === user?._id) {
            return item;
          }
        }),
      );
    }
  }, [selectedConvo?._id]);

  useEffect(() => {
    if (buyerSelected) {
      setCurrentConvoUser({});
    }
  }, [buyerSelected]);

  // console.log({ selectedConvo, selectedConvoUser, currentConvoUser })
  const { data: buyerSellerConversation } = useGetConversationsByMembersQuery({
    member1,
    member2,
  });

  const moveScrollToBottom = () => {
    const messagesEnd = messagesEndRef.current;
    messagesEnd.scrollTop = messagesEnd.scrollHeight;
  };

  useEffect(() => {
    if (messagesEndRef.current) {
      moveScrollToBottom();
    }
  }, [data]);

  console.log("data => buyerSelected => ", buyerSelected);

  const getmessage = () => {
    socket?.off("privateMsg").on("privateMsg", async ({ msg, conv }) => {
      if (!msg) return "";
      console.log("privateMessage recieved => ", { msg, conv });

      // toast.success("New message is received!")
      // toast.info("New message is received!");
      refetchMessages();
      refetchConversations();
    });
  };
  getmessage();

  const sendMessage = () => {
    console.log("message is sending...", message);

    if (message.trim().length === 0) return;
    else if (message.length > 0) {
      const messageContent = {
        senderId: user?._id,
        receiverId: selectedConvoUser._id,
        conversationId: selectedConvo._id,
        message: message,
      };
      const cntnt = { ...messageContent };
      cntnt.type = "text";
      // const msgs = [...allMessages, cntnt];
      // mutateMsg(msgs);
      socket?.emit("private message", { msg: messageContent });
      setMessage("");
      refetchMessages();
      refetchConversations();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  console.log("data => selectedConvo => ", selectedConvo);

  return (
    <div>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <Col xs={24} md={24} sm={24} lg={24} xl={24} className="mb-24">
            <Card bordered={false} className="criclebox mb-5">
              <Row gutter={[24, 0]}>
                <Col xs={24} md={24} sm={24} lg={12} xl={18} className="mb-24">
                  <div className="project-ant">
                    <div>
                      <Title className="mb-5" level={3}>
                        Buyer/Seller Chats
                      </Title>
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-xxl-3 col-xl-4 col-lg-4 col-12">
                    {conversationLoading ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "60vh",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <Spin size="large" tip="Loading Messages data..." />
                          <div
                            style={{
                              marginTop: "10px",
                              fontSize: "0.95em",
                            }}
                          >
                            Please wait, we're preparing your conversations!
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="profile_description_box heightAdjustment py-3 px-3">
                        {(
                          buyerSellerConversation &&
                          Object.keys(buyerSellerConversation)
                        )?.length ? (
                          <>
                            {[buyerSellerConversation]?.map((conversation) => {
                              return (
                                <span>
                                  <Disputeconversations
                                    setBuyerSelected={setBuyerSelected}
                                    name={"buyer/seller"}
                                    setSelectedConvo={setSelectedConvo}
                                    // SelectConvo={conversations[0]}
                                    selectedConvoStatus={conversation?.status}
                                    updateMessage={() => {}}
                                    conversation={conversation}
                                    // currentUser={user?.userDetail?.user._id}
                                    userDetail={conversation?.members?.find(
                                      (item) => {
                                        if (item._id !== user?._id) {
                                          return item;
                                        }
                                      },
                                    )}
                                    active={selectedConvo}
                                    updateConversationStatus={() => {}}
                                  />
                                </span>
                              );
                            })}
                          </>
                        ) : (
                          "No buyer/seller chat"
                        )}
                        {conversations?.map((conversation) => {
                          return (
                            <span>
                              <Disputeconversations
                                setBuyerSelected={setBuyerSelected}
                                // SelectConvo={conversations[0]}
                                setSelectedConvo={setSelectedConvo}
                                selectedConvoStatus={conversations[0]?.status}
                                updateMessage={() => {}}
                                conversation={conversation}
                                // currentUser={user?.userDetail?.user._id}
                                userDetail={conversation?.members?.find(
                                  (item) => {
                                    if (item._id !== user?._id) {
                                      return item;
                                    }
                                  },
                                )}
                                active={selectedConvo}
                                updateConversationStatus={() => {}}
                              />
                            </span>
                          );
                        })}
                      </div>
                    )}
                  </div>
                  {selectedConvo?._id ? (
                    <div className="col-xxl-9 col-xl-8 col-lg-8 col-12">
                      <div className="chat_top_header">
                        <p className="mb-0 chat_top_header_heading">
                          {buyerSelected
                            ? "Buyer/Seller"
                            : selectedConvoUser?.username}
                        </p>
                      </div>

                      <div className="messages_bg">
                        <div className="messgaes_height" ref={messagesEndRef}>
                          {messageLoading && selectedConvo?._id ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "60vh",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <Spin
                                  size="large"
                                  tip="Loading Messages data..."
                                />
                                <div
                                  style={{
                                    marginTop: "10px",
                                    fontSize: "0.95em",
                                  }}
                                >
                                  Please wait, we're preparing your Messages!
                                </div>
                              </div>
                            </div>
                          ) : (
                            <ChatMessages
                              buyerSelected={buyerSelected}
                              messages={data}
                              currentConvoUser={currentConvoUser}
                              setActivePurposal={setActivePurposal}
                              selectedConvo={selectedConvo}
                              selectedConvoUser={selectedConvoUser}
                            />
                          )}
                        </div>
                      </div>
                      {!buyerSelected &&
                        isManageDisputeCreate &&
                        Object.keys(selectedConvo).length > 0 && (
                          <div className="profile_description_box p-2">
                            <div className="d-flex justify-content-between align-items-end">
                              <div className="w-100">
                                <div className="input_msg_write">
                                  <div className="input-container">
                                    <textarea
                                      value={message}
                                      onChange={(e) =>
                                        setMessage(e.target.value)
                                      }
                                      onKeyDown={handleKeyDown}
                                      id="autoResizeInput"
                                      type="text"
                                      name="message"
                                      className="w-100 border-0 ps-4"
                                      placeholder="Message here..."
                                    ></textarea>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex align-items-center justify-content-center ms-2 pb-2">
                                <button
                                  className="msg_send"
                                  type="button"
                                  onClick={sendMessage}
                                >
                                  Send
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                    </div>
                  ) : (
                    <div className="col-xxl-9 col-xl-8 col-lg-8 col-12">
                      <div className="inbox_chat_outer_div">
                        <div className="chat_wrapper">
                          <div className="inbox_background" id="section-1">
                            <div className="d-flex justify-content-center align-items-center h-100">
                              <div>
                                <div className="text-center pb-3">
                                  <img
                                    src="https://work-as-pro.s3.us-east-2.amazonaws.com/staticPictures/wapLogo.png"
                                    className="img-fluid"
                                    style={{ width: "120px" }}
                                  />
                                </div>

                                <div>
                                  <p className="chatEmptyHeading mb-0">
                                    Pick up where you left off
                                  </p>
                                  <div className="text-center text-secondary">
                                    <p className="profile_location">
                                      Select a conversation and chat away.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default BuyerChat;
