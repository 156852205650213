import React, { memo } from "react";
import "./Inbox.css";
import TextMessage from "./subChat/TextMessage";

const RenderElements = ({
  msg,
  setActivePurposal,
  selectedConvoUser,
  selectedConvo,
  currentConvoUser,
  buyerSelected,
  shouldShowReply = true,
}) => {
  const commonMessageProps = {
    msg,
    setActivePurposal,
    selectedConvoUser,
    selectedConvo,
    currentConvoUser,
    buyerSelected,
    shouldShowReply,
  };

  return <TextMessage {...commonMessageProps} />;
};
export const MemoizedMovie = memo(RenderElements);
