import {
  Html5Outlined,
  QqOutlined,
  SendOutlined,
  SettingOutlined,
  TeamOutlined,
  UserAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import logo from "../../assets/images/nav_logo_dark.png";
import { useSitelogoGetQuery } from "../../services/setting";
import { logout } from "../../slices/user";
import { hasPermission } from "../../utils/permissions";
import Blogsdropown from "./Blogsdropown";
import CategoriesDropdowns from "./CategoriesDropdowns";
import CryptoDropdown from "./Cryptojobs";
import DisputeDropdwons from "./DisputeDropdwon";

const menuItems = [
  {
    key: "dashboard",
    icon: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* ...dashboard icon */}
      </svg>
    ),
    label: "Dashboard",
    to: "/dashboard",
    permission: "dashboard:view",
  },
  {
    key: "users",
    icon: <TeamOutlined />,
    label: "All Users",
    to: "/users",
    permission: "users:view",
  },
  {
    key: "subadmin",
    icon: <UserOutlined />,
    label: "Sub Admin",
    to: "/subadmin",
    permission: "subadmin:view",
  },
  {
    key: "topbuyers",
    icon: <QqOutlined />,
    label: "Top Buyers",
    to: "/topbuyers",
    permission: "users:view",
  },
  {
    key: "topsellers",
    icon: <UserAddOutlined />,
    label: "Top Sellers",
    to: "/topsellers",
    permission: "users:view",
  },
  {
    key: "subscribers",
    icon: <SendOutlined />,
    label: "Subscribers",
    to: "/subscribers",
    permission: "subscribers:view",
  },
  {
    key: "technologies",
    icon: <Html5Outlined />,
    label: "Technologies",
    to: "/technologies",
    permission: "technologies:view",
  },
  {
    key: "cryptotags",
    icon: <Html5Outlined />,
    label: "Crypto Tags",
    to: "/cryptotags",
    permission: "crypto:view",
  },
  {
    key: "blogs",
    permission: "blogs:view",
  },
  {
    key: "manageDisputes",
    permission: "manageDisputes:view",
  },
  {
    key: "manageCategories",
    permission: "manageCategories:view",
  },
  {
    key: "setting",
    icon: <SettingOutlined />,
    label: "General Setting",
    to: "/setting",
    permission: "settings:view",
  },

  // Add other menu items here
];

function Sidenav({ color, closeSidebar }) {
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");

  const { isLoading, data: webSiteLogo, isError } = useSitelogoGetQuery();

  // console.log("webSiteLogo=>", webSiteLogo);
  const dashboard = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M3 4C3 3.44772 3.44772 3 4 3H16C16.5523 3 17 3.44772 17 4V6C17 6.55228 16.5523 7 16 7H4C3.44772 7 3 6.55228 3 6V4Z"
        fill={color}
      ></path>
      <path
        d="M3 10C3 9.44771 3.44772 9 4 9H10C10.5523 9 11 9.44771 11 10V16C11 16.5523 10.5523 17 10 17H4C3.44772 17 3 16.5523 3 16V10Z"
        fill={color}
      ></path>
      <path
        d="M14 9C13.4477 9 13 9.44771 13 10V16C13 16.5523 13.4477 17 14 17H16C16.5523 17 17 16.5523 17 16V10C17 9.44771 16.5523 9 16 9H14Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const [dropdownStates, setDropdownStates] = useState({
    blogs: true,
    categories: true,
    disputes: true,
    cryptotags: true,
  });

  const toggleCollapse = (dropdownName) => {
    // Close all dropdowns except the one being clicked
    const updatedStates = Object.keys(dropdownStates).reduce(
      (acc, name) => ({
        ...acc,
        [name]: name === dropdownName ? !dropdownStates[name] : true,
      }),
      {},
    );

    setDropdownStates(updatedStates);
  };

  const renderMenuItem = (item) => {
    const handleLinkClick = () => {
      if (closeSidebar) {
        closeSidebar();
      }
    };
    if (item.permission && !hasPermission([item.permission])) {
      return null; // Hide menu item if user doesn't have permission
    }
    if (item.key === "manageDisputes")
      return (
        <DisputeDropdwons
          closeSidebar={closeSidebar}
          onClick={handleLinkClick}
          name="disputes"
          isCollapsed={dropdownStates.disputes}
          toggleCollapse={() => toggleCollapse("disputes")}
        />
      );
    else if (item.key === "manageCategories")
      return (
        <CategoriesDropdowns
          closeSidebar={closeSidebar}
          onClick={handleLinkClick}
          name="categories"
          isCollapsed={dropdownStates.categories}
          toggleCollapse={() => toggleCollapse("categories")}
        />
      );
    else if (item.key === "cryptotags")
      return (
        <CryptoDropdown
          closeSidebar={closeSidebar}
          onClick={handleLinkClick}
          name="cryptotags"
          isCollapsed={dropdownStates.cryptotags}
          toggleCollapse={() => toggleCollapse("cryptotags")}
        />
      );
    else if (item.key === "blogs")
      return (
        <Blogsdropown
          closeSidebar={closeSidebar}
          onClick={handleLinkClick}
          name="blogs"
          isCollapsed={dropdownStates.blogs}
          toggleCollapse={() => toggleCollapse("blogs")}
        />
      );
    else
      return (
        <Menu.Item key={item.key}>
          <NavLink to={item.to} onClick={handleLinkClick}>
            <span
              className="icon"
              style={{
                background: page === item.key ? color : "",
              }}
            >
              {item.key !== "dashboard" ? item.icon : dashboard}
            </span>
            <span className="label" style={{ fontSize: "0.85em" }}>
              {item.label}
            </span>
          </NavLink>
        </Menu.Item>
      );
  };
  const dispatch = useDispatch();

  const handleLogout = () => {
    try {
      dispatch(logout());
      window.location.href = "/signin";
    } catch (error) {
      console.error("Logout error:", error);
    }
  };
  return (
    <>
      <div className="brand text-start">
        <Link to="/dashboard">
          <img
            src={
              webSiteLogo?.dashbaord_logo ? webSiteLogo?.dashbaord_logo : logo
            }
            width={62}
            // src={logo}
            alt=""
          />
        </Link>
        {/* <span>WAP</span> */}
      </div>
      <hr />
      <Menu theme="light" mode="inline">
        {menuItems.map((item) => renderMenuItem(item))}
      </Menu>
      <button className="btn logout_btn_sidenav" onClick={handleLogout}>
        Logout
      </button>
    </>
  );
}

export default Sidenav;
