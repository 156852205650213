import { useSelector } from "react-redux";
import CustomInboxOfferProposal from "../CustomInboxOfferProposal";

const DisplayOffer = ({ msg, setActivePurposal, selectedConvo }) => {
  const user = useSelector((state) => state.user);
  if (msg.type === "offer") {
    return (
      <div className="bg-transparent w-100">
        <div className=" h-50">
          <p className="mb-3">
            <CustomInboxOfferProposal
              user={user?.userDetail?.user._id}
              proposal={msg?.customeoferrs}
              setActivePurposal={setActivePurposal}
              selectedConvo={selectedConvo}
            />
          </p>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default DisplayOffer;
