import { CodeSandboxOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi"; // Import FiChevronUp
import { Link } from "react-router-dom";

const CategoriesDropdowns = ({
  name,
  isCollapsed,
  toggleCollapse,
  closeSidebar,
}) => {
  const [isOpen, setIsOpen] = useState(false); // State to track dropdown open/closed

  const handleDropdownClick = () => {
    setIsOpen(!isOpen);
    toggleCollapse(name);
  };
  const handleLinkClick = () => {
    if (closeSidebar) {
      closeSidebar();
    }
  };
  return (
    <div>
      <ul className="">
        <li>
          <a
            className="nav-link px-3 sidebar_link"
            href="#"
            data-bs-toggle="collapse"
            data-bs-target={`#collapseExample_${name}`}
            aria-expanded={isCollapsed ? "false" : "true"}
            aria-controls={`collapseExample_${name}`}
            onClick={handleDropdownClick}
          >
            <span className="icon">
              <CodeSandboxOutlined className="icon_bg" />
            </span>
            <span className="ms-2" style={{ fontSize: "0.85em" }}>
              Manage Categories
            </span>
            <span className="ps-1">
              {isCollapsed ? (
                <FiChevronDown className="ms-auto" />
              ) : (
                <FiChevronUp className="ms-auto" />
              )}
            </span>
            <span id="right-icon" className="ms-auto">
              <i className="bi bi-chevron-down"></i>
            </span>
          </a>
          <div
            className={`collapse ${isCollapsed ? "" : "show"}`}
            id={`collapseExample_${name}`}
          >
            <ul className="navbar-nav ps-3">
              <li className="p-2" onClick={handleLinkClick}>
                <Link to="/category" className="nav-link px-3">
                  <span className="me-2">
                    <i className="bi bi-person-lines-fill"></i>
                  </span>
                  <i className="fal fa-sliders-v me-2"></i>
                  <span style={{ fontSize: "0.85em" }}>Category</span>
                </Link>
              </li>
              <li className="p-2" onClick={handleLinkClick}>
                <Link to="/subcategory" className="nav-link ps-2 pe-3">
                  <span className="me-2">
                    <i className="bi bi-cpu"></i>
                  </span>
                  {/* <i className="fal fa-project-diagram me-2"></i> */}
                  <span className="ps-3" style={{ fontSize: "0.85em" }}>
                    Subcategory
                  </span>
                </Link>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default CategoriesDropdowns;
