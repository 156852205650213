import ApiBase from "../app/ApiBase";
import config from "../config/development";
import { objectToQueryString } from "../utils/url";

export const cryptoCountryApi = ApiBase.injectEndpoints({
  endpoints: (builder) => ({
    getCountries: builder.query({
      query: (query) =>
        config.api.endpoints.getCryptoCountries(objectToQueryString(query)),
      transformResponse: (response) => {
        // handle successful response and return data to store
        console.log("response: ", response);
        return response;
      },
      keepUnusedDataFor: 1000,
      providesTags: ["cryptoJobCountry"],
    }),
    getCountriesById: builder.query({
      query: (query) => config.api.endpoints.getCryptoCountries(),
      transformResponse: (response) => {
        // handle successful response and return data to store
        console.log("response: ", response);
        return response;
      },
      keepUnusedDataFor: 1000,
    }),
    createCryptoJobCountry: builder.mutation({
      query: (data) => ({
        url: config.api.endpoints.createCryptoJobCountry,
        method: "POST",
        body: data,
      }),
      transformResponse: (response) => {
        // handle successful response and return data to store

        console.log("response: ", response);
        return response;
      },
      invalidatesTags: ["", "cryptoJobCountry"],
    }),

    updateCryptoJobCountry: builder.mutation({
      query: (body) => ({
        url: config.api.endpoints.updateCryptoJobCountry(body.id),
        method: "PATCH",
        body: body.data,
      }),

      transformResponse: (response) => {
        // handle successful response and return data to store
        // dispatch(updateUser(response));
        return response;
      },
      invalidatesTags: ["cryptoJobCountry"],
    }),

    deleteCryptoJobCountry: builder.mutation({
      query: (Id) => ({
        url: config.api.endpoints.deleteCryptoJobCountry(Id),
        method: "DELETE",
      }),
      transformResponse: (response) => {
        // handle successful response and return data to store
        return response;
      },
      invalidatesTags: ["cryptoJobCountry"],
    }),
  }),
});

// Generate hooks for each endpoint
export const {
  useGetCountriesQuery,
  useGetCountriesByIdQuery,
  useCreateCryptoJobCountryMutation,
  useUpdateCryptoJobCountryMutation,
  useDeleteCryptoJobCountryMutation,
} = cryptoCountryApi;
