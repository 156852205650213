import { React, useEffect } from "react";

import { Card, Col, Row, Select, Spin, Tag, Typography } from "antd";

import { FieldTimeOutlined, RetweetOutlined } from "@ant-design/icons";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  useChangeJobStatusMutation,
  useGetUserJobByIdQuery,
} from "../services/user";
import { hasPermission } from "../utils/permissions";

// TypoGraphy
const { Title, Text, Link } = Typography;
// End Typography

// Status Select
const Gig_Status = (value) => {
  console.log(`selected ${value}`);
};
// End Status Select

function Job_Detail() {
  const { id } = useParams();
  const history = useHistory();
  const isUserEdit = hasPermission("users:edit");
  const [
    changeJobStatus,
    {
      isSuccess: isStatusSuccess,
      data,
      isError: isStatusError,
      error: statusError,
      isLoading: isStatusLoading,
    },
  ] = useChangeJobStatusMutation();

  const {
    isLoading,
    isSuccess,
    data: job,
    refetch,
  } = useGetUserJobByIdQuery({
    jobId: id,
  });

  useEffect(() => {
    if (isStatusSuccess) {
      if (data?.message === "Job deleted successfully") {
        history.goBack();
      } else {
        refetch();
      }
    }
  }, [isStatusSuccess]);

  // Status Select
  const job_Status = (value) => {
    console.log(`selected ${value}`);
    changeJobStatus({
      jobId: id,
      status: value,
    });
  };

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col xs={24} md={24} sm={24} lg={24} xl={24} className="mb-24">
          <Card>
            {/* <img className="main_gig_detail_img mb-24" src="https://images.pexels.com/photos/585752/pexels-photo-585752.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /> */}
            <div>
              <Row gutter={[24, 0]} className="mb-24">
                {isUserEdit && (
                  <Col xs={24} md={12} sm={24} lg={6} xl={6} className="mb-24">
                    <>
                      {isStatusLoading && <Spin size="small" />}
                      <p className="font-bold mb-2 mt-3">Job Status</p>
                    </>
                    <Select
                      defaultValue="active"
                      style={{ width: 200 }}
                      onChange={job_Status}
                      value={job?.jobstatus || "active"}
                      options={[
                        {
                          value: "active",
                          label: "Active",
                        },
                        // {
                        //   value: "suspended",
                        //   label: "Suspended",
                        // },
                        {
                          value: "deleted",
                          label: "Deleted",
                        },
                      ]}
                    />
                  </Col>
                )}
                {/* <Col xs={24} md={12} sm={24} lg={6} xl={6} className="mb-24">
                  <p className="font-bold mb-0 mt-3">Offers</p>
                  <p className="mb-0 gig_detail_options">59</p>
                </Col>
                <Col xs={24} md={12} sm={24} lg={6} xl={6} className="mb-24">
                  <p className="font-bold mb-0 mt-3">Clicks</p>
                  <p className="mb-0 gig_detail_options">3547</p>
                </Col>
                <Col xs={24} md={12} sm={24} lg={6} xl={6} className="mb-24">
                  <p className="font-bold mb-0 mt-3">
                    Impressions{" "}
                    <span className="updown-arrow">
                      <ArrowUpOutlined type="success" />
                      <ArrowDownOutlined type="danger" />
                    </span>
                  </p>
                  <p className="mb-0 gig_detail_options">5746</p>
                </Col> */}
              </Row>
            </div>
            <div>
              <Row gutter={[24, 0]} className="mb-24">
                <Col xs={24} md={24} sm={24} lg={16} xl={16} className="mb-24">
                  <Title>{job?.jobtitle}</Title>
                  <p
                    className="mb-24"
                    dangerouslySetInnerHTML={{
                      __html: job?.jobdescription || "<p></p>",
                    }}
                  />

                  <div className="mb-24">
                    <Title level={5}>Skill</Title>
                    {job?.skills?.map((t) => (
                      <Tag color="#F47458">{t}</Tag>
                    ))}
                  </div>
                  <Row gutter={[24, 0]}>
                    <Col
                      xs={24}
                      md={12}
                      sm={24}
                      lg={6}
                      xl={6}
                      className="mb-24"
                    >
                      <p className="font-bold mb-0 mt-3">
                        <RetweetOutlined className="me-3" />
                        Revisions
                      </p>
                      <p className="mb-0 gig_detail_options">10</p>
                    </Col>
                    <Col
                      xs={24}
                      md={12}
                      sm={24}
                      lg={6}
                      xl={6}
                      className="mb-24"
                    >
                      <p className="font-bold mb-0 mt-3">
                        <FieldTimeOutlined className="me-3" />
                        Days
                      </p>
                      <p className="mb-0 gig_detail_options">
                        {job?.deliveryDay}
                      </p>
                    </Col>
                  </Row>
                  <Row gutter={[24, 0]}>
                    <Col
                      xs={24}
                      md={12}
                      sm={24}
                      lg={6}
                      xl={6}
                      className="mb-24"
                    >
                      <p className="font-bold mb-0 mt-3">Category</p>
                      <p className="mb-0 gig_detail_options">
                        {job?.jobcategory}
                      </p>
                    </Col>
                    <Col
                      xs={24}
                      md={12}
                      sm={24}
                      lg={6}
                      xl={6}
                      className="mb-24"
                    >
                      <p className="font-bold mb-0 mt-3">Budget ($)</p>
                      <p className="mb-0 gig_detail_options">
                        {job?.price || 0}
                      </p>
                    </Col>
                    <Col
                      xs={24}
                      md={12}
                      sm={24}
                      lg={6}
                      xl={6}
                      className="mb-24"
                    >
                      <p className="font-bold mb-0 mt-3">Payment Type</p>
                      <p className="mb-0 gig_detail_options">
                        {job?.payment_type}
                      </p>
                    </Col>
                    <Col
                      xs={24}
                      md={12}
                      sm={24}
                      lg={6}
                      xl={6}
                      className="mb-24"
                    >
                      <p className="font-bold mb-0 mt-3">Rate Type</p>
                      <p className="mb-0 gig_detail_options">
                        {job?.rate_type}
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default Job_Detail;
