import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
// import React from "react";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { Alert, Col, Layout, Menu, Row, Spin, Typography } from "antd";
import { useFormik } from "formik";
import { useState } from "react";
import { useSelector } from "react-redux";
import { protectedRoutesItems } from "../App";
import logo from "../assets/images/nav_logo_dark.png";
import { useLoginMutation } from "../services/auth";
import { useSitelogoGetQuery } from "../services/setting";
import { selectUser } from "../slices/user";
import { signinValidation } from "../validations/signinValidation";

const initialValues = {
  email: "",
  password: "",
};

const { Title } = Typography;
const { Header, Footer, Content } = Layout;

const SignIn = () => {
  const user = useSelector(selectUser);
  const [isAccessNotGranted, setIsAccessNotGranted] = useState(false);
  const [login, { isSuccess, data, isError, error, isLoading }] =
    useLoginMutation();
  const history = useHistory();

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    handleReset,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: signinValidation,
    onSubmit: (values, actions) => {
      login(values);
    },
  });

  useEffect(() => {
    if (isSuccess) {
      const permissions = data?.user?.permissions?.length
        ? data?.user?.permissions
        : [];
      const routes = protectedRoutesItems
        .map((r) => {
          if (r?.permissionKey) return { key: r.permissionKey, route: r.key };
          return { key: false, route: r.key };
        })
        .filter((r) => r.key);

      const navigateArray = routes
        .map((r) => {
          if (permissions.some((p) => p.resource === r.key)) {
            return r;
          } else return false;
        })
        .filter((r) => r);

      if (!navigateArray?.length) {
        return setIsAccessNotGranted(true);
      }

      const isDashboardPermission = navigateArray.some(
        (r) => r.key === "dashboard",
      );
      if (isDashboardPermission) {
        history.push("/dashboard");
      } else {
        history.push(navigateArray[0].route);
      }
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      // toast.error(error?.data?.message);
    }
  }, [isError]);

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const { data: webSiteLogo } = useSitelogoGetQuery();

  return (
    <>
      <Layout className="layout-default layout-signin h-full-screen">
        <Header>
          <div className="header-col header-nav">
            <Menu mode="horizontal" defaultSelectedKeys={["1"]}>
              <Menu.Item key="1">
                <Link to="/dashboard">
                  <img
                    // src={logo}
                    src={
                      webSiteLogo?.dashbaord_logo
                        ? webSiteLogo?.dashbaord_logo
                        : logo
                    }
                    alt=""
                    width={70}
                    height={40}
                  />
                </Link>
              </Menu.Item>
            </Menu>
          </div>
        </Header>

        <Content className="signin">
          <Row gutter={[24, 0]} justify="space-around">
            <Col
              xs={{ span: 24, offset: 0 }}
              lg={{ span: 8, offset: 0 }}
              md={{ span: 12 }}
            >
              {isAccessNotGranted && (
                <Alert
                  className="success-message"
                  message="Access Denied!"
                  type="error"
                  showIcon
                />
              )}

              {isSuccess && !isAccessNotGranted && (
                <Alert
                  className="success-message"
                  message="Sign-in successful!"
                  type="success"
                  showIcon
                />
              )}

              {isError && (
                <Alert
                  className="error-message"
                  message={`Incorrect Email or Password`}
                  type="error"
                  showIcon
                />
              )}
              <Title className="mb-15 fs-3 text-center">Sign In</Title>
              <Title
                className="font-regular text-muted text-center fs-6 fw-light"
                level={1}
              >
                Enter your email and password to sign in
              </Title>

              <div className="container-fluid mt-5" id="forms_validation">
                <div className="row">
                  <div className="col-12">
                    <form onSubmit={handleSubmit} className="signin_form">
                      <div className="input-block mb-20px">
                        <label htmlFor="email" className="form-label">
                          Email <span className="text-danger">*</span>
                        </label>
                        <input
                          type="email"
                          autoComplete="off"
                          name="email"
                          id="email"
                          className="form-control"
                          placeholder="Enter your email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.email && touched.email ? (
                          <span className="form_error">{errors.email}</span>
                        ) : null}
                      </div>
                      <div className="mb-20px position-relative">
                        <label for="sign_in_password" className="form-label">
                          Password <span className="text-danger">*</span>
                        </label>
                        <input
                          type={showPassword ? "text" : "password"}
                          className="form-control"
                          name="password"
                          autoComplete="off"
                          id="sign_in_password"
                          placeholder="Enter your password"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <span
                          className="password-toggle-signin"
                          onClick={togglePasswordVisibility}
                        >
                          {showPassword ? (
                            <EyeInvisibleOutlined />
                          ) : (
                            <EyeOutlined />
                          )}
                        </span>
                        {errors.password && touched.password ? (
                          <span className="form_error">{errors.password}</span>
                        ) : null}
                      </div>
                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn primary_orange_btn w-100 mt-3 mb-3"
                          disabled={isLoading} // Disable the button while loading
                        >
                          {isLoading ? (
                            <Spin size="small" /> // Display a loading indicator while loading
                          ) : (
                            "Sign In"
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Content>
        <Footer>
          {/* <Menu mode="horizontal">
            <Menu.Item>Company</Menu.Item>
            <Menu.Item>About Us</Menu.Item>
            <Menu.Item>Teams</Menu.Item>
            <Menu.Item>Products</Menu.Item>
            <Menu.Item>Blogs</Menu.Item>
            <Menu.Item>Pricing</Menu.Item>
          </Menu> */}
          {/* <Menu mode="horizontal" className="menu-nav-social">
            <Menu.Item>
              <Link to="#">{<DribbbleOutlined />}</Link>
            </Menu.Item>
            <Menu.Item>
              <Link to="#">{<TwitterOutlined />}</Link>
            </Menu.Item>
            <Menu.Item>
              <Link to="#">{<InstagramOutlined />}</Link>
            </Menu.Item>
            <Menu.Item>
              <Link to="#">
                <svg
                  width="18"
                  height="18"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path d="M496 256c0 137-111 248-248 248-25.6 0-50.2-3.9-73.4-11.1 10.1-16.5 25.2-43.5 30.8-65 3-11.6 15.4-59 15.4-59 8.1 15.4 31.7 28.5 56.8 28.5 74.8 0 128.7-68.8 128.7-154.3 0-81.9-66.9-143.2-152.9-143.2-107 0-163.9 71.8-163.9 150.1 0 36.4 19.4 81.7 50.3 96.1 4.7 2.2 7.2 1.2 8.3-3.3.8-3.4 5-20.3 6.9-28.1.6-2.5.3-4.7-1.7-7.1-10.1-12.5-18.3-35.3-18.3-56.6 0-54.7 41.4-107.6 112-107.6 60.9 0 103.6 41.5 103.6 100.9 0 67.1-33.9 113.6-78 113.6-24.3 0-42.6-20.1-36.7-44.8 7-29.5 20.5-61.3 20.5-82.6 0-19-10.2-34.9-31.4-34.9-24.9 0-44.9 25.7-44.9 60.2 0 22 7.4 36.8 7.4 36.8s-24.5 103.8-29 123.2c-5 21.4-3 51.6-.9 71.2C65.4 450.9 0 361.1 0 256 0 119 111 8 248 8s248 111 248 248z"></path>
                </svg>
              </Link>
            </Menu.Item>
            <Menu.Item>
              <Link to="#">{<GithubOutlined />}</Link>
            </Menu.Item>
          </Menu> */}
          <p className="copyright">
            © 2024, All right are reserved by
            {/* {<HeartFilled />} by */}
            <a href="" className="font-weight-bold" target="_blank">
              WorkAsPro
            </a>
          </p>
        </Footer>
      </Layout>
    </>
  );
};
export default SignIn;
