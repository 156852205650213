import ApiBase from "../app/ApiBase";
import config from "../config/development";
import { objectToQueryString } from "../utils/url";

export const subscriberApi = ApiBase.enhanceEndpoints({
  addTagTypes: ["Subscriber"],
}).injectEndpoints({
  endpoints: (builder) => ({
    getSubscriber: builder.query({
      query: (query) =>
        config.api.endpoints.getSubscribers(objectToQueryString(query)),
      transformResponse: (response) => {
        // handle successful response and return data to store
        console.log("response: ", response);
        return response;
      },
    }),
    getAllSubscriberCSV: builder.query({
      query: (query) => config.api.endpoints.getAllSubscriberCSV,
      transformResponse: (response) => {
        // handle successful response and return data to store
        console.log("response: ", response);
        return response?.subscribers;
      },
    }),
  }),
});

// Generate hooks for each endpoint
export const { useGetSubscriberQuery, useLazyGetAllSubscriberCSVQuery } =
  subscriberApi;
