import { useState } from "react";

const ExpandableText = ({ children, descriptionLength }) => {
  const fullText = children;

  // Set the initial state of the text to be collapsed
  const [isExpanded, setIsExpanded] = useState(false);

  // This function is called when the read more/less button is clicked
  const toggleText = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <p className="text">
      {isExpanded ? fullText : `${fullText.slice(0, descriptionLength)}`}
      {descriptionLength >= 100 ? (
        <span onClick={toggleText} className="toggle-button">
          {isExpanded ? "Show less" : "... Show more"}
        </span>
      ) : (
        ""
      )}
    </p>
  );
};

const DisplayExpandableText = ({ msg }) => {
  if (!msg?.message) return null;
  return (
    <>
      <p className={`mb-0 mt-2 px-1 w-fit-content message_inbox`}>
        <ExpandableText
          descriptionLength={
            Number(msg?.message?.length) >= 100
              ? 100
              : Number(msg?.message?.length)
          }
        >
          {msg?.message}
        </ExpandableText>
      </p>
    </>
  );
};

export default DisplayExpandableText;
