import { React, useEffect } from "react";

import { FieldTimeOutlined, RetweetOutlined } from "@ant-design/icons";
import Slider from "react-slick";

import { Card, Col, Row, Select, Spin, Tag, Typography } from "antd";
import toast from "react-hot-toast";
import { BiRevision } from "react-icons/bi";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  useChangeGigStatusMutation,
  useGetUserGigByIdQuery,
} from "../services/user";
import { hasPermission } from "../utils/permissions";

// Gig Option List
const data = [
  "Functional Website",
  "1 Page",
  "Design Customization",
  "Content Upload",
  "Responsive Design",
  "3 Plugins/Extensions",
  "E-Commerce Functionality",
  "5 Products",
];
// End Gig Option List

// TypoGraphy
const { Title, Text, Link } = Typography;
// End Typography

// End Status Select

function Gig_Detail() {
  const { id } = useParams();
  const history = useHistory();
  const isUserEdit = hasPermission("users:edit");
  const [
    changeGigStatus,
    {
      isSuccess: isStatusSuccess,
      data,
      isError: isStatusError,
      error: statusError,
      isLoading: isStatusLoading,
    },
  ] = useChangeGigStatusMutation();
  const {
    isLoading,
    isSuccess,
    data: gig,
    refetch,
  } = useGetUserGigByIdQuery({
    gigId: id,
  });

  const settings = {
    dots: false,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 3,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 3,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 3,
          dots: true,
          arrows: false,
        },
      },
    ],
  };
  useEffect(() => {
    if (isStatusSuccess) {
      if (data?.message === "Gig deleted successfully") {
        history.goBack();
      } else {
        toast.success(data?.message || "Success");
        refetch();
      }
    }
  }, [isStatusSuccess]);

  useEffect(() => {
    if (isStatusError) {
      toast.error(statusError?.data?.message || "Error While updating status");
    }
  }, [isStatusError]);
  // Status Select
  const Gig_Status = (value) => {
    console.log(`selected ${value}`);
    changeGigStatus({
      gigId: id,
      status: value,
    });
  };
  return (
    <>
      <Row gutter={[24, 0]}>
        <Col xs={24} md={24} sm={24} lg={24} xl={24} className="mb-24">
          <Card>
            <div className="gig_details_image_slider  pb-lg-3 pb-3">
              {console.log(gig, ">>>Checking console")}
              {gig?.gig?.gigmedia ? (
                <video
                  controls
                  src={gig?.gig?.gigmedia}
                  className="w-100 slider_image_gig_detail"
                />
              ) : (
                <Slider {...settings} className="slider_border">
                  {gig?.gig?.gigImages?.map((image, index) => (
                    <div className="" key={index}>
                      <img
                        src={
                          image ||
                          "https://work-as-pro.s3.us-east-2.amazonaws.com/staticPictures/dummy_gig.png"
                        }
                        className="w-100 slider_image_gig_detail"
                      />
                    </div>
                  ))}
                </Slider>
              )}
            </div>

            {/* <Card>
            <img
              className="main_gig_detail_img mb-24"
              src={
                gig?.gig?.gigImages
                  ? gig?.gig?.gigImages
                  : "https://images.pexels.com/photos/585752/pexels-photo-585752.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              }
              // src="https://images.pexels.com/photos/585752/pexels-photo-585752.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              alt=""
            /> */}

            {/* <div>
        <h2>For Slider </h2>
        <Slider {...settings}>
          <div>
          <img
              className="main_gig_detail_img mb-24"
              src="https://images.pexels.com/photos/585752/pexels-photo-585752.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              alt=""
            />
          </div>
          <div>
          <img
              className="main_gig_detail_img mb-24"
              src="https://images.pexels.com/photos/585752/pexels-photo-585752.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              alt=""
            />
          </div>
        </Slider>
      </div>  */}

            {/* For Video  */}
            {/* <div className="">
        <video src=""></video>
      </div> */}
            <div>
              <Row gutter={[24, 0]} className=" px-3">
                {isUserEdit && (
                  <Col xs={24} md={12} sm={24} lg={4} xl={4} className="">
                    <>
                      {isStatusLoading && <Spin size="small" />}
                      <p className="font-bold mb-1 all_text_fontsize">
                        Gig Status
                      </p>
                    </>
                    <Select
                      defaultValue="active"
                      style={{ width: 200 }}
                      onChange={Gig_Status}
                      value={gig?.gig?.gigstatus || "active"}
                      options={[
                        {
                          value: "active",
                          label: "Active",
                        },
                        {
                          value: "suspended",
                          label: "Suspended",
                        },
                        {
                          value: "deleted",
                          label: "Deleted",
                        },
                      ]}
                    />
                  </Col>
                )}
              </Row>
              <Row gutter={[24, 0]} className=" px-3">
                <Col
                  xs={12}
                  md={12}
                  sm={24}
                  lg={4}
                  xl={4}
                  className="text-center"
                >
                  <p className="font-bold mb-0 mt-3 all_text_fontsize">
                    Completed Orders
                  </p>
                  <p className="mb-0 gig_detail_options all_text_fontsize">
                    {gig?.completedOrders}
                  </p>
                </Col>
                <Col
                  xs={12}
                  md={12}
                  sm={24}
                  lg={4}
                  xl={4}
                  className="text-center"
                >
                  <p className="font-bold mb-0 mt-3 all_text_fontsize">
                    Active Orders
                  </p>
                  <p className="mb-0 gig_detail_options all_text_fontsize">
                    {gig?.activeOrders}
                  </p>
                </Col>
                <Col
                  xs={12}
                  md={12}
                  sm={24}
                  lg={4}
                  xl={4}
                  className="text-center"
                >
                  <p className="font-bold mb-0 mt-3 all_text_fontsize">
                    Cancelled Orders
                  </p>
                  <p className="mb-0 gig_detail_options all_text_fontsize">
                    {gig?.canceledOrders}
                  </p>
                </Col>
                <Col
                  xs={12}
                  md={12}
                  sm={24}
                  lg={4}
                  xl={4}
                  className="text-center"
                >
                  <p className="font-bold mb-0 mt-3 all_text_fontsize">
                    Average Orders
                  </p>
                  <p className="mb-0 gig_detail_options all_text_fontsize">
                    {Math.round(gig?.averageOrders)}
                  </p>
                </Col>
              </Row>
            </div>
            <div>
              <Row gutter={[24, 0]} className="mb-24 px-3">
                <Col xs={24} md={12} sm={24} lg={16} xl={16} className="">
                  <div style={{ wordBreak: "break-all" }}>
                    <Title level={3} className="pt-2 mb-0">
                      {gig?.gig?.gigtitle}
                    </Title>
                    <p
                      className="mb-0 all_text_fontsize"
                      dangerouslySetInnerHTML={{
                        __html: gig?.gig?.gigdescription || "<p></p>",
                      }}
                    />
                  </div>
                </Col>
                <Col xs={24} md={12} sm={24} lg={16} xl={16} className="">
                  <Row gutter={[24, 0]} className="">
                    <Col
                      xs={24}
                      md={12}
                      sm={24}
                      lg={6}
                      xl={6}
                      className="text-center"
                    >
                      <p className="font-bold mb-0 mt-3 all_text_fontsize">
                        Category
                      </p>
                      <p className="mb-0 gig_detail_options all_text_fontsize">
                        {gig?.gig?.gigcategory}
                      </p>
                    </Col>
                    <Col
                      xs={24}
                      md={12}
                      sm={24}
                      lg={6}
                      xl={6}
                      className="text-center"
                      style={{ textAlign: "center" }}
                    >
                      <p className="font-bold mb-0 mt-3 all_text_fontsize">
                        Budget ($)
                      </p>
                      <p className="mb-0 gig_detail_options all_text_fontsize">
                        {gig?.gig?.gigOffers && gig.gig?.gigOffers[0]?.price}
                      </p>
                    </Col>
                    <Col
                      xs={24}
                      md={12}
                      sm={24}
                      lg={6}
                      xl={6}
                      className="text-center"
                    >
                      <p className="font-bold mb-0 mt-3 all_text_fontsize">
                        Payment Method
                      </p>
                      <p className="mb-0 gig_detail_options all_text_fontsize">
                        {gig?.gig?.acceptedTokens?.join(", ")}
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Card>
        </Col>
      </Row>
      <Row gutter={[24, 0]}>
        {!(
          gig?.gig?.gigOffers && gig?.gig?.gigOffers[0]?.offertype === "BASIC"
        ) ? (
          <>
            <Col xs={24} md={24} sm={24} lg={12} xl={8} className="mb-24">
              <Card style={{ wordBreak: "break-all" }}>
                <Title level={5} className="text-center">
                  {gig?.gig?.gigOffers[0]?.offertype ? "Basic" : ""}
                </Title>
                <p>{gig?.gig?.gigOffers[0]?.offertitle}</p>
                <div className="d-flex justify-content-around">
                  <div className="">
                    <p className="pakg-option-head d-flex align-items-center all_text_fontsize">
                      <BiRevision className="me-2" />
                      Revisions
                    </p>
                    <p className="text-center pakg-option all_text_fontsize">
                      {gig?.gig?.gigOffers[0]?.revisions || 0}
                    </p>
                  </div>
                  <div className="">
                    <p className="pakg-option-head d-flex align-items-center all_text_fontsize">
                      <FieldTimeOutlined className="me-2" />
                      Days
                    </p>
                    <p className="text-center pakg-option all_text_fontsize">
                      {gig?.gig?.gigOffers[0]?.deliveryDays || 0}
                    </p>
                  </div>
                </div>
                <Tag
                  className="gig-pkg-price mb-24 all_text_fontsize"
                  color="#F47458"
                >
                  <span>$</span>
                  {gig?.gig?.gigOffers[0]?.price || 0}
                </Tag>
              </Card>
            </Col>
          </>
        ) : (
          <>
            <Col xs={24} md={24} sm={24} lg={12} xl={8} className="mb-24">
              <Card style={{ wordBreak: "break-all" }}>
                <Title level={2}>{gig?.gig?.gigOffers[0]?.offertype}</Title>
                <p>{gig?.gig?.gigOffers[0]?.offertitle}</p>
                <div className="d-flex justify-content-around">
                  <div className="">
                    <p className="pakg-option-head">
                      <RetweetOutlined className="me-3" />
                      Revisions
                    </p>
                    <p className="text-center pakg-option">
                      {gig?.gig?.gigOffers[0]?.revisions}
                    </p>
                  </div>
                  <div className="">
                    <p className="pakg-option-head">
                      <FieldTimeOutlined className="me-3" />
                      Days
                    </p>
                    <p className="text-center pakg-option">
                      {gig?.gig?.gigOffers[0]?.deliveryDays}
                    </p>
                  </div>
                </div>
                <Tag className="gig-pkg-price mb-24" color="#F47458">
                  <span>$</span>
                  {gig?.gig?.gigOffers[0]?.price}
                </Tag>
              </Card>
            </Col>
            <Col xs={24} md={24} sm={24} lg={12} xl={8} className="mb-24">
              <Card style={{ wordBreak: "break-all" }}>
                <Title level={2}>{gig?.gig?.gigOffers[1]?.offertype}</Title>
                <p>{gig?.gig?.gigOffers[1]?.offertitle}</p>
                <div className="d-flex justify-content-around">
                  <div className="">
                    <p className="pakg-option-head">
                      <RetweetOutlined className="me-3" />
                      Revisions
                    </p>
                    <p className="text-center pakg-option">
                      {gig?.gig?.gigOffers[1]?.revisions}
                    </p>
                  </div>
                  <div className="">
                    <p className="pakg-option-head">
                      <FieldTimeOutlined className="me-3" />
                      Days
                    </p>
                    <p className="text-center pakg-option">
                      {gig?.gig?.gigOffers[1]?.deliveryDays}
                    </p>
                  </div>
                </div>
                <Tag className="gig-pkg-price mb-24" color="#F47458">
                  <span>$</span>
                  {gig?.gig?.gigOffers[1]?.price}
                </Tag>
              </Card>
            </Col>
            <Col xs={24} md={24} sm={24} lg={12} xl={8} className="mb-24">
              <Card style={{ wordBreak: "break-all" }}>
                <Title level={2}>{gig?.gig?.gigOffers[2]?.offertype}</Title>
                <p>{gig?.gig?.gigOffers[2]?.offertitle}</p>
                <div className="d-flex justify-content-around">
                  <div className="">
                    <p className="pakg-option-head">
                      <RetweetOutlined className="me-3" />
                      Revisions
                    </p>
                    <p className="text-center pakg-option">
                      {gig?.gig?.gigOffers[2]?.revisions}
                    </p>
                  </div>
                  <div className="">
                    <p className="pakg-option-head">
                      <FieldTimeOutlined className="me-3" />
                      Days
                    </p>
                    <p className="text-center pakg-option">
                      {gig?.gig?.gigOffers[2]?.deliveryDays}
                    </p>
                  </div>
                </div>
                <Tag className="gig-pkg-price mb-24" color="#F47458">
                  <span>$</span>
                  {gig?.gig?.gigOffers[2]?.price}
                </Tag>
              </Card>
            </Col>
          </>
        )}
      </Row>
    </>
  );
}

export default Gig_Detail;
