import ApiBase from "../app/ApiBase";
import config from "../config/development";
import { objectToQueryString } from "../utils/url";

export const cryptoAddressApi = ApiBase.injectEndpoints({
  endpoints: (builder) => ({
    getAddress: builder.query({
      query: (query) =>
        config.api.endpoints.getCryptoAddress(objectToQueryString(query)),
      transformResponse: (response) => {
        // handle successful response and return data to store
        console.log("response: ", response);
        return response;
      },
      keepUnusedDataFor: 1000,
      providesTags: ["cryptoJobCity"],
    }),
    createCryptoJobCity: builder.mutation({
      query: (data) => ({
        url: config.api.endpoints.createCryptoJobCity,
        method: "POST",
        body: data,
      }),
      transformResponse: (response) => {
        // handle successful response and return data to store

        console.log("response: ", response);
        return response;
      },
      invalidatesTags: ["", "cryptoJobCity"],
    }),

    updateCryptoJobCity: builder.mutation({
      query: (body) => ({
        //  config.api.endpoints.updateCryptoJobCity(query),
        url: config.api.endpoints.updateCryptoJobCity(body.id),
        method: "PATCH",
        body: body.data,
      }),

      transformResponse: (response) => {
        // handle successful response and return data to store
        // dispatch(updateUser(response));
        return response;
      },
      invalidatesTags: ["cryptoJobCity"],
    }),
    deleteCryptoJobCity: builder.mutation({
      query: (Id) => ({
        url: config.api.endpoints.deleteCryptoJobCity(Id),
        method: "DELETE",
      }),
      transformResponse: (response) => {
        // handle successful response and return data to store
        return response;
      },
      invalidatesTags: ["cryptoJobCity"],
    }),
  }),
});

// Generate hooks for each endpoint
export const {
  useGetAddressQuery,
  useCreateCryptoJobCityMutation,
  useUpdateCryptoJobCityMutation,
  useDeleteCryptoJobCityMutation,
} = cryptoAddressApi;
