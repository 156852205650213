import React from "react";
import ModalImage from "react-modal-image";
// import FileIconSvg from '../../../images/file-icon.svg'; // Adjust the path accordingly
import { FaFileVideo } from "react-icons/fa";
import { formatSize, handleCustomDownload } from "../../utils";
import FileIcon from "./FileIcon";
function getFileExtension(fileName) {
  const dotIndex = fileName.lastIndexOf(".");
  return dotIndex !== -1 ? fileName.substring(dotIndex + 1) : "";
}
const DisplayFiles = ({ msg, isReply = false, shouldShowReply }) => {
  const areFiles = msg.files && msg.files.length;

  if (!areFiles) {
    return null;
  } else {
    const shouldDownloadButtonDisplay = areFiles > 1;
    const handleDownloadAll = () => {
      msg.files.forEach((file) => {
        handleCustomDownload({
          url: file.file,
          fileName: file.fileName,
        });
      });
    };
    return (
      <>
        <div className="d-flex justify-content-start bg-transparent w-100">
          <div className=" h-50 ps-2">
            {shouldDownloadButtonDisplay && !isReply && shouldShowReply && (
              <>
                {" "}
                <button
                  className="download_file_link_btn"
                  onClick={handleDownloadAll}
                >
                  Download All
                </button>
                <br />
                <span className="one_file_link_download">{areFiles} Files</span>
              </>
            )}
            <div className="images_div_wrap">
              <div className="d-flex flex-wrap">
                {msg.files?.map((file, index) => (
                  <div>
                    <div className="image_specific_width me-2 mb-2" key={index}>
                      {file.type === "image" && (
                        <ModalImage
                          small={file.file}
                          large={file.file}
                          hideDownload={true}
                          className="rounded-2 image_specific_width"
                        />
                      )}
                      {file.type === "video" && (
                        <>
                          <div className="mb-0 w-fit-content">
                            <div className="ps-2">
                              <div className="mb-0 w-fit-content">
                                <div className="ps-2 file_and_video_width">
                                  <FaFileVideo fontSize={30} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      {file.type === "application" && (
                        <>
                          <div className="d-flex justify-content-center align-items-center h-100">
                            <div className="mb-0 w-fit-content">
                              <div className="ps-2">
                                <FileIcon />
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <p className="mb-0 w-fit-content px-1 message_inbox pb-1">
                      <span
                        className="text-dark"
                        onClick={() => {
                          if (shouldShowReply)
                            handleCustomDownload({
                              url: file.file,
                              fileName: file.fileName,
                            });
                        }}
                        style={{
                          cursor: shouldShowReply ? "pointer" : "default",
                        }}
                      >
                        <i className="fas fa-file-download me-1 fs-6 icon_grey_color"></i>{" "}
                        <span
                          className="message_filename_text"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title={`${file?.fileName}(${formatSize(file?.size)})`}
                        >
                          {file?.fileName?.length > 5
                            ? `${file?.fileName?.substring(
                                0,
                                1,
                              )}.${getFileExtension(
                                file?.fileName,
                              )} (${formatSize(file?.size)})`
                            : `${file?.fileName} (${formatSize(file?.size)})`}
                        </span>
                      </span>
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default DisplayFiles;
