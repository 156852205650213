import moment from "moment";
import { ChatDate } from "./ChatDate";

export function ChatMessages({
  messages,
  setActivePurposal,
  selectedConvoUser,
  selectedConvo,
  currentConvoUser,
  buyerSelected,
}) {
  const groupedMessages = messages?.reduce((acc, message) => {
    const date = moment(message.createdAt).format("L");
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(message);
    return acc;
  }, {});

  return (
    <>
      {Object.keys(groupedMessages || {})?.map((date, index) => (
        <ChatDate
          key={index}
          date={date}
          setActivePurposal={setActivePurposal}
          messages={groupedMessages[date]}
          selectedConvo={selectedConvo}
          selectedConvoUser={selectedConvoUser}
          currentConvoUser={currentConvoUser}
          buyerSelected={buyerSelected}
        />
      ))}
    </>
  );
}
