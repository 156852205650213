import React from "react";
import "react-quill/dist/quill.snow.css";
import { Link } from "react-router-dom";
import CreateCryptoJobForm from "../components/crypto/createCryptoJobForm";
import { CryptoJobFormikProvider } from "../hooks/useFormikCryptoJobContext";

const Createcryptojob = () => {
  return (
    <CryptoJobFormikProvider>
      <div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="">
                <div className="d-flex justify-content-center">
                  <div className="mt-lg-5 mt-md-4 mt-3 mb-lg-5 mb-md-4 mb-3 crypto_featured_jobs_max_width">
                    <div className="row align-items-center h-100">
                      <div className="col-12">
                        <h4 className="create_crypto_job_heading">
                          Hire TOP Web3, Crypto and Blockchain Talent
                        </h4>
                        <p className="mb-0 looking_for_web3_services_p">
                          Looking for{" "}
                          <Link to="/cryptojobs">
                            {" "}
                            web3 recruitment services
                          </Link>{" "}
                          ? Fill out the form to schedule a call with our
                          recruiterment partner.
                        </p>
                        <p className="looking_for_web3_services_p">
                          Planning to Post a job later?
                        </p>
                      </div>
                      <div className="col-12">
                        <CreateCryptoJobForm />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CryptoJobFormikProvider>
  );
};

export default Createcryptojob;
