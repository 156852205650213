import ApiBase from "../app/ApiBase";
import config from "../config/development";
import { objectToQueryString } from "../utils/url";

export const applicantsApi = ApiBase.injectEndpoints({
  endpoints: (builder) => ({
    getApplicants: builder.query({
      query: (query) =>
        config.api.endpoints.getCryptoApplicants(objectToQueryString(query)),
      transformResponse: (response) => {
        // handle successful response and return data to store
        console.log("response: ", response);
        return response;
      },
      keepUnusedDataFor: 1000,
      providesTags: ["cryptoApplicants"],
    }),
  }),
});

// Generate hooks for each endpoint
export const { useGetApplicantsQuery } = applicantsApi;
