import {
  EditFilled,
  EyeInvisibleOutlined,
  EyeOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { Button, Form, Input, Modal, Spin } from "antd";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import ImageUploading from "react-images-uploading";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "../../assets/styles/main.css";
import { useUpdateCredentialsMutation } from "../../services/auth";
import { selectUser } from "../../slices/user";

const UserProfile = () => {
  const user = useSelector(selectUser);
  console.log("User", user);
  // console.log("user=>", user);
  const [isChangeEmailModalOpen, setIsChangeEmailModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const showModalChangeEmail = () => {
    setIsChangeEmailModalOpen(true);
  };

  const handleOkChangeEmail = () => {
    setIsChangeEmailModalOpen(false);
  };

  const handleCancelChangeEmail = () => {
    setIsChangeEmailModalOpen(false);
  };
  const [form] = Form.useForm();
  const [images, setImages] = React.useState([]);
  const maxNumber = 1;
  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    // console.log(imageList, addUpdateIndex);

    setImages(imageList);
  };

  const [updateCredentials, { error }] = useUpdateCredentialsMutation();
  // console.log("profile user=>", user);

  const initialValues = {
    image: "",
    currentEmail: user?.email || "",
    newEmail: "",
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  };
  const onFinish = (values) => {
    // console.log("Received values of form: ", values);
    // console.log("Current Password:", values.currentPassword);
    setLoading(true);
    let data = {
      ...values,
      image: images[0]?.data_url || "",
      currentPassword: values.currentPassword,

      ...(values.newPassword && { newPassword: values.newPassword }),
      confirmPassword: values.confirmPassword,
    };
    data = Object.fromEntries(
      Object.entries(data).filter(([_, v]) => v !== ""),
    );
    updateCredentials(data)
      .then((response) => {
        // Check if the API call is successful and there are no errors
        if (response && !response.error) {
          // Reset the form fields
          form.resetFields();

          // Close the modal if the API call is successful and without errors
          handleOkChangeEmail();
          toast.success("Successfully updated");
          setLoading(false);
          // Additional success handling if needed
          // console.log("API call successful:", response);
          console.log("User Data =>", user);
        } else {
          toast.error(response.error.data.message);
          setLoading(false);
          // Handle the case where the API call is successful but has errors
          console.log("API call successful but with errors:", response);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Something went wrong");
        // Handle errors if needed
        // console.error("Error updating credentials:", error);
      });
  };
  const [showLivePassword, setshowLivePassword] = useState(false);

  const toggleLivePasswordVisibility = () => {
    setshowLivePassword(!showLivePassword);
  };
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const toggleconfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  useEffect(() => {
    // Update form fields when the user object changes
    form.setFieldsValue({
      currentEmail: user?.email || "",
    });
    if (user?.image) {
      setImages([{ data_url: user.image }]);
    }
  }, [user, form]);
  return (
    <>
      <li className="list-unstyled">
        <Link
          to=""
          className=""
          data-bs-toggle="dropdown"
          style={{ zIndex: "2", textDecoration: "none" }}
        >
          <div className="nav_bar_user_image_new">
            <img
              src={
                user?.image ||
                "https://work-as-pro.s3.us-east-2.amazonaws.com/staticPictures/image_2024_02_05T06_10_50_024Z.png"
              }
              className="rounded-circle main_nav_user_profile_img ms-2"
              width="50px"
              alt="pic"
              style={{ width: "30px", height: "30px", objectFit: "fill" }}
            />

            <div
            //   className={`${
            //     user?.userDetail?.user?.onlineStatus == "online"
            //       ? "nav_bar_user_image_dot"
            //       : "nav_bar_user_image_offline_dot"
            //   }`}
            ></div>
          </div>
        </Link>

        <div
          className="dropdown-menu dropdown-menu-end userprofile_dropdown mb-0 pb-1"
          style={{ border: "none" }}
        >
          <div className="text-end pe-3 pt-2">
            <span className="edit_filler_color">
              <EditFilled onClick={showModalChangeEmail} className="" />
            </span>
          </div>
          <div className="side_bar_top_user_profile_box text-center">
            <img
              className="sidebar_profile_img mt-3 position-relative"
              src={
                user?.image ||
                "https://work-as-pro.s3.us-east-2.amazonaws.com/staticPictures/image_2024_02_05T06_10_50_024Z.png"
              }
              alt="pic"
            />

            <span
            //   className={`${
            //     user?.userDetail?.user?.onlineStatus == "online"
            //       ? "online_dot_user_dropdwon"
            //       : "offline_dot_color"
            //   }`}
            ></span>
            <div className="d-flex justify-content-center flex-column">
              <h5 className="sidebar_username pb-2 fw-bold">
                {user?.username}
              </h5>
              <p className="profile_dropdown_email">{user?.email}</p>
            </div>
          </div>

          <div className="side_bar_links_first_box">
            <ul className="" style={{ listStyle: "none", paddingLeft: "0px" }}>
              <li>
                {/* <Link
                  className="sidebar_link"
                  to={`/profile/${user?.username}`}
                > */}
                <Modal
                  id="profileModal"
                  title="Edit Your Profile"
                  open={isChangeEmailModalOpen}
                  onCancel={handleCancelChangeEmail}
                  footer={null}
                >
                  <Form
                    layout="vertical"
                    initialValues={initialValues}
                    onFinish={onFinish}
                    form={form}
                  >
                    <p>Upload Image</p>
                    <ImageUploading
                      multiple
                      value={images}
                      onChange={onChange}
                      maxNumber={maxNumber}
                      dataURLKey="data_url"
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => (
                        // write your building UI
                        <div className="upload__image-wrapper">
                          {!images?.length && (
                            <div className="profile_image_select_box mb-3">
                              <button
                                // style={isDragging ? { color: 'red' } : undefined}
                                className="image_selct_box text-decoration-none"
                                onClick={onImageUpload}
                                {...dragProps}
                              >
                                <PlusCircleOutlined /> Upload
                              </button>
                            </div>
                          )}
                          <div>
                            {imageList.map((image, index) => (
                              <div key={index} className="image-item">
                                <div className="image_upload_box">
                                  <img
                                    src={image["data_url"]}
                                    alt=""
                                    width="100"
                                    onClick={() => onImageUpdate(index)}
                                  />
                                </div>
                                <div className="image-item__btn-wrapper">
                                  <button
                                    onClick={(e) => {
                                      onImageUpdate(index);
                                      e.preventDefault();
                                    }}
                                    className="btn update_img_btn"
                                  >
                                    Update
                                  </button>
                                  <button
                                    onClick={() => onImageRemove(index)}
                                    className="btn remove_img_btn"
                                  >
                                    Remove
                                  </button>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </ImageUploading>
                    <Form.Item
                      label="Enter Current Email"
                      name="currentEmail"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Email!",
                        },
                        {
                          pattern:
                            /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
                          message: "Please enter a valid email address!",
                        },
                      ]}
                    >
                      <Input placeholder="Email" className="mt-1" disabled />
                    </Form.Item>
                    <Spin
                      align="center"
                      spinning={loading}
                      size="large"
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        zIndex: 9999, // Set a high z-index
                      }}
                    >
                      {/* Your form or other content */}
                    </Spin>
                    <Form.Item
                      label="Enter New Email"
                      name="newEmail"
                      rules={[
                        {
                          pattern:
                            /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
                          message: "Please enter a valid email address!",
                        },
                      ]}
                    >
                      <Input placeholder="Email" className="mt-1" />
                    </Form.Item>
                    <Form.Item
                      label="Enter Current Password"
                      name="currentPassword"
                    >
                      <Input
                        placeholder="Password"
                        type={showLivePassword ? "text" : "password"}
                        className="mt-1"
                        onChange={(e) => {
                          form.setFieldsValue({
                            currentPassword: e.target.value,
                          });
                          // other logic here
                        }}
                      />
                      <span
                        className="password-toggle-user-profile"
                        onClick={toggleLivePasswordVisibility}
                      >
                        {showLivePassword ? (
                          <EyeInvisibleOutlined />
                        ) : (
                          <EyeOutlined />
                        )}
                      </span>
                    </Form.Item>
                    <Form.Item label="Enter New Password" name="newPassword">
                      <Input
                        placeholder="Password"
                        type={showPassword ? "text" : "password"}
                        className="mt-1"
                        onChange={(e) => {
                          form.setFieldsValue({
                            newPassword: e.target.value,
                          });
                        }}
                      />
                      <span
                        className="password-toggle-user-profile"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? (
                          <EyeInvisibleOutlined />
                        ) : (
                          <EyeOutlined />
                        )}
                      </span>
                    </Form.Item>
                    <Form.Item
                      label="Enter Confirm Password"
                      name="confirmPassword"
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (
                              !value ||
                              getFieldValue("newPassword") === value
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error("The password doesn't match!"),
                            );
                          },
                        }),
                      ]}
                    >
                      <Input
                        placeholder="Password"
                        type={showConfirmPassword ? "text" : "password"}
                        className="mt-1"
                        onChange={(e) => {
                          form.setFieldsValue({
                            confirmPassword: e.target.value,
                          });
                        }}
                      />
                      <span
                        className="password-toggle-user-profile"
                        onClick={toggleconfirmPasswordVisibility}
                      >
                        {showConfirmPassword ? (
                          <EyeInvisibleOutlined />
                        ) : (
                          <EyeOutlined />
                        )}
                      </span>
                    </Form.Item>
                    <Form.Item label=" " colon={false} className="text-end">
                      <Button
                        className="m-1"
                        type=""
                        onClick={handleCancelChangeEmail}
                      >
                        Cancel
                      </Button>

                      <Button className="m-1" type="primary" htmlType="submit">
                        Submit
                      </Button>
                    </Form.Item>
                  </Form>
                </Modal>
                {/* </Link> */}
              </li>
            </ul>
          </div>
        </div>
      </li>
    </>
  );
};

export default UserProfile;
