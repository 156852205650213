import { Col, Row } from "antd";
import React, { useState } from "react";
import DisputeTable from "../components/DisputeTable";
import { useGetPendingDisputesQuery } from "../services/dispute";

const Pendingdisputes = () => {
  const [page, setPage] = useState(1);
  const [pageLength, setPageLength] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [search, setSearch] = useState("");

  let { data, refetch } = useGetPendingDisputesQuery(
    `limit=${pageLength}&page=${page}&fields=uniqueOrderId,user,sellerId,jobId,gigId,status,cryptoToken,disputeProof,disputeDescription,orderId,status&search=${searchQuery}`,
  );

  return (
    <div>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <Col xs={24} md={24} sm={24} lg={24} xl={24} className="mb-24">
            <div bordered={false} className="criclebox mb-5">
              <Row
                gutter={[24, 0]}
                style={{ paddingLeft: "2em", paddingRight: "2em" }}
              >
                <Col xs={24} md={24} sm={24} lg={24} xl={24} className="mb-24">
                  <DisputeTable
                    searchQuery={searchQuery}
                    search={search}
                    setSearch={setSearch}
                    offersData={data}
                    refetch={refetch}
                    pageLength={pageLength}
                    setPageLength={setPageLength}
                    page={page}
                    setPage={setPage}
                    setSearchQuery={setSearchQuery}
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Pendingdisputes;
