import ApiBase from "../app/ApiBase";
import config from "../config/development";
import { loginSuccess, selectUser, updateUser } from "../slices/user";
import { disconnectWallet } from "../slices/wallet";
import store from "../store";
import { objectToQueryString } from "../utils/url";

export const authApi = ApiBase.enhanceEndpoints({
  addTagTypes: ["User"],
}).injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query({
      query: () => {
        const user = store.getState(selectUser);
        const isAuthenticated = !!user;
        if (isAuthenticated) {
          return config.api.endpoints.getUser;
        } else return "";
      },
      transformResponse: (response) => {
        // handle successful response and return data to store
        store.dispatch(updateUser(response.user));
        return response;
      },
      providesTags: ["getUser"],
    }),
    getAllAdmins: builder.query({
      query: (query) =>
        config.api.endpoints.getAllAdmins(objectToQueryString(query)),
      transformResponse: (response) => {
        // handle successful response and return data to store
        console.log("response => ", response);
        return response;
      },
      providesTags: ["getAllAdmins"],
    }),
    getAdminByUsername: builder.query({
      query: ({ username }) =>
        config.api.endpoints.getAdminByUsername(username),
      transformResponse: (response) => {
        // handle successful response and return data to store
        return response;
      },
      providesTags: ["getAdminByUsername"],
    }),
    updateAdmin: builder.mutation({
      query: (body) => ({
        url: config.api.endpoints.updateAdmin,
        method: "PUT",
        body,
      }),
      transformResponse: (response) => {
        // handle successful response and return data to store
        return response;
      },
      invalidatesTags: ["getAllAdmins", "getAdminByUsername"],
    }),

    updateCredentials: builder.mutation({
      query: (body) => ({
        url: config.api.endpoints.updateCredentials,
        method: "PUT",
        body,
      }),

      transformResponse: (response) => {
        // handle successful response and return data to store
        // dispatch(updateUser(response));
        return response;
      },
      invalidatesTags: ["getUser", "getAllAdmins", "getAdminByUsername"],
    }),

    createAdmin: builder.mutation({
      query: (body) => ({
        url: config.api.endpoints.createAdmin,
        method: "POST",
        body,
      }),
      transformResponse: (response) => {
        // handle successful response and return data to store
        return response;
      },
      invalidatesTags: ["getAllAdmins", "getAdminByUsername"],
    }),
    deleteAdmin: builder.mutation({
      query: ({ username }) => ({
        url: config.api.endpoints.deleteAdmin(username),
        method: "DELETE",
      }),
      transformResponse: (response) => {
        // handle successful response and return data to store
        return response;
      },
      invalidatesTags: ["getAllAdmins", "getAdminByUsername"],
    }),
    signup: builder.mutation({
      query: (body) => ({
        url: config.api.endpoints.signup,
        method: "POST",
        body,
      }),
      providesTags: ["User"],
      transformResponse: (response) => {
        // handle successful response and return data to store
      },
    }),
    login: builder.mutation({
      query: (body) => ({
        url: config.api.endpoints.login,
        method: "POST",
        body,
      }),
      providesTags: ["User"],
      transformResponse: (response) => {
        const { tokens, user } = response; // Destructure the token from the response
        let token = tokens?.refresh?.token;
        // sessionStorage.setItem('jwt', token);

        // Dispatch the loginSuccess action with the user data as the payload
        store.dispatch(loginSuccess({ ...user, token }));

        // Clear the wallet slice
        store.dispatch(disconnectWallet());

        return response;
      },
    }),
  }),
});

// Generate hooks for each endpoint
export const {
  useSignupMutation,
  useLoginMutation,
  useGetUserQuery,
  useGetAdminByUsernameQuery,
  useGetAllAdminsQuery,
  useUpdateAdminMutation,
  useUpdateCredentialsMutation,
  useDeleteAdminMutation,
  useCreateAdminMutation,
} = authApi;
