export function objectToQueryString(obj) {
  const keyValuePairs = [];

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = encodeURIComponent(obj[key]);
      keyValuePairs.push(`${key}=${value}`);
    }
  }

  return keyValuePairs.join("&");
}
