import { FaReply } from "react-icons/fa";
import { useSelector } from "react-redux";
import DisplayCustomOffer from "./DisplayCustomOffer";
import DisplayExpandableText from "./DisplayExpendableText";
import DisplayFiles from "./DisplayFiles";
import MessageHeader from "./MessageHeader";

const DisplayReplyButton = (selectedConvo, msg) => {
  const getName = () => {
    const member = selectedConvo?.members?.find(
      (m) => m._id === msg.senderId,
    )?.username;

    return member ?? "";
  };

  return (
    <p className="mb-0 reply_msg_btn mt-2">
      <FaReply className="me-2" />
      {getName()}
      &nbsp; Replied
    </p>
  );
};

const DisplayReply = ({
  msg,
  selectedConvo,
  setActivePurposal,
  shouldShowReply,
  selectedConvoUser,
  currentConvoUser,
}) => {
  const shouldReply = msg.parentId && Object.keys(msg.parentId).length;
  const user = useSelector((state) => state.user);

  console.log("@displayReplay => ", selectedConvo);

  if (shouldReply && shouldShowReply) {
    return (
      <>
        <div className="" id={msg._id}>
          {DisplayReplyButton(selectedConvo, msg)}
          {console.log(
            selectedConvo?.members[0]?.username,
            ">>Checking selected convo",
          )}
          <div
            className={"replied_message_border"}
            style={{ cursor: "pointer" }}
            // onClick={handleGoToReplyMessage}
          >
            <MessageHeader
              selectedConvo={selectedConvo}
              currentConvoUser={currentConvoUser}
              shouldImageDisplay={false}
              msg={msg.parentId}
              selectedConvoUser={selectedConvoUser}
              shouldShowReply={false}
            />
            <DisplayExpandableText msg={msg.parentId} />

            <DisplayFiles msg={msg.parentId} isReply={true} />

            <DisplayCustomOffer
              selectedConvo={selectedConvo}
              setActivePurposal={setActivePurposal}
              msg={msg.parentId}
              user={user}
            />
          </div>
        </div>
      </>
    );
  } else {
    return null;
  }
};

export default DisplayReply;
