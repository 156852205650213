import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { selectUser } from "../slices/user";
import { hasPermission } from "../utils/permissions";

const ProtectedRoute = ({
  component: Component,
  requiredPermissions,
  ...rest
}) => {
  const user = useSelector(selectUser); // Access the user state using the selectUser selector
  const [loading, setLoading] = useState(true);
  const isAuthenticated = !!user;

  useEffect(() => {
    let timer;
    if (!isAuthenticated) {
      timer = setTimeout(() => {
        setLoading(false);
      }, 3000);
    } else {
      setLoading(false);
    }
    return () => clearTimeout(timer);
  }, [isAuthenticated]);

  const hasPermissions =
    isAuthenticated &&
    requiredPermissions?.every((requiredPermission) => {
      const isPermission = hasPermission(requiredPermission);
      return isPermission;
    });

  if (loading) {
    // Render a loading indicator or skeleton screen
    return <div></div>;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated && hasPermissions ? (
          <Component {...props} />
        ) : (
          <Redirect to="/signin" /> // Redirect to the sign-in page if not authenticated
        )
      }
    />
  );
};

export default ProtectedRoute;
