import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import React, { useState } from "react";
import toast from "react-hot-toast";

import useQueryParams from "../hooks/useQueryParams";
import {
  useCreateCryptoJobCityMutation,
  useDeleteCryptoJobCityMutation,
  useGetAddressQuery,
  useUpdateCryptoJobCityMutation,
} from "../services/cryptoAddress";
import { useGetCountriesQuery } from "../services/cryptoCountry";
import { useGetTechnologiesQuery } from "../services/technology";
import { hasPermission } from "../utils/permissions";
const { Search } = Input;
const { Option } = Select;

const onSearch = (value) => console.log(value);
// Search End

const { Text, Link } = Typography;

const { TextArea } = Input;

const { confirm } = Modal;

function Cryptocitywithcountry() {
  const [ShowUser, setShowUser] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedId, setSelectedId] = useState(null);
  const isTechnologyCreate = hasPermission("technologies:create");
  const isTechnologyEdit = hasPermission("technologies:edit");
  const isTechnologyDelete = hasPermission("technologies:delete");
  const [cryptoCityData, setCryptoCityData] = useState(null);

  const {
    isLoading,
    data: technologies,
    isError,
    error,
  } = useGetTechnologiesQuery(
    {
      page,
      perPage: 10,
      // search: searchQuery,
    },
    {
      selectFromResult: ({ data, isLoading, error }) => ({
        // clear data if error exists
        data: error ? [] : data,
        isLoading,
        error,
      }),
    },
  );

  const { isLoading: countryLoading, data: countryData } = useGetCountriesQuery(
    {
      page: 1,
      limit: 300,
    },
  );
  const { isLoading: cityLoading, data: cityData } = useGetAddressQuery({
    page: 1,
    limit: 300,
    city: searchQuery,
  });

  const [
    createCryptoJobCity,
    {
      // isLoading: isCreateAdminLoading,
      // isSuccess: isCreateAdminSuccess,
      // isError: isCreateAdminError,
      // error: createAdminError,
      // data: createAdminData,
    },
  ] = useCreateCryptoJobCityMutation();
  useQueryParams({
    params: {
      page: [page > 1 ? page : null, setPage, 1],
      search: [searchQuery, setSearchQuery, "", true, setSearch], // Update setSearch as well
    },
    watchList: [searchQuery, page],
  });
  const [updateCityMutation] = useUpdateCryptoJobCityMutation();
  const [deleteCryptoJobCity] = useDeleteCryptoJobCityMutation();
  const showDeleteConfirm = (id) => {
    confirm({
      title: "Are you sure delete this City?",
      icon: <ExclamationCircleFilled />,
      // content: 'Some descriptions',
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteCryptoJobCity(id);
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const OpenUser = (id) => {
    setSelectedId(id);
    // setShowUser(true);
    const existingCity = cityData?.addresses.find((city) => city._id === id);
    if (existingCity) {
      // Populate form fields with existing city data
      Cityform.setFieldsValue({
        select_country: existingCity.country, // Assuming select_country is the name of the country field in the form
        city: existingCity.city,
      });
    }

    showCityModal();
  };
  const UserOk = () => {
    setSelectedId(null);
    setShowUser(false);
    showCityModal(false);
  };
  const UserCancel = () => {
    setSelectedId(null);
    setShowUser(false);
  };
  // Create User Modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setSelectedId(null);
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setSelectedId(null);
    setIsModalOpen(false);
  };
  const onSearch = (value) => {
    setSearchQuery(value); // Update the 'search' state with the entered value
  };

  // Set up pagination options
  const paginationOptions = {
    total: cityData?.addresses?.totalCount || 0, // Set the total number of items
    current: page, // Set the current page number
    pageSize: 10, // Set the number of items per page
    // pageSizeOptions: ['5', '10', '20'], // Optional: Available page size options
    showSizeChanger: false, // Optional: Show the page size changer
    showQuickJumper: false, // Optional: Show quick jump to page input
    onChange: (page, pageSize) => {
      setPage(page);

      // You can implement your custom logic here to fetch data for the selected page
      // For example, you can use the useGetUsersQuery hook with the new page number
    },
    onShowSizeChange: (current, size) => {
      // You can implement your custom logic here to fetch data with the new page size
      // For example, you can use the useGetUsersQuery hook with the new page size
    },
  };
  // User Table Data Stat
  const columns = [
    {
      title: "City Name",
      dataIndex: "city",
      key: "city",
    },
  ];

  if (isTechnologyEdit || isTechnologyDelete) {
    columns.push({
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          {/* {isTechnologyEdit && ( */}
          <a className="primary_color" onClick={() => OpenUser(record?._id)}>
            <EditOutlined />
          </a>
          {/* )} */}
          {isTechnologyDelete && (
            <a
              className="danger_color"
              onClick={() => showDeleteConfirm(record?._id)}
              type="dashed"
            >
              <DeleteOutlined />
            </a>
          )}
        </Space>
      ),
    });
  }
  // Reset Form
  const [form] = Form.useForm();
  const [Cityform] = Form.useForm();
  const onReset = () => {
    form.resetFields();
  };

  const [componentDisabled, setComponentDisabled] = useState(false);
  const onFormLayoutChange = ({ disabled }) => {
    setComponentDisabled(disabled);
  };
  const { Title, Text, Paragraph } = Typography;

  const [isCityModalOpen, setCityIsModalOpen] = useState(false);
  const showCityModal = () => {
    setCityIsModalOpen(true);
  };
  const handleCityOk = () => {
    setCityIsModalOpen(false);
    Cityform.resetFields();
  };
  const handleCityCancel = () => {
    setCityIsModalOpen(false);
    Cityform.resetFields();
    setSelectedId(null);
  };
  const [countries, setCountries] = useState({
    Pakistan: [],
    India: [],
    Australia: [],
  });
  const [selectedCountry, setSelectedCountry] = useState("Pakistan");

  const handleChange = (value) => {
    setSelectedCountry(value);
  };

  const onFinish = async (values) => {
    try {
      if (selectedId) {
        // Update city if selectedId exists
        const response = await updateCityMutation({
          id: selectedId,
          data: { city: values.city, country: values.select_country },
        });
        if (response.error) {
          console.error("Error updating city:", response.error);
          toast.error("City already exits");
        } else {
          toast.success("City updated successfully.");
          handleCityCancel(); // Close the modal after successful update
        }
      } else {
        // Create city if selectedId does not exist
        const formData = { city: values.city, country: values.select_country };
        const response = await createCryptoJobCity(formData);
        if (response.error) {
          toast.error(response.error.data.error);
        } else {
          toast.success("City created successfully.");
          handleCityOk(); // Close the modal after successful creation
        }
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An unexpected error occurred. Please try again later.");
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <Col xs={24} md={24} sm={24} lg={24} xl={24} className="mb-24">
            <Card bordered={false} className="criclebox mb-5">
              <Row gutter={[24, 0]}>
                <Col xs={24} md={24} sm={24} lg={24} xl={24} className="mb-24">
                  <div className="d-flex justify-content-end">
                    <div>
                      <Search
                        className="setInnerContent"
                        allowClear
                        placeholder="Input search text"
                        onSearch={onSearch}
                        enterButton
                        value={search}
                        onChange={(e) => setSearch(e.target.value)} // Update the 'search' state while typing
                      />
                      {/* {search && (
                        <button
                          style={{
                            position: "absolute",
                            top: "50%",
                            right: "228px",
                            transform: "translateY(-50%)",
                            background: "none",
                            border: "none",
                            cursor: "pointer",
                            fontSize: "12px",
                          }}
                          onClick={() => {
                            setSearch("");
                          }}
                        >
                          <FaTimes />
                        </button>
                      )} */}
                    </div>
                    {isTechnologyCreate && (
                      <div className="create_user_btn_div">
                        <Button
                          type="primary"
                          className="ms-3 px-3 rounded-5"
                          onClick={showCityModal}
                        >
                          Country With City
                        </Button>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
              <Row gutter={[24, 0]}>
                <Col xs={24} md={24} sm={24} lg={24} xl={24} className="mb-24">
                  <div className="">
                    <Table
                      loading={isLoading}
                      pagination={paginationOptions}
                      columns={columns}
                      dataSource={cityData?.addresses}
                    />
                  </div>
                </Col>
              </Row>
            </Card>
            <div>
              {/* Add country and city */}

              <Modal
                // title="Add City with Country"
                title={selectedId ? "Edit City" : "Create City"}
                open={isCityModalOpen}
                onOk={handleCityOk}
                onCancel={handleCityCancel}
                footer={null}
              >
                <Form
                  form={Cityform}
                  layout="vertical"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  // initialValues={{ select_country: selectedId }}
                >
                  <Row gutter={[24, 0]}>
                    <Col
                      xs={24}
                      md={24}
                      sm={24}
                      lg={24}
                      xl={24}
                      className="mobile-24"
                    >
                      <Form.Item
                        label="Select country"
                        name="select_country" // Corrected field name
                        className="mb-2"
                        rules={[
                          {
                            required: true,
                            message: "Please Select Country",
                          },
                        ]}
                      >
                        {/* <Select
                          defaultValue="Pakistan"
                          onChange={handleChange}
                          className=""
                          options={[
                            { value: "Pakistan", label: "Pakistan" },
                            { value: "India", label: "India" },
                            { value: "Australia", label: "Australia" },
                          ]}
                        /> */}
                        <Select
                          showSearch
                          className="mt-2"
                          // style={{ width: 200 }}
                          style={{ width: "100%", padding: 0, border: "1px" }}
                          notFoundContent={false}
                          placeholder="Select Country"
                          optionFilterProp="children"
                          onChange={handleChange}
                          // onBlur={handleBlur}
                          // value={values.country}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          <Option value={""}>Select Country</Option>
                          {countryData?.countries?.map((country) => (
                            <Option key={country?._id} value={country?._id}>
                              {country?.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col
                      xs={24}
                      md={24}
                      sm={24}
                      lg={24}
                      xl={24}
                      className="mobile-24"
                    >
                      <Form.Item
                        label="Add City"
                        name="city" // Corrected field name
                        rules={[
                          {
                            required: true,
                            message: "Enter City Name",
                          },
                        ]}
                      >
                        <Input placeholder="City Name" className="mt-2" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item>
                    <div className="d-flex justify-content-end mt-3">
                      <Button
                        key="back"
                        onClick={handleCityCancel}
                        style={{ marginRight: 8 }}
                      >
                        Cancel
                      </Button>
                      <Button key="submit" type="primary" htmlType="submit">
                        {/* Add */}
                        {selectedId ? "Update" : "Create"}
                      </Button>
                    </div>
                  </Form.Item>
                </Form>
              </Modal>
            </div>
            {/* User View Modal End */}
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Cryptocitywithcountry;
