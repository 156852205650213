import moment from "moment";
import { useSelector } from "react-redux";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";

const CommonMessageContent = ({
  msg,
  image,
  name,
  shouldShowReply,
  shouldImageDisplay,
  currentConvoUser,
}) => {
  // const dispatch = useDispatch();

  // const handleReplyClick = () => {
  //   dispatch(setReplyMessage(msg));
  // };
  console.log(currentConvoUser?.username, ">>Cheking in Message Content");
  return (
    <div className="message_content_div ">
      <div className="d-flex justify-content-between">
        <div
          className={`d-flex align-items-center ${
            shouldImageDisplay ? "pb-2" : ""
          }`}
        >
          <div className=" h-100 ">
            {shouldImageDisplay && (
              <img
                src={image}
                alt="user"
                className="img-fluid inbox_profile_pic "
              />
            )}
          </div>
          <div className="d-flex align-items-center ps-3 my-lg-0 my-md-0 my-4">
            <p className="inbox_user_name_profile mb-0 d-flex align-items-center">
              {name}
            </p>
            <p className="mb-0 ps-4 text-end inbox_time">
              {moment(msg?.createdAt).format("LT")}
            </p>
          </div>
        </div>
        {/* {shouldShowReply && (
          <div onClick={handleReplyClick}>
            <button className="reply_msg_btn">
              <FaReply className="me-2" />
              Reply
            </button>
          </div>
        )} */}
      </div>
    </div>
  );
};

const MessageHeader = ({
  msg,
  selectedConvoUser,
  shouldShowReply,
  buyerSelected,
  selectedConvo,
  currentConvoUser,
  shouldImageDisplay = true,
}) => {
  const user = useSelector((state) => state.user?.user);
  const personalProfile = useSelector(
    (state) => state?.sellerProfile?.becomeSellerProfile,
  );
  const { id: paramId } = useParams();
  const location = useLocation();
  // Parse the query string to get the value of the "slug" parameter
  const queryParams = new URLSearchParams(location.search);
  const member1 = queryParams.get("member1");
  let id;
  if (member1) {
    id = selectedConvoUser?._id;
  } else {
    id = paramId;
  }
  let image;
  let name;

  let buyerSellerChat = "userChat";
  if (member1) {
    buyerSellerChat = "disputeChat";
    const isChatWithUser = selectedConvo?.members?.find(
      (m) => m?._id === user?._id,
    );

    if (isChatWithUser) {
      buyerSellerChat = "disputChatWithUser";
    }
  }

  if (buyerSellerChat === "userChat") {
    if (msg?.senderId === id) {
      name = currentConvoUser?.username;
      image = currentConvoUser?.userProfile?.personalpicture;
    } else {
      name = selectedConvoUser?.username;
      image = selectedConvoUser?.userProfile?.personalpicture;
    }
  } else if (buyerSellerChat === "disputeChat") {
    if (msg?.senderId === id) {
      const userData = selectedConvo?.members?.find((m) => m?._id === id);
      name = userData?.username;
      image = userData?.userProfile?.personalpicture;
    } else {
      const userData = selectedConvo?.members?.find((m) => m?._id !== id);
      name = userData?.username;
      image = userData?.userProfile?.personalpicture;
    }
  } else if (buyerSellerChat === "disputChatWithUser") {
    if (msg?.senderId === user?._id) {
      name = "Me";
    } else {
      name = selectedConvoUser?.username;
    }
  }

  if (!image) {
    image =
      "https://work-as-pro.s3.us-east-2.amazonaws.com/staticPictures/image_2024_02_05T06_10_50_024Z.png";
  }

  return (
    <>
      <CommonMessageContent
        shouldImageDisplay={shouldImageDisplay}
        msg={msg}
        name={name}
        image={image}
        currentConvoUser={currentConvoUser}
        shouldShowReply={shouldShowReply}
      />
    </>
  );
};

export default MessageHeader;
