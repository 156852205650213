import { useReadUnreadNotificationMutation } from "../services/dashboard";

export const useReadNotification = () => {
  const [readNotification, { isLoading: isReadUnreadNotification }] =
    useReadUnreadNotificationMutation();

  const makeReadUnreadNotification = ({ item, isUnread }) => {
    if (!item?._id) return;

    readNotification({
      notificationId: item?._id,
      isUnread,
    });
  };

  return { makeReadUnreadNotification, isReadUnreadNotification };
};
