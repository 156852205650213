export const dashboardCards = {
  totalSellers: "Total Sellers",
  totalBuyers: "Total Buyers",
  totalGigs: "Total Gigs",
  totalJobs: "Total Jobs",
  totalInvestedInIdeaToken: "Total Invested In IDEA Token",
  totalBuyersInvested: "Total Buyers Invested",
  totalInvestedInOtherTokens: "Total Invested In Other Tokens",
  totalCancelOrders: "Total Cancel Orders",
  totalOrders: "Total Orders",
  totalActiveOrders: "Total Active Orders",
};
