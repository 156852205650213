import { endpoints } from "./endpoints";

const config = {
  api: {
    baseUrl: process.env.REACT_APP_BACKEND_URL,
    endpoints,
  },
  // other configuration details specific to your development environment
};

export default config;
