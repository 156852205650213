import React from "react";
import { useCryptoJobFormikContext } from "../../hooks/useFormikCryptoJobContext";

const DisplayFormSalary = () => {
  const { values, handleChange, handleBlur, displayError } =
    useCryptoJobFormikContext();

  return (
    <>
      {/* <div>
        <label className="create_crypto_jobs_labels">Screening Questions</label>
        <p className="create_crypo_screening_questions_p mb-2">
          We ask all candidates for their CV, cover letter message, GitHub,
          LinkedIn, phone number, current salary and location. Feel free to ask
          up to 3 more questions to help you screen applicants:
        </p>
      </div> */}
      <div>
        <div className="row">
          <label className="create_crypto_jobs_labels">Salary Range</label>
          <div className="col-lg-3 col-md-6 col-12">
            <input
              type="number"
              name="salaryMin"
              className="job_title_input_field"
              placeholder="Starting Salary"
              value={values.salaryMin}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {displayError("salaryMin")}
          </div>
          <div className="col-lg-3 col-md-6 col-12">
            <input
              type="number"
              name="salaryMax"
              className="job_title_input_field"
              placeholder="Ending Salary"
              value={values.salaryMax}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {displayError("salaryMax")}
          </div>
          <div className="col-lg-3 col-md-6 col-12">
            <select
              name="salaryCurrency"
              id="salaryCurrency"
              className="salry_range_select"
              value={values.salaryCurrency}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option value="">Select currency</option>
              <option value="USDT">USDT</option>
              <option value="IDEA">IDEA</option>
              <option value="DAI">DAI</option>
              <option value="BNB">BNB</option>
              <option value="BUSD">BUSD</option>
              <option value="MATIC">MATIC</option>
            </select>
            {displayError("salaryCurrency")}
          </div>
          <div className="col-lg-3 col-md-6 col-12">
            <select
              name="salaryFrequency"
              id="salaryFrequency"
              className="salry_range_select"
              value={values.salaryFrequency}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option value="">Select frequency</option>
              <option value="yearly">YEARLY</option>
              <option value="monthly">MONTHLY</option>
              <option value="weekly">WEEKLY</option>
              <option value="daily">DAILY</option>
              <option value="hourly">HOURLY</option>
            </select>
            {displayError("salaryFrequency")}
          </div>
          {/* <p className="looking_for_web3_services_p pt-2 mb-0">
            Are you overpaying or underpaying?
          </p> */}
        </div>
      </div>
    </>
  );
};

export default DisplayFormSalary;
