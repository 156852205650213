import DisplayExpandableText from "./DisplayExpendableText";
import DisplayFiles from "./DisplayFiles";
import DisplayOffer from "./DisplayOffer";
import DisplayReply from "./DisplayReply";
import MessageHeader from "./MessageHeader";

const TextMessage = ({
  msg,
  selectedConvo,
  setActivePurposal,
  shouldShowReply,
  selectedConvoUser,
  currentConvoUser,
  buyerSelected,
}) => {
  // const user = useSelector((state) => state.user);
  // const goToReplyMessage = useSelector(selectGoToReplyMessage);
  // const shouldHighlight = goToReplyMessage && goToReplyMessage === msg?._id;

  console.log("@displayReply  ss=> ", {
    selectedConvoUser,
    currentConvoUser,
    selectedConvo,
  });

  return (
    <div className="col-12 pe-2 p-0">
      <div
        id={msg?._id}
        className={`message_by_others`}
        // style={{ backgroundColor: shouldHighlight ? "#FFEBE5" : "" }}
      >
        <MessageHeader
          buyerSelected={buyerSelected}
          currentConvoUser={currentConvoUser}
          msg={msg}
          selectedConvo={selectedConvo}
          selectedConvoUser={selectedConvoUser}
          shouldShowReply={shouldShowReply}
        />
        <DisplayReply
          currentConvoUser={currentConvoUser}
          selectedConvoUser={selectedConvoUser}
          shouldShowReply={shouldShowReply}
          selectedConvo={selectedConvo}
          setActivePurposal={setActivePurposal}
          msg={msg}
        />
        <DisplayExpandableText msg={msg} />
        <DisplayFiles msg={msg} shouldShowReply={shouldShowReply} />
        <DisplayOffer
          msg={msg}
          setActivePurposal={setActivePurposal}
          selectedConvo={selectedConvo}
        />
      </div>
    </div>
  );
};
export default TextMessage;
