import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export let fakeSocketInstance;

const useSocketInstance = () => {
  const [socket, setSocket] = useState(null);
  const token = useSelector((state) => state?.user?.user?.token);
  console.log({ token, socket });

  useEffect(() => {
    if (token) {
      import("../utils/socket")
        .then((socket) => {
          socket.initializeSocket(token, setSocket, fakeSocketInstance);
        })
        .catch((error) => {
          console.log("error in socket instance=> ", error);
          setSocket(null);
        });
    }
  }, [token]);

  return socket;
};

export default useSocketInstance;
