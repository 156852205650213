import ApiBase from "../app/ApiBase";
import config from "../config/development";
import { objectToQueryString } from "../utils/url";
export const categoryApi = ApiBase.enhanceEndpoints({
  addTagTypes: ["Category"],
}).injectEndpoints({
  endpoints: (builder) => ({
    getCategories: builder.query({
      query: (query) =>
        config.api.endpoints.getCategories(objectToQueryString(query)),
      transformResponse: (response) => {
        // handle successful response and return data to store
        console.log("response: ", response);
        return response;
      },
      providesTags: ["getCategories"],
    }),
    getSubcategories: builder.query({
      query: (query) =>
        config.api.endpoints.getSubcategories(objectToQueryString(query)),
      transformResponse: (response) => {
        // handle successful response and return data to store
        console.log("response: ", response);
        return response;
      },
      providesTags: ["getSubcategories"],
    }),
    getCategoriesWithSubcategories: builder.query({
      query: (query) =>
        config.api.endpoints.getCategoriesWithSubcategories(
          objectToQueryString(query),
        ),
      transformResponse: (response) => {
        // handle successful response and return data to store
        console.log("response: ", response);

        let realTimeData = response?.categories;
        // Merging all subcategories into one array
        const subcategoriesArray = realTimeData.reduce((acc, item) => {
          return acc.concat(item.category.subcategories);
        }, []);

        // Creating a new array with all categories without subcategories field
        const categoriesArray = realTimeData.map((item) => {
          const { subcategories, ...categoryWithoutSubcategories } =
            item.category;
          return categoryWithoutSubcategories;
        });

        // Creating the final transformed object
        const transformedData = {
          subcategories: subcategoriesArray,
          categories: categoriesArray,
        };
        console.log("transformedResponse : ", transformedData);

        return transformedData;
      },
      providesTags: ["getCategoriesWithSubcategories"],
    }),
    createCategory: builder.mutation({
      query: (formData) => ({
        url: config.api.endpoints.createCategory,
        method: "POST",
        body: formData, // The data is already a FormData object containing the multipart data
        headers: {
          // 'Content-Type': 'multipart/form-data', // Set the Content-Type header directly here
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100,
          );
          console.log(`Upload progress: ${progress}%`);
        },
      }),

      transformResponse: (response) => {
        // handle successful response and return data to store
        console.log("response: ", response);
        return response;
      },
      invalidatesTags: ["getCategories", "getSubcategories"],
    }),
    deleteCategory: builder.mutation({
      query: (id) => ({
        url: config.api.endpoints.deleteCategory(id),
        method: "DELETE",
      }),
      transformResponse: (response) => {
        // handle successful response and return data to store

        console.log("response: ", response);
        return response;
      },
      invalidatesTags: ["getCategories", "getSubcategories"],
    }),
    updateCategory: builder.mutation({
      query: ({ body, id }) => ({
        url: config.api.endpoints.updateCategory(id),
        method: "PUT",
        body,
      }),
      transformResponse: (response) => {
        // handle successful response and return data to store

        console.log("response: ", response);
        return response;
      },
      invalidatesTags: ["getCategories", "getSubcategories"],
    }),
  }),
});

// Generate hooks for each endpoint
export const {
  useGetCategoriesQuery,
  useGetCategoriesWithSubcategoriesQuery,
  useGetSubcategoriesQuery,
  useCreateCategoryMutation,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
} = categoryApi;
