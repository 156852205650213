import React from "react";
import { FaTwitter } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import ReactImageUploading from "react-images-uploading";
import imageUploaderImg from "../../assets/images/white-image.png";
import { useCryptoJobFormikContext } from "../../hooks/useFormikCryptoJobContext";

const DisplayFormCompany = () => {
  const { values, handleChange, handleBlur, setFieldValue, displayError } =
    useCryptoJobFormikContext();
  const maxNumber = 1;

  const onChange = (imageList, addUpdateIndex) => {
    setFieldValue("companyLogo", imageList);
  };
  return (
    <div className="row">
      <h3 className="company_details_h3 pt-lg-4 pt-md-3 pt-3">
        Company Details
      </h3>
      <div className="col-lg-6 col-md-6 col-12">
        <div className="mb-3">
          <label className="create_crypto_jobs_labels">Webiste</label>
          <input
            type="text"
            name="companyWebsite"
            className="job_title_input_field"
            placeholder="https://company.com"
            value={values.companyWebsite}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {displayError("companyWebsite")}
        </div>
        <div className="mb-3">
          <label className="create_crypto_jobs_labels">
            <FaTwitter className="crypto_job_twitter_logo_color me-1" /> Twitter
          </label>
          <input
            type="text"
            name="companyTwitter"
            className="job_title_input_field"
            placeholder="@twitter"
            value={values.companyTwitter}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {displayError("companyTwitter")}
        </div>
        {/* <div className="mb-3">
          <label className="create_crypto_jobs_labels d-flex align-items-center h-100">
            <BsDiscord className="crypto_job_discord_logo_color me-2" /> Discord
          </label>
          <input
            type="text"
            name="companyDiscord"
            className="job_title_input_field"
            placeholder="https://discord.gg/company123"
            value={values.companyDiscord}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {displayError("companyDiscord")}
        </div> */}
      </div>
      <div className="col-lg-6 col-md-6 col-12">
        <div className="d-flex justify-content-center">
          <ReactImageUploading
            multiple
            value={values.companyLogo}
            onChange={onChange}
            onBlur={handleBlur}
            maxNumber={maxNumber}
            dataURLKey="data_url"
          >
            {({ imageList, onImageUpload, onImageRemove, dragProps }) => (
              <div className="upload__image-wrapper">
                <div className="img_uploader_img">
                  <div className="text-center">
                    <p className="mb-1 create_job_logo_heading">
                      Logo{" "}
                      {imageList.length === 0 ? (
                        ""
                      ) : (
                        <RxCross2
                          fontSize={14}
                          onClick={() => onImageRemove()}
                          className="rx_cross_crypto_img"
                        />
                      )}
                    </p>
                  </div>
                  {imageList.length === 0 ? (
                    <img
                      src={imageUploaderImg}
                      onClick={onImageUpload}
                      {...dragProps}
                      className="img-fluid crypto_job_images_border"
                    />
                  ) : (
                    <div>
                      {imageList.map((image, index) => (
                        <div key={index} className="image-item">
                          <img
                            src={image["data_url"]}
                            alt=""
                            className="img_uploader_img crypto_job_images_border"
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            )}
          </ReactImageUploading>
        </div>
        <div className="mt-4 text-center">{displayError("companyLogo")}</div>
      </div>
    </div>
  );
};

export default DisplayFormCompany;
