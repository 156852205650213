import React, { useEffect } from "react";

import { useParams } from "react-router";

import moment from "moment";
// import { ConversationStatuses } from "../../../confing/ConversationStatuses";
const Pendingconversations = ({
  setBuyerSelected,
  conversation,
  currentUser,
  active,
  userDetail,
  updateConversationStatus,
  updateMessage,
  selectedConvoStatus,
  setSelectedConvo,
  name,
}) => {
  const navigate = {};

  useEffect(() => {}, []);
  const { id: username } = useParams();

  const findConvStatus = () =>
    conversation?.status?.find((item) => item.memberId == currentUser);

  const handleUnread = (e) => {
    const message = conversation?.lastMessage;
    if (message?.status === "unread") {
      updateMessage(message, "read", conversation);
    }
    if (findConvStatus()?.unread) {
      // updateConversationStatus(ConversationStatuses.UNREAD, conversation);
    }
  };

  const fetchUsername = () =>
    conversation?.members?.find((m) => m._id !== currentUser)?.username;

  const handleUnreadIcon = async (e) => {
    e.stopPropagation();

    if (!findConvStatus()?.unread && username === fetchUsername()) {
      navigate("/inbox");
    } else {
      if (!username) {
        navigate(`/inbox/${fetchUsername()}`);
      }
    }

    // updateConversationStatus(ConversationStatuses.UNREAD, conversation);
  };

  const handleStared = (e) => {
    e.stopPropagation();
    // updateConversationStatus(ConversationStatuses.STARRED, conversation);
  };

  useEffect(() => {
    const formatRelativeTime = (number, withoutSuffix, key, isFuture) => {
      console.log({ number, withoutSuffix, key, isFuture });
      const weeks = Math.floor(number / 7);

      if (number < 7) return number + " d";

      if (number < 14 && number > 6) {
        return isFuture ? "in 1 w" : "1 w";
      } else if (number > 13) {
        return withoutSuffix ? `${weeks} w` : `${weeks} w ago`;
      }
    };
    moment.updateLocale("en", {
      relativeTime: {
        future: "in %sec",
        past: "%sec ago",
        s: "few sec ago",
        ss: "%d s",
        m: "1 min",
        mm: "%d min",
        h: "1 h",
        hh: "%d h",
        d: "1 d",
        dd: formatRelativeTime,
        w: "1 w",
        ww: "%d w",
        M: "1 mon",
        MM: "%d mon",
        y: "1 y",
        yy: "%d y",
      },
    });
  }, []);

  const getLastMessage = () => {
    let message = "";

    if (conversation?.lastMessage) {
      if (conversation.lastMessage.type === "text") {
        message = conversation.lastMessage.message;
      } else if (
        conversation.lastMessage.type === "offer" &&
        conversation.lastMessage.senderId !== userDetail?._id
      ) {
        message = "An offer";
      } else if (
        conversation.lastMessage.type === "offerUpdate" &&
        conversation.lastMessage.senderId !== userDetail?._id
      ) {
        message = "Offer update";
      } else if (
        conversation.lastMessage.type === "offerUpdate" &&
        conversation.lastMessage.senderId === userDetail?._id
      ) {
        message = "Offer update";
      } else if (
        conversation.lastMessage.type === "offer" &&
        conversation.lastMessage.senderId === userDetail?._id
      ) {
        message = "An offer";
      } else if (conversation.lastMessage.senderId !== userDetail?._id) {
        message = "An attachment";
      } else if (conversation.lastMessage.senderId === userDetail?._id) {
        message = "An attachment";
      }
    }

    return message;
  };

  return (
    <>
      <div
        style={{ cursor: "pointer" }}
        className={`row userBox py-2 mt-2 p-1 ms-1 me-1 ${
          conversation?._id === active?._id ? "activeInbox" : ""
        }`}
        onClick={(e) => {
          // navigate(`/inbox/${fetchUsername()}`, {
          //   state: { id: null },
          //   replace: true,
          // });
          // SelectConvo(conversation);
          // handleUnread(e);
          setSelectedConvo(conversation);
          if (name) setBuyerSelected(true);
          else {
            setBuyerSelected(false);
          }
        }}
      >
        <div className="col-12 d-flex align-items-center h-100 justify-content-between p-0">
          <div className="d-flex align-items-center">
            <div className="position-relative h-100">
              <img
                src={
                  userDetail?.userProfile?.personalpicture
                    ? userDetail?.userProfile?.personalpicture
                    : "https://work-as-pro.s3.us-east-2.amazonaws.com/staticPictures/image_2024_02_05T06_10_50_024Z.png"
                }
                alt={userDetail?.username}
                className="img-fluid inbox_profile_pic "
              />
              <span
                className={`position-absolute ${
                  userDetail?.onlineStatus === "online"
                    ? "inbox_online_dot"
                    : "inbox_offline_dot"
                } translate-middle p-1 rounded-circle`}
              >
                <span className="visually-hidden">Online</span>
              </span>
            </div>

            <div className="  ps-2 my-lg-0 my-md-0 my-4">
              <div className="">
                <div>
                  <p className="inbox_user_name mb-0 pe-1">
                    {" "}
                    {name || userDetail?.username}
                  </p>
                </div>

                <div className="adjustment_inbox_user_text">
                  <span className="mb-0 inbox_user_text adjustment_inbox_user_text ">
                    {getLastMessage()}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <div className="d-flex justify-content-center align-items-center h-100 pt-2 pb-2">
              <div className="text-center">
                {/* {findConvStatus()?.starred ? (
                  <i
                    onClick={handleStared}
                    className="fas fa-star profile_star px-1"
                  ></i>
                ) : (
                  <i
                    onClick={handleStared}
                    className="fa-regular fa-star profile_star px-1"
                  ></i>
                )}
                {findConvStatus()?.unread ? (
                  <BsEnvelopeFill
                    className="pb-1 fs-5 inboxIcon"
                    onClick={handleUnreadIcon}
                  />
                ) : (
                  <BsEnvelopeOpen
                    className="pb-1 fs-5 inboxIcon"
                    onClick={handleUnreadIcon}
                  />
                )} */}
                <div>
                  {conversation?.lastMessage && (
                    <p className="inbox_user_text text-center mb-0">
                      {" "}
                      {moment(conversation?.lastMessage?.createdAt).fromNow(
                        true,
                      )}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pendingconversations;
