import "antd/dist/antd.css";

import { useSelector } from "react-redux";

import { Toaster } from "react-hot-toast";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import ProtectedRoute from "./HOC/ProtectedRoute";
import "./assets/styles/Variables.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import Main from "./components/layout/Main";
import "./index.css";
import Blogs from "./pages/Blogs";
import Category from "./pages/Categoory";
import Dispute from "./pages/Dispute";
import General_Setting from "./pages/General_Setting";
import Gig_Detail from "./pages/Gig_Detail";
import Home from "./pages/Home";
import Job_Detail from "./pages/Job_Detail";
import Listedblogs from "./pages/Listedblogs";
import Order_Detail from "./pages/Order_Detail";
import Pendingdisputes from "./pages/Pendingdisputes";
import Profile from "./pages/Profile";
import SignIn from "./pages/SignIn";
import SubAdmin from "./pages/SubAdmin";
import Subcategory from "./pages/Subcategory";
import TopBuyers from "./pages/TopBuyers";
import TopSellers from "./pages/TopSellers";
import Transactions from "./pages/Transactions";
import Subscribers from "./pages/subscribers";
import Technology from "./pages/technology";
import Users_Index from "./pages/users";

import { selectUser } from "./slices/user";

// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { SocketProvider } from "./hooks/socketContext";
import Addcryptocountry from "./pages/Addcryptocountry";
import Applicants from "./pages/Applicants";
import Blogpreview from "./pages/Blogpreview";
import BuyerChat from "./pages/BuyerChat";
import Createcryptojob from "./pages/Createcryptojob";
import Cryptocitywithcountry from "./pages/Cryptocitywithcountry";
import Cryptotags from "./pages/Cryptotags";
import Draftblogs from "./pages/Draftblogs";
import Listedcryptojobs from "./pages/Listedcryptojobs";
import NotificationsPage from "./pages/NotificationsPage";
import PersonalChat from "./pages/PersonalChat";
import { useGetUserQuery } from "./services/auth";
import { hasPermission } from "./utils/permissions";

export const protectedRoutesItems = [
  {
    key: "/dashboard",
    permission: "dashboard:view",
    Component: Home,
    permissionKey: "dashboard",
  },
  {
    key: "/users",
    permission: "users:view",
    Component: Users_Index,
    permissionKey: "users",
  },
  {
    key: "/cryptotags",
    permission: "crypto:view",
    Component: Cryptotags,
    permissionKey: "users",
  },
  {
    key: "/crypto-add-country",
    permission: "crypto:view",
    Component: Cryptocitywithcountry,
    permissionKey: "users",
  },
  {
    key: "/createcryptojobs",
    permission: "crypto:view",
    Component: Createcryptojob,
    permissionKey: "users",
  },
  {
    key: "/edit_crypto_jobs/:id",
    permission: "crypto:view",
    Component: Createcryptojob,
    permissionKey: "users",
  },
  {
    key: "/addcrytocountry",
    permission: "crypto:view",
    Component: Addcryptocountry,
    permissionKey: "users",
  },
  {
    key: "/listedcryptojobs",
    permission: "crypto:view",
    Component: Listedcryptojobs,
    permissionKey: "users",
  },
  {
    key: "/applicants/:cryptoJobId",
    permission: "crypto:view",
    Component: Applicants,
    permissionKey: "users",
  },
  {
    key: "/gig_detail/:id",
    permission: "users:view",
    Component: Gig_Detail,
  },
  {
    key: "/job_detail/:id",
    permission: "users:view",
    Component: Job_Detail,
  },
  {
    key: "/order_detail/:id",
    permission: "users:view",
    Component: Order_Detail,
  },
  {
    key: "/profile/:username",
    permission: "users:view",
    Component: Profile,
  },
  {
    key: "/disputes/chat",
    permission: "manageDisputes:view",
    Component: BuyerChat,
  },
  {
    key: "/profile/chat/:username",
    permission: "users:view",
    Component: Profile,
  },
  {
    key: "/personal_chat/:id",
    permission: "users:view",
    Component: PersonalChat,
  },
  {
    key: "/disputes",
    permission: "manageDisputes:view",
    Component: Dispute,
    permissionKey: "manageDisputes",
  },
  {
    key: "/pending_dispute",
    permission: "manageDisputes:view",
    Component: Pendingdisputes,
    permissionKey: "manageDisputes",
  },
  {
    key: "/transactions",
    permission: "manageDisputes:view",
    Component: Transactions,
    permissionKey: "manageDisputes",
  },
  {
    key: "/category",
    permission: "manageCategories:view",
    Component: Category,
    permissionKey: "manageCategories",
  },
  {
    key: "/subcategory",
    permission: "manageCategories:view",
    Component: Subcategory,
    permissionKey: "manageCategories",
  },
  {
    key: "/subadmin",
    permission: "subadmin:view",
    Component: SubAdmin,
    permissionKey: "subadmin",
  },
  {
    key: "/topbuyers",
    permission: "users:view",
    Component: TopBuyers,
    permissionKey: "users",
  },
  {
    key: "/topsellers",
    permission: "users:view",
    Component: TopSellers,
    permissionKey: "users",
  },
  {
    key: "/subscribers",
    permission: "subscribers:view",
    Component: Subscribers,
    permissionKey: "subscribers",
  },
  {
    key: "/technologies",
    permission: "technologies:view",
    Component: Technology,
    permissionKey: "technologies",
  },
  {
    key: "/listed_blogs",
    permission: "blogs:view",
    Component: Listedblogs,
    permissionKey: "blogs",
  },
  {
    key: "/draft_blogs",
    // permission: "blogs:view",
    permission: "blogs:create",

    Component: Draftblogs,
    permissionKey: "blogs",
  },
  {
    key: "/blog-preview",
    permission: "blogs:view",
    Component: Blogpreview,
    permissionKey: "blogs",
  },
  {
    key: "/add_new_blog",
    permission: "blogs:create",
    Component: Blogs,
  },
  {
    key: "/setting",
    permission: "settings:view",
    Component: General_Setting,
    permissionKey: "settings",
  },
  {
    key: "/notifications",
    permission: "settings:view",
    Component: NotificationsPage,
    permissionKey: "settings",
  },

  // Add other menu items here
];

export const routes = protectedRoutesItems
  .map((p) => {
    return {
      path: p.key,
      element: (
        <ProtectedRoute
          exact
          path={p.key}
          component={p.Component}
          requiredPermissions={[p.permission]}
        />
      ),
    };
  })
  .push({ path: "/signin", element: <SignIn /> });

function App() {
  const user = useSelector(selectUser);
  const isAuthenticated = !!user;
  useGetUserQuery();

  const renderMenuItem = (item) => {
    if (item.permission && !hasPermission([item.permission])) {
      if (!isAuthenticated) {
        return <Redirect to="/signin" />;
      }
    }

    return (
      <ProtectedRoute
        exact
        path={item.key}
        component={item.Component}
        requiredPermissions={[item.permission]}
      />
    );
  };

  // Check for invalid routes and redirect to dashboard
  const handleInvalidRoute = () => {
    const validRouteKeys = protectedRoutesItems.map(
      (item) => item.key.replace(/:\w+/g, "*"), // Replace params with *
    );

    const currentPath = window?.location?.pathname
      ? window?.location?.pathname
      : null;

    // Check if any of the valid route keys match the current path
    const isValidRoute = validRouteKeys.some((validRoute) => {
      const regex = new RegExp(`^${validRoute.replace(/\*/g, "\\w+")}$`);
      return regex.test(currentPath);
    });

    if (currentPath && !isValidRoute) {
      return <Redirect to="/dashboard" />;
    }

    return null;
  };

  return (
    <SocketProvider>
      <div className="App">
        <Toaster position="top-center" reverseOrder={false} />
        <Router>
          <Switch>
            <Route path="/signin" exact component={SignIn} />
            <Main>
              {/* Handle invalid routes */}
              <Route render={handleInvalidRoute} />
              {protectedRoutesItems.map((item) => renderMenuItem(item))}
              <Route
                exact
                path="/"
                render={() => {
                  if (isAuthenticated) {
                    return <Redirect to="/dashboard" />;
                  } else {
                    return <Redirect to="/signin" />;
                  }
                }}
              />
            </Main>
          </Switch>
        </Router>
      </div>
    </SocketProvider>
  );
}

export default App;
