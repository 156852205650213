import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from "redux-persist";
import { encryptTransform } from "redux-persist-transform-encrypt";
import storage from "redux-persist/lib/storage";
import ApiBase from "../app/ApiBase";
import userSlice from "../slices/user";
import walletSlice from "../slices/wallet";

const rootReducer = combineReducers({
  user: userSlice,
  wallet: walletSlice,
  [ApiBase.reducerPath]: ApiBase.reducer,
});

const secretKey =
  process.env.REACT_APP_SECRET_KEY ||
  "my-super-secret-key-which-is-very-long-so-that-it-will-be-hard-for-anyone-to-guess-it";

const persistConfig = {
  key: "wap-admin-panel",
  version: 1,
  storage,
  whitelist: ["user", "wallet"],
  transforms: [
    encryptTransform({
      secretKey,
      onError: (error) => {
        console.error(error);
      },
    }),
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(ApiBase.middleware),
  devTools: process.env.NODE_ENV !== "production",
});

export default store;

export const persistor = persistStore(store);
