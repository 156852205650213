import ApiBase from "../app/ApiBase";
import config from "../config/development";

export const cryptoCompanyApi = ApiBase.injectEndpoints({
  endpoints: (builder) => ({
    getCompaniesByName: builder.query({
      query: (name) => config.api.endpoints.getCryptoCompaniesByName(name),
      transformResponse: (response) => {
        // handle successful response and return data to store
        console.log("response: ", response);
        return response;
      },
      keepUnusedDataFor: 1000,
    }),
    createCryptoCompany: builder.mutation({
      query: (formData) => ({
        url: config.api.endpoints.createCryptoCompany,
        method: "POST",
        body: formData,
      }),
      transformResponse: (response) => {
        // handle successful response and return formData to store

        // console.log("response: ", response);
        return response;
      },
    }),
  }),
});

// Generate hooks for each endpoint
export const { useCreateCryptoCompanyMutation, useGetCompaniesByNameQuery } =
  cryptoCompanyApi;
