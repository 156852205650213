import { Form, Pagination, Select } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import toast from "react-hot-toast";
import { IoPersonCircleOutline } from "react-icons/io5";
import { ReadUnreadNotification } from "../components/notification/readNotification";
import { useReadNotification } from "../hooks/useUnreadNotification";
import {
  useDeleteNotificationsMutation,
  useGetNotificationsQuery,
  useReadUnreadNotificationMutation,
} from "../services/dashboard";

const notification = [
  {
    content:
      "A recent sign-in to your WorkAsPro account (5d106d25) from an unknown device or browser.",
    time: "12: 07 pm",
  },
  {
    content:
      "A recent sign-in to your WorkAsPro account (5d106d25) from an unknown device or browser.",
    time: "12: 08 pm",
  },
  {
    content:
      "A recent sign-in to your WorkAsPro account (5d106d25) from an unknown device or browser.",
    time: "12: 09 pm",
  },
];

const NotificationsPage = () => {
  const [selectKey, setSelectKey] = useState(Date.now()); // Key for re-render
  const [page, setPage] = useState(1);
  const [searchType, setSearchType] = useState("");
  const [isUnread, setIsUnread] = useState(null);
  const { makeReadUnreadNotification } = useReadNotification();

  const { isLoading, data, isError, error, refetch } = useGetNotificationsQuery(
    {
      page,
      recentCount: 5,
      limit: 5,
      type: searchType,
      isUnread,
    },
  );
  const [deleteNotification, { isLoading: isDeleteLoading }] =
    useDeleteNotificationsMutation();
  const [readNotification, { isLoading: isReadUnreadNotification }] =
    useReadUnreadNotificationMutation();
  const earlierNotifications = data?.notifications || [];
  console.log("earlierNotifications=>", earlierNotifications);

  const todayNotifications = data?.mostRecent || [];

  const paginationOptions = {
    total: data?.totalNotifications || 0, // Set the total number of items
    current: page, // Set the current page number
    pageSize: 5, // Set the number of items per page
    // pageSizeOptions: ['5', '10', '20'], // Optional: Available page size options
    showSizeChanger: false, // Optional: Show the page size changer
    showQuickJumper: false, // Optional: Show quick jump to page input
    onChange: (page, pageSize) => {
      setPage(page);
      // console.log("page => ", page);
      // You can implement your custom logic here to fetch data for the selected page
      // For example, you can use the useGetUsersQuery hook with the new page number
    },
    onShowSizeChange: (current, size) => {
      // You can implement your custom logic here to fetch data with the new page size
      // For example, you can use the useGetUsersQuery hook with the new page size
    },
  };

  const deleteNotificationById = (id) => {
    const promise = deleteNotification(id);
    toast.promise(promise, {
      success: "notification deleted",
      error: "error deleting notification",
      loading: "notification is deleting...",
    });
  };

  const history = useHistory();

  const navigateTypeBased = (type, item) => {
    makeReadUnreadNotification({ item, isUnread: false });
    switch (type) {
      case "User":
        // Navigate to user-related page
        history.push(`/profile/${item.user.username}`);

        break;
      case "Gig":
        // Navigate to gig-related page
        history.push(`/gig_detail/${item.gig._id}`);
        // /gig_detail/6528d650670a6c58a13702f6
        break;
      case "Job":
        // Navigate to job-related page
        history.push(`/job_detail/${item.job._id}`);

        break;
      case "new_order":
        // Navigate to new order-related page
        history.push(`/order_detail/${item.order._id}`);

        break;
      default:
        // Handle other types or invalid types
        console.log("Invalid type or no navigation defined for this type");
    }
  };
  console.log(data?.notifications);
  return (
    <div className="container">
      <div className="d-flex justify-content-end ps-3">
        <div className="me-lg-3 me-4" id="select_user_type">
          <Form.Item
            className="mb-3"
            // label="User Type"
            name="User type"
          >
            <Select
              key={selectKey}
              defaultValue={searchType}
              style={{
                maxWidth: "200px",
                minWidth: "200px",
                fontSize: "1em",
              }}
              value={searchType}
              onChange={(value) => {
                setPage(1);
                if (value === "read") {
                  setIsUnread(false);
                } else if (value === "unread") {
                  setIsUnread(true);
                } else {
                  setIsUnread(null);
                }
                // history.push(`/users/${value?.toLowerCase()}`);
              }}
            >
              <Select.Option value="" className="all_text_fontsize">
                Select Notifications
              </Select.Option>
              <Select.Option value="read" className="all_text_fontsize">
                All Read Notifications
              </Select.Option>
              <Select.Option value="unread" className="all_text_fontsize">
                All Unread Notifications
              </Select.Option>
            </Select>
          </Form.Item>
        </div>
        <div className="me-lg-3 me-4" id="select_user_type">
          <Form.Item
            className="mb-3"
            // label="User Type"
            name="User type"
          >
            <Select
              key={selectKey}
              defaultValue={searchType}
              style={{
                maxWidth: "200px",
                minWidth: "200px",
                fontSize: "1em",
              }}
              value={searchType}
              onChange={(value) => {
                setPage(1);
                setSearchType(value);
                // history.push(`/users/${value?.toLowerCase()}`);
              }}
            >
              <Select.Option value="" className="all_text_fontsize">
                Select Type
              </Select.Option>
              <Select.Option value="User" className="all_text_fontsize">
                Users
              </Select.Option>
              <Select.Option value="Gig" className="all_text_fontsize">
                Gigs
              </Select.Option>
              <Select.Option value="Job" className="all_text_fontsize">
                Jobs
              </Select.Option>
              <Select.Option value="new_order" className="all_text_fontsize">
                Orders
              </Select.Option>
            </Select>
          </Form.Item>
        </div>
      </div>
      <div className="px-3">
        <div className="row">
          <div className="col-12">
            <div className="pb-lg-4 pb-md-4 pb-3">
              <h5 className="notification_page_most_recent mb-4">
                Most Recent
              </h5>
            </div>
            <div className="pb-4">
              {todayNotifications?.length > 0 ? (
                <>
                  {todayNotifications &&
                    todayNotifications?.map((item, index) => (
                      <div
                        onClick={() => {
                          navigateTypeBased(item?.type, item);
                        }}
                        className="row align-items-center pb-4"
                        key={index}
                        style={{ cursor: "pointer" }}
                      >
                        <div className="col-1">
                          <div>
                            <IoPersonCircleOutline
                              fontSize={30}
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title="Account"
                            />
                          </div>
                        </div>
                        <div className="col-10">
                          <p className="notification_page_content_p mb-2">
                            {item?.title}
                            {` `}(
                            {item.type === "User" ? (
                              <strong>{item.user.username}</strong>
                            ) : (
                              ""
                            )}
                            {item.type === "Gig" ? (
                              <strong>{item?.gig?.gigtitle}</strong>
                            ) : (
                              ""
                            )}
                            {item?.type === "Job" ? (
                              <strong>{item?.job?.jobtitle}</strong>
                            ) : (
                              ""
                            )}
                            {item?.type === "new_order" ? (
                              <strong>
                                Order#:{item?.order?.uniqueOrderId}
                              </strong>
                            ) : (
                              ""
                            )}
                            )
                          </p>
                          <p className="notification_page_content_time mb-0">
                            {moment(item?.createdAt).format("MMM DD, YYYY") +
                              " : " +
                              moment(item?.createdAt).fromNow(true)}{" "}
                            ago
                          </p>
                        </div>
                        {/* <div className="col-1">
                          <RxCross1
                          onClick={()=> deleteNotificationById(item?._id)}
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Remove Notification"
                            fontSize={25}
                            className="notication_cross_color"
                          />
                        </div> */}
                        <ReadUnreadNotification item={item} />
                      </div>
                    ))}
                </>
              ) : (
                <p className="notification_page_content_time">
                  There are no notification for you at the moment.
                </p>
              )}
            </div>
            <div className="pt-lg-4 pt-md-3 pt-3 pb-lg-4 pb-md-4 pb-3">
              <h5 className="notification_page_most_recent mb-4">Earlier</h5>
            </div>
            <div className="pb-4">
              {earlierNotifications?.map((item, index) => (
                <div
                  className="row align-items-center pb-4"
                  onClick={() => {
                    navigateTypeBased(item?.type, item);
                  }}
                  key={index}
                  style={{ cursor: "pointer" }}
                >
                  <div className="col-1">
                    <div>
                      <IoPersonCircleOutline
                        fontSize={30}
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Account"
                      />
                    </div>
                  </div>
                  <div className="col-10">
                    <p className="notification_page_content_p mb-2">
                      {item?.title}
                      {` `}(
                      {item?.type === "User" ? (
                        <strong>{item?.user?.username}</strong>
                      ) : (
                        ""
                      )}
                      {item?.type === "Gig" ? (
                        <strong>{item?.gig?.gigtitle}</strong>
                      ) : (
                        ""
                      )}
                      {item?.type === "Job" ? (
                        <strong>{item?.job?.jobtitle}</strong>
                      ) : (
                        ""
                      )}
                      {item?.type === "new_order" ? (
                        <strong>Order#: {item?.order?.uniqueOrderId}</strong>
                      ) : (
                        ""
                      )}
                      )
                    </p>
                    <p className="notification_page_content_time mb-0">
                      {moment(item?.createdAt).format("MMM DD, YYYY") +
                        " : " +
                        moment(item?.createdAt).fromNow(true)}{" "}
                      ago
                    </p>
                  </div>
                  {/* <div className="col-1">
                    <RxCross1
                    onClick={()=> deleteNotificationById(item?._id)}
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="Remove Notication"
                      fontSize={25}
                      className="notication_cross_color"
                    />
                  </div> */}
                  <ReadUnreadNotification item={item} />
                </div>
              ))}
            </div>

            <div className="text-right">
              <Pagination {...paginationOptions} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationsPage;
