import { Select } from "antd";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
// import imageUploaderImg from "../../../assets/images/white-image.png";
// import imageUploaderImgCircle from "../../../assets/images/white-img-circle.png";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useCryptoJobFormikContext } from "../../hooks/useFormikCryptoJobContext";
import { useGetCompaniesByNameQuery } from "../../services/cryptoCompany";
import { useGetCryptoJobByIdQuery } from "../../services/cryptoJob";
import {
  JOB_TYPE_ENUM,
  updateFormikValuesForEdit,
} from "../../utils/cryptoJobFormik";
import DisplayAddress from "./DisplayAddress";
import DisplayFormCompany from "./DisplayFormCompany";
import DisplayFormSalary from "./DisplayFormSalary";
import DisplayFormTags from "./DisplayFormTags";

const { Option } = Select;
const fonts = ["Arial", "Helvetica", "Courier", "Times New Roman", "Verdana"];
const CreateCryptoJobForm = () => {
  const {
    handleSubmit,
    values,
    errors,
    handleChange,
    handleBlur,
    isSubmitting,
    setFieldValue,
    displayError,
  } = useCryptoJobFormikContext();

  const { id } = useParams();

  const [companySearch, setCompanySearch] = useState("");

  const [selectedCompany, setSelectedCompany] = useState(null);
  const { isLoading: jobLoading, data: cryptoJob } =
    useGetCryptoJobByIdQuery(id);

  const { data: companies } = useGetCompaniesByNameQuery("");

  useEffect(() => {
    if (cryptoJob) {
      updateFormikValuesForEdit(cryptoJob, setFieldValue);
    }
  }, [cryptoJob]);

  const handleEditorChange = (content) => {
    setFieldValue("jobDescription", content);
  };

  const handleEditorCompanyChange = (content) => {
    setFieldValue("companyAbout", content);
  };

  const handleCompanySearch = (value) => {
    setCompanySearch(value);
  };

  const handleCompanyChange = (value) => {
    // `value` contains the search input
    console.log("@crypto Search value:", value);

    const existedCompany = companies?.find(
      (c) => c.name?.toLowerCase() === value?.toLowerCase(),
    );

    if (existedCompany) {
      const name = existedCompany.name;
      setSelectedCompany(existedCompany);
      setFieldValue("companyName", name);
    } else {
      setFieldValue("companyName", value);
      setSelectedCompany(null);
    }

    setCompanySearch("");
    // You can use this value for filtering data or any other operations
  };

  useEffect(() => {
    if (selectedCompany) {
      setFieldValue("companyName", selectedCompany.name || "");
      setFieldValue("companyAbout", selectedCompany.about || "");
      setFieldValue("companyEmail", selectedCompany?.email || "");

      setFieldValue(
        "companyDiscord",
        selectedCompany?.socialMedia?.discord || "",
      );
      setFieldValue(
        "companyTwitter",
        selectedCompany?.socialMedia?.twitter || "",
      );
      setFieldValue("companyWebsite", selectedCompany?.website || "");
      let pictureValue = [];
      if (selectedCompany.picture) {
        pictureValue = [
          {
            data_url: selectedCompany.picture,
          },
        ];
      }

      setFieldValue("companyLogo", pictureValue);
    }
  }, [selectedCompany]);

  const handleJobTypeChange = (value) => {
    setFieldValue("jobType", value);
  };

  const getSubmitButtonText = () => {
    if (id) {
      if (isSubmitting) {
        return "Job Updating...";
      } else {
        return "Update Job";
      }
    } else {
      if (isSubmitting) {
        return "Job Posting...";
      } else {
        return "Post your job";
      }
    }
  };
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ color: [] }, { background: [] }],
      [{ size: ["10px", "12px", "14px", "16px", "18px", "20px"] }], // Font size options in CSS units
      ["clean"],
    ],
  };

  return (
    <form onSubmit={handleSubmit} id="select_job_type_fields">
      <div className="mb-3">
        <label className="create_crypto_jobs_labels">
          Job Title
          <span className="crypto_job_red_steric">*</span>
        </label>
        <input
          type="text"
          name="jobTitle"
          className="job_title_input_field"
          placeholder="Solidity Engineer"
          value={values.jobTitle}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {displayError("jobTitle")}
      </div>

      <div className="mb-3" id="select_job_type_fields">
        <label className="create_crypto_jobs_labels">
          Job Type
          <span className="crypto_job_red_steric">*</span>
        </label>
        <Select
          showSearch
          className="job_title_input_field"
          // style={{ width: 200 }}
          style={{ width: "100%", padding: 0 }}
          notFoundContent={false}
          placeholder="Select Job Type"
          optionFilterProp="children"
          onChange={handleJobTypeChange}
          onBlur={handleBlur}
          value={values.jobType}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          <Option value={""}>Select Job Type</Option>
          {JOB_TYPE_ENUM.map((type) => (
            <Option key={type} value={type}>
              {type}
            </Option>
          ))}
        </Select>
        {displayError("jobType")}
      </div>

      <DisplayAddress />

      <div className="mb-3">
        <label className="create_crypto_jobs_labels">
          Company Name
          <span className="crypto_job_red_steric">*</span>
        </label>
        <Select
          showSearch
          className="job_title_input_field"
          // style={{ width: 200 }}
          style={{ width: "100%", padding: 0 }}
          notFoundContent={false}
          placeholder="Select Company"
          optionFilterProp="children"
          onSearch={handleCompanySearch}
          onChange={handleCompanyChange}
          onBlur={handleBlur}
          value={values.companyName}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          <Option value={companySearch}>
            {companySearch || "Select Company"}
          </Option>
          {companies?.map((company) => (
            <Option key={company._id} value={company.name}>
              {company.name}
            </Option>
          ))}
        </Select>
        {displayError("companyName")}
      </div>

      <div className="mb-3">
        <label className="create_crypto_jobs_labels">
          Tagline
          <span className="crypto_job_red_steric">*</span>
        </label>
        <input
          type="text"
          name="tagline"
          className="job_title_input_field"
          placeholder="CryptoCoin — Keep it short. Dont write Inc. Ltd."
          value={values.tagline}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {displayError("tagline")}
      </div>
      <div className="mb-3">
        <label className="create_crypto_jobs_labels">
          About your Company
          <span className="crypto_job_red_steric">*</span>
        </label>
        <ReactQuill
          theme="snow"
          value={values.companyAbout}
          onChange={handleEditorCompanyChange}
          style={{ height: "150px" }}
        />
        <div className="mt-5">{displayError("companyAbout")}</div>
      </div>
      <div className="mb-3">
        <label className="create_crypto_jobs_labels pt-2">
          Job Description
          <span className="crypto_job_red_steric">*</span>
        </label>
        <ReactQuill
          theme="snow"
          value={values.jobDescription}
          onChange={handleEditorChange}
          style={{ height: "150px" }} // Default font
          modules={modules}
        />
      </div>
      <div className="mt-5">{displayError("jobDescription")}</div>

      <DisplayFormTags />

      <div className="mb-3">
        <label className="create_crypto_jobs_labels">
          Email for resumes. Stays private
          <span className="crypto_job_red_steric">*</span>
        </label>
        <input
          type="email"
          name="companyEmail"
          className="job_title_input_field"
          placeholder="name@hotmail.com"
          value={values.companyEmail}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {displayError("companyEmail")}
      </div>
      <DisplayFormSalary />
      {/* <DisplayFormQuestions /> */}
      <DisplayFormCompany />
      {/* <DisplayHMDetails /> */}
      <div className="text-center my-lg-5 my-md-4 my-3">
        <button
          type="submit"
          disabled={isSubmitting}
          className="crypto_job_submit_btn"
        >
          {getSubmitButtonText()}
        </button>
      </div>
    </form>
  );
};

export default CreateCryptoJobForm;
