import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Mutex } from "async-mutex";
import config from "../config/development";
import { logout } from "../slices/user";

const baseQuery = fetchBaseQuery({
  baseUrl: config.api.baseUrl,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().user?.user?.token;

    // If we have a token set in state, let's assume that we should be passing it.
    if (token) {
      headers.set("authorization", `${token}`);
    }

    return headers;
  },
});

// create a new mutex
const mutex = new Mutex();

const baseQueryWithReauth = async (args, api, extraOptions) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    // checking whether the mutex is locked
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        api.dispatch(logout());
        // window.location.href = "/signin";
      } catch (e) {
        console.log(e);
      } finally {
        // release must be called once the mutex should be released again.
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

const ApiBase = createApi({
  baseQuery: baseQueryWithReauth,
  reducerPath: "API",
  endpoints: () => ({}),
  tagTypes: [
    "getAllAdmins",
    "getAdminByUsername",
    "getTechnologies",
    "getCategories",
    "getCategoriesWithSubcategories",
    "getSubcategories",
    "getAllBlogs",
    "getBlogBySlug",
    "getUser",
    "getCryptoTags",
    "getCryptoTagById",
    "getUsers",
    "cryptoApplicants",
  ],
});

export default ApiBase;
